import myAxios from "../../services/myAxios";

export default {
  state: {
    notification: null, //chosen notification
    notifications: [],
    filtered: [],
  },
  getters: {
    notifications: (state) => state.notifications,
    notification: (state) => state.notification,
    notificationsFiltered: (state) => state.filtered,
  },
  mutations: {
    //sets all notifications
    "notifications/set": (state, payload) => {
      state.notifications = payload;
      state.filtered = [...state.notifications];
    },
    //sets one notification
    "notification/set": (state, payload) => (state.notification = payload),
    //filters the notification's array by notification's key and notification's val
    "notifications/filter": (state, { key, val }) => {
      state.filtered = !val
        ? [...state.notifications]
        : state.notifications.filter((f) => f[key] === val);
    },
    //store one notification
    "notification/store": (state, payload) => state.notifications.push(payload),
    //destroys one notification
    "notification/destroy": (state, id) =>
      (state.notifications = state.notifications.filter((item) => {
        return item._id !== id;
      })),
    //updates one notification
    "notification/update": (state, payload) => {
      state.notifications = state.notifications.map((item) => {
        if (item._id === payload._id) {
          return payload;
        }
        return item;
      });
    },
  },
  actions: {
    //fetch all notifications
    "notification/index": async (context) => {
      const { data } = await myAxios.get("/notification");
      context.commit("notifications/set", data);
    },
    //fetch one notification by id
    "notification/show": async (context, id) => {
      let { data } = await myAxios.get("/notification/" + id);
      context.commit("notification/set", data);
    },
    //stores one notification
    "notification/store": async (context, payload) => {
      let { data } = await myAxios.post("/notification", { ...payload });
      context.commit("notification/store", data);
    },
    //destroys one notification
    "notification/destroy": async (context, id) => {
      await myAxios.delete("/notification/" + id);
      context.commit("notification/destroy", id);
    },
    //updates one notification by its id
    "notification/update": async (context, payload) => {
      await myAxios.put("/notification/" + payload._id, payload);
      context.commit("notification/update", payload);
    },
  },
};
