<template>
  <div>
    <my-dialog :title="escort.name" v-model="openModal">
      <template v-slot:content>
        <v-alert v-if="notes" type="info">{{ notes }}</v-alert>
        <escort-display-table :escort="escort" />
      </template>
      <template v-slot:actions>
        <div class="d-flex justify-space-between" style="width: 100%">
          <div>
            <icon-btn-tooltip
              icon="mdi-pencil-box"
              :tooltip="$t('escorts.editSingleEscort')"
              @cb="isSingleEscort ? editSingle() : createOrEditException()"
              :disabled="hasPendingChild"
            />
            <icon-btn-tooltip
              icon="mdi-delete"
              :tooltip="$t('escorts.deleteSingleEscort')"
              @cb="isPermanentEscort ? skipEscort() : deleteEscort()"
              :disabled="hasPendingChild"
            />
          </div>
          <div class="d-flex flex-column align-center">
            <v-btn v-if="hasPendingChild" text color="red" @click="pendingEscortDialog = true">
              <v-icon>mdi-alert-circle-outline</v-icon>
              {{ $t("escorts.pendingMsg") }}
            </v-btn>
          <v-row dense>
            <v-col cols="2" sm="6" class="mb-3">
              <v-btn @click="duplicateEscort" width="120" text>
                <v-icon class="ml-1">mdi-content-copy</v-icon>
                {{ $t('escorts.duplicate') }}
              </v-btn>
            </v-col>
            <v-col cols="2" sm="6">
              <v-btn @click="toggleDoneStatus" :loading="loading" width="120" text :color="computedIsEscortDone ? 'success' : 'grey'">
                <v-icon class="ml-1">{{!computedIsEscortDone ? 'mdi-times' : 'mdi-check'}}</v-icon>
                {{ computedIsEscortDone ? $t('generics.done') : $t('generics.undone') }}
              </v-btn>
            </v-col>
          </v-row>
          </div>
          <div v-if="isPermanentEscort && $store.getters.role <= 1">
            <icon-btn-tooltip
              icon="mdi-pencil-box-multiple"
              :tooltip="$t('escorts.editTotalEscort')"
              @cb="editTotal"
              :color="'error'"
            />
            <icon-btn-tooltip
              icon="mdi-delete-alert"
              :tooltip="$t('escorts.deleteTotalEscort')"
              :color="'error'"
              @cb="deleteEscort"
            />
          </div>
        </div>
      </template>
    </my-dialog>
    <escort-handler
      :isNew="isNew"
      v-if="totalEscortHandlerDialog"
      v-model="totalEscortHandlerDialog"
      :escort="escort"
      :initialData="escort"
      @close="close"
    />
    <single-escort-handler
      :isNew="isNew"
      v-if="singleEscortHandlerDialog"
      v-model="singleEscortHandlerDialog"
      :escort="escort"
      :initialData="escort"
      :date="event.start"
      @close="close"
    />
    <exception-escort-handler
      :isNew="!escort.parent"
      v-if="exceptionEscortHandlerDialog"
      v-model="exceptionEscortHandlerDialog"
      :escort="escort"
      :date="event.start"
      @close="close"
    />
    <pending-escort-handler
      v-if="pendingEscortDialog"
      v-model="pendingEscortDialog"
      :pendingEscort="escort.pendingChild"
      @close="close"
    />
  </div>
</template>
<script>
/**
 * This component shows the escort and connect to escort CRUD components
 */
import MyDialog from "@/components/generics/MyDialog";
import IconBtnTooltip from "@/components/generics/IconBtnTooltip";
import EscortHandler from "@/components/handlers/EscortHandler";
import SingleEscortHandler from "@/components/handlers/SingleEscortHandler";
import ExceptionEscortHandler from "@/components/handlers/ExceptionEscortHandler";
import PendingEscortHandler from "@/components/handlers/PendingEscortHandler";
import EscortDisplayTable from "@/components/EscortDisplayTable";
import Times from '@/helpers/times.js';

export default {
  name: "escort-display",
  components: {
    MyDialog,
    IconBtnTooltip,
    ExceptionEscortHandler,
    EscortHandler,
    PendingEscortHandler,
    EscortDisplayTable,
    SingleEscortHandler,
  },
  props: {
    value: Boolean,
    escort: Object,
    event: {
      type: Object,
      default: () => { },
    },
  },
  mixins: [],
  data: () => ({
    exceptionEscortHandlerDialog: false,
    singleEscortHandlerDialog: false,
    totalEscortHandlerDialog: false,
    pendingEscortDialog: false,
    isNew: false,
    loading: false
  }),
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    hasPendingChild() {
      return !!(this.escort && this.escort.pendingChild);
    },
    isExceptionEscort() { return this.escort.isChild },
    isSingleEscort() { return this.escort.isSingle && !this.escort.isChild },
    isPermanentEscort() {
      return !this.isSingleEscort && !this.isExceptionEscort
    },
    notes() {
      return this.escort?.notes
    },
    computedIsEscortDone() {
      return Times.dateIsInArray(this.event.start, this.escort.doneEscorts);
    }
  },
  methods: {
    async skipEscort() {
      let { isConfirmed } = await this.$createSwal({
        title: this.$t("errors.escorts.deleteSingle"),
      });
      if (isConfirmed) {
        if (!this.escort.parent) {
          await this.$store.dispatch("escort/skips", {
            id: this.escort?._id,
            date: new Date(this.event.start),
          });
        } else {
          await this.$store.dispatch("escort/destroy", this.escort);
        }
        this.close();
      }
    },
    async deleteEscort() {
      const title = this.isPermanentEscort ?
        this.$t("errors.escorts.deleteTotal") :
        this.$t("errors.escorts.deleteSingle");
      let { isConfirmed } = await this.$createSwal({ title });
      if (isConfirmed) {
        await this.$store.dispatch("escort/destroy", this.escort);
        this.close();
      }
    },
    createOrEditException() {
      this.exceptionEscortHandlerDialog = true;
    },
    duplicateEscort() {
      this.isNew = true;
      if (this.isSingleEscort) {
        this.singleEscortHandlerDialog = true;
      } else {
        this.totalEscortHandlerDialog = true;
      }
    },
    async toggleDoneStatus() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "escort/change-done-status",
          {id: this.escort._id, isDone: !this.computedIsEscortDone, date: this.event.start});
      } finally {
        this.loading = false;
        this.close();
      }
    },
    editSingle() {
      this.isNew = false;
      this.singleEscortHandlerDialog = true;
    },
    editTotal() {
      this.isNew = false;
      this.totalEscortHandlerDialog = true;
    },
    close() {
      this.openModal = false;
    },
  },
};
</script>
