<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title class="d-flex align-center">
          <v-row>
            <v-col cols="11">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                icon
                :label="$t('generics.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-icon
                color="primary"
                v-if="isAllowedToMutate"
                @click="addDialog = true"
                v-text="'mdi-plus'"
              />
              <v-icon color="primary" @click="loadData" v-text="'mdi-refresh'" />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="computedHeaders"
          :items="items"
          :search="search"
          :loading="loading"
          :items-per-page="20"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <point-handler @done="createPoint" v-if="addDialog" v-model="addDialog" :isNew="true" />
    <point-handler @done="updatePoint" v-if="editDialog" v-model="editDialog" :point="chosen" />
  </div>
</template>

<script>
import PointHandler from "@/components/handlers/PointHandler";
export default {
  name: "PointsTable",
  components: { PointHandler },
  data() {
    return {
      loading: false,
      chosen: null,
      search: "",
      addDialog: false,
      editDialog: false,
    };
  },
  computed: {
    computedHeaders() {
      const headers = [
        { text: this.$t("points.points"), value: "name" },
      ];
      if (this.isAllowedToMutate) headers.push(
        {
          text: `${this.$t("generics.actions")}`,
          value: "actions",
          width: "10%",
        },
      )
      return headers
    },
    isAllowedToMutate() {
      return this.$store.getters.role <= 1
    },
    items() {
      return this.$store.getters.points;
    },
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("point/index");
      this.loading = false;
    },
    editItem(item) {
      this.chosen = item;
      this.editDialog = true;
    },
    async deleteItem(item) {
      let { isConfirmed } = await this.$createSwal({
        icon: "warning",
        title: `${this.$t("generics.delete")} ${item.name}?`,
      });
      if (isConfirmed) {
        await this.$store.dispatch("point/destroy", item._id);
      }
    },
    async updatePoint(name) {
      this.loading = true;
      this.chosen.name = name;
      await this.$store.dispatch("point/update", this.chosen);
      this.loading = false;
    },
    async createPoint(name) {
      this.loading = true;
      await this.$store.dispatch("point/store", { name });
      this.loading = false;
    },
  },
  async mounted() {
    if (!this.items.length) {
      await this.loadData();
    }
  },
};
</script>
