import myAxios from "../../services/myAxios";

export default {
  state: {
    path: null, //chosen path
    paths: [],
    filtered: [],
  },
  getters: {
    paths: (state) => state.paths,
    path: (state) => state.path,
    pathFiltered: (state) => state.filtered,
  },
  mutations: {
    //sets all paths
    "paths/set": (state, payload) => {
      state.paths = payload;
      state.filtered = [...state.paths];
    },
    //sets one path
    "path/set": (state, payload) => (state.path = payload),
    //filters the path's array by path's key and path's val
    "paths/filter": (state, { key, val }) => {
      state.filtered = !val
        ? [...state.paths]
        : state.paths.filter((f) => f[key] === val);
    },
    //store one path
    "path/store": (state, payload) => state.paths.push(payload),
    //destroys one path
    "path/destroy": (state, id) =>
      (state.paths = state.paths.filter((item) => {
        return item._id !== id;
      })),
    //updates one path
    "path/update": (state, payload) => {
      state.paths = state.paths.map((item) => {
        if (item._id === payload._id) {
          return payload;
        }
        return item;
      });
    },
  },
  actions: {
    //fetch all paths
    "path/index": async (context) => {
      const { data } = await myAxios.get("/path");
      context.commit("paths/set", data);
    },
    //fetch one path by id
    "path/show": async (context, id) => {
      let { data } = await myAxios.get("/path/" + id);
      context.commit("path/set", data);
    },
    //stores one path
    "path/store": async (context, payload) => {
      let { data } = await myAxios.post("/path", { ...payload });
      context.commit("path/store", { ...data, ...payload });
    },
    //destroys one path
    "path/destroy": async (context, id) => {
      await myAxios.delete("/path/" + id);
      context.commit("path/destroy", id);
    },
    //updates one path by its id
    "path/update": async (context, payload) => {
      await myAxios.put("/path/" + payload._id, payload);
      context.commit("path/update", payload);
    },
  },
};
