<template>
  <div>
    <v-container class="center-all">
      <v-card max-width="500">
        <v-row no-gutters align="center" justify="center">
          <v-card-title class="center-all" v-text="$t('userDetails.userDetails')" />
          <v-btn :disabled="editMode" icon text @click="editMode = !editMode">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-row>
        <v-form v-model="valid" @submit.prevent="submit">
          <v-row class="pa-16">
            <v-col cols="12">
              <v-text-field
                color="primary"
                class="no-bottom-line"
                :label="$t('userDetails.name')"
                v-model="form.name"
                hide-details
                :rules="nameValidationRule"
                readonly
                :disabled="loading"
              />
            </v-col>
            <v-col cols="12" class="d-flex">
              <v-text-field
                color="primary"
                :class="{ 'no-bottom-line': !this.editMode }"
                :label="$t('userDetails.phone')"
                v-model="form.phone"
                :hide-details="!editMode"
                :rules="phoneValidationRule"
                :readonly="!editMode"
                :disabled="loading"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                color="primary"
                class="no-bottom-line"
                :label="$t('userDetails.email')"
                v-model="form.email"
                hide-details
                :rules="emailValidationRule"
                readonly
                :disabled="loading"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                color="primary"
                :class="{ 'no-bottom-line': !this.editMode }"
                :label="$t('userDetails.address')"
                v-model="form.address"
                :hide-details="!editMode"
                :rules="addressValidationRule"
                :readonly="!editMode"
                :disabled="loading || !form.address && !editMode"
              />
            </v-col>
            <v-col v-if="editMode" cols="12" class="d-flex justify-center">
              <v-btn :disabled="loading" text @click="cancelEdit" class="mx-1">ביטול</v-btn>
              <v-btn :loading="loading" type="submit" class="mx-1">שמור</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import validationMixins from '@/mixins/validation';

export default {
  name: "UserDetails",
  mixins: [validationMixins],
  data() {
    return {
      editMode: false,
      loading: false,
      valid: false,

      form: {
        name: '',
        phone: '',
        email: '',
        address: '',
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.userDetails;
    },
  },
  methods: {
    setInitailFormValues() {
      this.form.name = this.user.name;
      this.form.phone = this.user.phone;
      this.form.email = this.user.email;
      this.form.address = this.user.address?.name || this.user.address;
    },
    cancelEdit() {
      this.editMode = false;
      this.setInitailFormValues();
    },
    async submit() {
      if (!this.valid) return;
      try {
        this.loading = true;
        const updatedUser = {
          ...this.user,
          name: this.form.name,
          phone: this.form.phone,
          email: this.form.email,
          address: this.form.address,
        };
        await this.$store.dispatch('user/update', updatedUser);
        this.editMode = false;
      } finally {
        this.loading = false
      }
    },
  },
  created() {
    this.setInitailFormValues();
  }
};
</script>

<style scoped>
.no-bottom-line::v-deep .v-input__control > .v-input__slot:after,
.no-bottom-line::v-deep .v-input__control > .v-input__slot:before {
  content: none !important;
}
</style>
