<template>
  <div>
    <v-card-title class="d-flex align-center">
      <v-row>
        <v-col cols="11">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            icon
            :label="$t('generics.search')"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-icon
            v-if="isAllowedToMutate"
            color="primary"
            @click="addDialog = true"
            v-text="'mdi-plus'"
          />
          <v-icon color="primary" @click="loadData" v-text="'mdi-refresh'" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="computedHeaders"
      :items="paths"
      :search="search"
      :loading="loading"
      :items-per-page="20"
    >
      <template v-slot:item.color="{ item }">
        <colors-pie-chart :color="item.color" />
      </template>
      <template v-slot:item.points="{ item }">
        <v-chip-group column>
          <v-chip v-for="(tag, i) in item.points" :key="i">{{ tag.name }}</v-chip>
        </v-chip-group>
      </template>
      <template v-slot:item.time="{ item }">
        <span>{{ item.time }}</span>
        {{ item.time && $t("date.minutes") }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <path-handler v-if="addDialog" v-model="addDialog" :isNew="true" @save="addPath" />
    <path-handler v-if="editDialog" v-model="editDialog" :path="chosen" @save="editPath" />
  </div>
</template>

<script>
import PathHandler from "@/components/handlers/PathHandler";
import times from "@/mixins/times";
import ColorsPieChart from '../generics/ColorsPieChart.vue';


export default {
  name: "PathsTable",
  components: { PathHandler, ColorsPieChart },
  mixins: [times],
  data() {
    return {
      loading: false,
      chosen: null,
      search: "",
      addDialog: false,
      editDialog: false,
    };
  },
  computed: {
    computedHeaders() {
      const headers = [
        { text: `${this.$t("generics.color")}`, value: "color" },
        { text: `${this.$t("generics.name")}`, value: "name" },
        { text: `${this.$t("points.points")}`, value: "points" },
        { text: `${this.$t("paths.time")}`, value: "time" },
      ];
      if (this.isAllowedToMutate) headers.push(
        {
          text: `${this.$t("generics.actions")}`,
          value: "actions",
          width: "10%",
        },
      )
      return headers
    },
    isAllowedToMutate() {
      return this.$store.getters.role <= 1
    },
    paths() {
      return this.$store.getters.paths;
    },
    points() {
      return this.$store.getters.points;
    },
    users() {
      return this.$store.getters.users;
    },
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("point/index");
      await this.$store.dispatch("path/index");
      this.loading = false;
    },
    editItem(item) {
      this.chosen = item;
      this.editDialog = true;
    },
    async deleteItem(item) {
      let { isConfirmed } = await this.$createSwal({
        icon: "warning",
        title: `${this.$t("generics.delete")}?`,
      });
      if (isConfirmed) {
        await this.$store.dispatch("path/destroy", item._id);
      }
    },
    async editPath(path) {
      await this.$store.dispatch("path/update", path);
    },
    async addPath(path) {
      await this.$store.dispatch("path/store", path);
    },
  },
  async mounted() {
    if (!this.paths.length || !this.points.length) {
      await this.loadData();
    }
  },
};
</script>
