<template>
  <div>
    <my-dialog :title="title" v-model="openModal">
      <template v-slot:content>
        <v-row>
          <v-text-field :label="$t('generics.name')" v-model="name" autofocus />
        </v-row>
        <v-row>
          <v-autocomplete
            v-model="select"
            @change="onSelect"
            :items="points"
            :label="$t('points.points')"
            item-text="name"
            return-object
            clearable
          />
        </v-row>
        <v-row>
          <ins v-if="selectPoints.length">{{ $t("points.points") }}:</ins>
          <my-draggable
            @deleteSelect="deleteSelect"
            :items="selectPoints"
            v-if="selectPoints.length"
          />
        </v-row>
        <v-row class="d-flex justify-start">
          <v-col cols="3" class="d-flex center-all">
            <div class="ml-2">{{ $t("complexPaths.time") }}:</div>
            <!-- <v-text-field v-model="time" type="number" /> -->
            <number-picker :min="5" v-model="time" />
            <div class="mr-2">{{ $t("date.minutes") }}.</div>
          </v-col>
          <v-col cols="7">
            <v-select
              v-model="type"
              :label="$t('escorts.type')"
              :items="types"
              :rules="[(v) => !!v || $t('errors.notEmpty')]"
              required
            />
          </v-col>
          <color-platen v-model="color" />
        </v-row>
        <v-row v-if="err">
          <v-col class="error--text text-center">{{ err }}</v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn
          text
          color="primary"
          v-text="isNew ? $t('generics.add') : $t('generics.save')"
          @click="submit"
        />
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from "@/components/generics/MyDialog";
import MyDraggable from "@/components/generics/MyDraggable";
import NumberPicker from "@/components/generics/NumberPicker";
import ColorPlaten from '../generics/ColorPlaten.vue';
export default {
  name: "path-handler",
  components: { MyDialog, MyDraggable, NumberPicker, ColorPlaten },
  props: {
    value: Boolean,
    isNew: {
      type: Boolean,
      default: false,
    },
    path: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      types: [
        { text: this.$t("escorts.types.foot"), value: "foot" },
        { text: this.$t("escorts.types.vehicle"), value: "vehicle" },
        { text: this.$t("escorts.types.vehicle-foot"), value: "vehicle-foot" },
      ],
      err: null,
      
      // form
      name: "",
      select: "",
      time: 10,
      selectPoints: [],
      type: "",
      color: null,
    }
  },
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    points() {
      return this.$store.getters.points;
    },
    title() {
      return this.$t("paths.handlerTitle", {
        prefix: this.$t(this.isNew ? "generics.add" : "generics.edit"),
        suffix: this.$t(this.isNew ? "generics.new" : ""),
      });
    },
  },
  methods: {
    onSelect(item) {
      if (item) {
        this.selectPoints = [...this.selectPoints, item];
      }
    },
    deleteSelect(item, i) {
      this.selectPoints = this.selectPoints.filter(
        (select, index) => index !== i
      );
    },
    async submit() {
      if (!this.validateForm()) {
        return this.createErr(this.$t("errors.validate.paths"));
      }
      this.$emit("save", {
        ...this.path,
        name: this.name,
        points: this.selectPoints,
        time: +this.time,
        type: this.type,
        color: this.color
      });
      this.openModal = false;
    },
    validateForm() {
      return this.selectPoints.length > 1 && this.name.length;
    },
    createErr(msg) {
      this.err = msg;
      setTimeout(() => {
        this.err = null;
      }, 2000);
      return;
    },
  },
  mounted() {
    if (!this.isNew) {
      this.name = this.path.name;
      this.selectPoints = [...this.path.points];
      this.time = this.path.time;
      this.type = this.path.type;
      this.color = this.path.color;
    }
  },
};
</script>
