<template>
  <div class="wrapper">
    <div
      v-for="(c, i) in colorsItems"
      :key="c.name"
      :class="`${c.name === color ? 'focused' : 'circle-button '}`"
      @click="pick(i)"
      :style="`background-color: ${c.color}`"
    />
  </div>
</template>
<script>
import listsMixins from '@/mixins/lists';

export default {
  name: "color-platen",
  props: { value: String },
  mixins: [listsMixins],
  computed: {
    color: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      idx: 0,
    };
  },
  methods: {
    pick(i) {
      this.color = this.colorsItems[i].name;
    },
    setDefaultColor() {
      if (this.color) return;
      this.color = this.colorsItems.at(-1).name;
    }
  },
  created() {
    this.setDefaultColor()
  },
};
</script>
<style scoped lang="scss">
.wrapper {
  width: 300px;
  display: flex;
  flex-wrap: wrap;

  .circle-button {
    width: 14px;
    height: 14px;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    margin-left: 3px;
  }

  .focused {
    border: 2px solid black;
    padding: 8px;
    border-radius: 50%;
    margin: 0 3px;
  }
}
</style>
