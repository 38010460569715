<template>
	<div>
		<my-dialog
			:title="isNew ? $t('escorts.new') : $t('escorts.edit')"
			v-model="openModal"
			:persistent="true"
		>
			<template v-slot:content>
				<v-form
					ref="form"
					v-model="valid"
					lazy-validation
					v-if="!loading"
				>
					<v-text-field
						:value="computedDefaultEscortName"
						:label="$t('generics.name')"
						:rules="[(v) => v.length >= 1 || $t('errors.notEmpty')]"
						required
            disabled
					/>
					<div
						v-if="form.path"
						v-text="typeString"
					/>
					<v-row>
						<v-col cols="12" sm="6">
							<v-autocomplete
                @input="onInputPath"
                :value="form.path"
								:items="paths"
								:label="$t('paths.path')"
								item-text="name"
								return-object
								clearable
								:rules="[(v) => !!v || $t('errors.notEmpty')]"
							/>
						</v-col>
						<v-col cols="6" sm="3">
              <v-switch
								v-model="isComplexPath"
								:label="
                  $t(isComplexPath ? 'generics.complex' : 'generics.regular')
                "
								@click="() => (form.path = null)"
                hide-details
							/>
						</v-col>
						<v-col cols="6" sm="3">
              <v-switch
                v-model="form.isCircular"
                :label="$t('paths.isCircular')"
                hide-details
              />
            </v-col>
					</v-row>
					<dayjs-picker v-model="form.days" />
					<div class="d-flex date">
            <my-date-picker
							v-model="form.date.start"
							:end="form.date.end"
							:label="$t('date.dateStart')"
						/>
						<my-date-picker
							v-model="form.date.end"
							:start="form.date.start"
							:label="$t('date.dateEnd')"
						/>
					</div>
					<v-row class="d-flex">
            <v-col cols="6">
              <my-time-picker
                v-model="form.time.start"
                :label="$t('date.timeStart')"
              />
            </v-col>
            <v-col cols="6" class="d-flex align-center">
              <div v-if="computedTimeEnd" class="center-all-col">
                <span>{{ $t('date.timeEnd') }}</span>
                <span>{{ computedTimeEnd }}</span>
              </div>
            </v-col>
					</v-row>
					<v-autocomplete
						v-model="form.escorters"
						:items="escorters"
						:label="$t('generics.escorters')"
						item-text="name"
						item-value="_id"
						clearable
						multiple
						:rules="[(v) => !!v.length || $t('errors.notEmpty')]"
					/>
					<color-platen v-model="form.color" />
					<passengers-table
						v-model="form.passengers"
						:maxPassengers="form.maxPassengers"
						@maxPassengersHandler="maxPassengersHandler"
					/>
					<v-textarea
						v-model="form.notes"
						:label="$t('generics.notes')"
						rows="1"
					/>
				</v-form>
				<v-row v-if="err">
					<v-col class="error--text text-center">{{ err }}</v-col>
				</v-row>
			</template>
			<template v-slot:actions>
				<v-btn
					text
					v-if="!isNew"
					color="error"
					v-text="$t('generics.remove')"
					@click="deleteItem"
				/>
				<v-btn
					text
					color="primary"
					v-text="isNew ? $t('generics.add') : $t('generics.change')"
					@click="isNew ? create() : update()"
					:disabled="!valid"
				/>
			</template>
		</my-dialog>
	</div>
</template>
<script>
import MyDialog from "@/components/generics/MyDialog";
import MyDatePicker from "@/components/generics/MyDatePicker";
import MyTimePicker from "@/components/generics/MyTimePicker";
import ColorPlaten from "@/components/generics/ColorPlaten";
import { days } from "@/helpers/calendar";
import _ from "lodash";
import times from "@/mixins/times";
import PassengersTable from "@/components/PassengersTable.vue";
import DayjsPicker from "../generics/DayjsPicker.vue";

export default {
	name: "escort-handler",
	components: {
		MyDialog,
		MyDatePicker,
		MyTimePicker,
		ColorPlaten,
		PassengersTable,
		DayjsPicker
	},
	props: {
		value: Boolean,
		isNew: {
			type: Boolean,
			default: false,
		},
		initialData: {
			type: Object,
		},
		escort: { type: Object, default: () => ({}) },
	},
	mixins: [times],
	data() {
		return {
			tempPassengers: [],
			form: {
				name: "",
				type: "",
				color: undefined,
				days: [],
				time: {
					start: null,
					end: null,
				},
				date: {
					start: null,
					end: null,
				},
				numberOfPassengers: 1,
				passengers: [],
				maxPassengers: 19,
				escorters: [],
				pathModel: "Path",
				path: "",
				isCircular: false,
				notes: "",
			},
			selectedAll: false,
			count: 7,
			err: null,
			types: [
				{ text: this.$t("escorts.types.foot"), value: "foot" },
				{ text: this.$t("escorts.types.vehicle"), value: "vehicle" },
				{ text: this.$t("escorts.types.vehicle-foot"), value: "vehicle-foot" },
			],
			valid: true,
			isComplexPath: false,
			escortChangesDialog: false,
			sendMsg: false,
			notificationMsg: "",
			isInfoMsg: true,
			changeAll: false,
			loading: false,
			notifyDialog: false,
		};
	},
	watch: {
		isComplexPath(v) {
			this.form.pathModel = v ? "ComplexPath" : "Path";
		},
    computedTimeEnd(v) {
      this.form.time.end = v;
    },
	},
	computed: {
		openModal: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
		escorters() {
			return this.$store.getters.escorters;
		},
		passengers() {
			return this.$store.getters.passengers;
		},
		paths() {
			return this.isComplexPath
				? this.$store.getters.complexPaths
				: this.$store.getters.paths;
		},
		typeString() {
			let str = "";
			str += this.$t('escorts.type') + ": ";
			str += this.$t(`escorts.types.${this.form.path?.type}`);
			return str;
		},
    computedTimeEnd() {
     if (this.form.time.start && this.form.date.start && this.form.path) {
        let d = this.setDateTime(this.form.date.start, this.form.time.start)
        const durationTime = (this.form.isCircular ? 2 : 1) * this.form.path?.time;
        let result = d.add(durationTime, "m")
        return this.extractTimeString(result);
      } else return ""
    },
    computedDefaultEscortName() {
      return `${this.form.time?.start || '00:00'} - ${this.form.path?.name || 'שם מסלול'}`
    }
	},
	methods: {
		saveColor(color) {
			this.form.color = color;
		},
		async create() {
      const data = {...this.form, name: this.computedDefaultEscortName};
      await this.$store.dispatch("escort/store", data);
			this.close();
		},
		async update() {
      const data = {...this.form, name: this.computedDefaultEscortName};
      await this.$store.dispatch("escort/update", data);
			this.close();
		},
		async deleteItem() {
			this.$emit("deleteItem", this.escort);
			this.openModal = false;
		},
		validateForm() {
			this.$refs.form.validate();
			return (
				this.form.name &&
				this.form.time.start &&
				this.form.time.end &&
				this.form.date.start &&
				this.form.days.length &&
				this.form.passengers.length &&
				this.form.escorters.length &&
				this.form.path &&
				this.form.type
			);
		},
		createErr(msg) {
			this.err = msg;
			setTimeout(() => {
				this.err = null;
			}, 2000);
			return;
		},
		getPassengersWithNumbers(v) {
			this.form.passengers = v;
		},
		close() {
			this.openModal = false;
		},
		maxPassengersHandler(v) {
			this.form.maxPassengers = v;
		},
    onInputPath(val) {
      this.form.path = val;
      if(!val) return;
      this.form.color = this.isComplexPath ? val.paths[0].color : val.color;
    }
	},
	async created() {
		this.loading = true;
		if (!this.isNew || this.initialData) {
			this.form = _.cloneDeep(!this.isNew ? this.escort : this.initialData);
			this.isComplexPath = this.form.pathModel === "ComplexPath";
			this.form.date = {
				start: this.extractDateString(this.form.date.start),
				end: this.extractDateString(this.form.date.end),
			};
		}
		this.selectedAll = this.form?.days?.length >= this.count;
		if (!this.paths.length) {
			await this.$store.dispatch("path/index");
			await this.$store.dispatch("complexPath/index");
		}
		if (!this.passengers.length || !this.escorters.length)
			this.$store.dispatch("users/index");
		this.loading = false;
    this.form.color = this.escort.color;
	},
};
</script>
