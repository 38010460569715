<template>
  <div>
    <v-card-title class="d-flex align-center">
      <v-row>
        <v-col cols="11">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            icon
            :label="$t('generics.search')"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-icon
          v-if="isAllowedToMutate"
           color="primary" @click="addDialog = true" v-text="'mdi-plus'" />
          <v-icon color="primary" @click="loadData" v-text="'mdi-refresh'" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="computedHeaders"
      :items="complexPaths"
      :search="search"
      :loading="loading"
      :items-per-page="50"
    >
      <template v-slot:item.paths="{ item }">
        <v-chip-group column>
          <v-chip v-for="(tag, i) in item.paths" :key="i" :color="tag.color">{{ tag.name }}</v-chip>
        </v-chip-group>
      </template>
      <template v-slot:item.colors="{ item }">
        <colors-pie-chart :colors="item.paths.map( p => p.color )" />
      </template>
      <template v-slot:item.time="{ item }">
        <span>{{ item.time }}</span>
        {{ item.time && $t("date.minutes") }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <complex-path-handler
      v-if="addDialog"
      v-model="addDialog"
      :isNew="true"
      @save="addComplexPath"
    />
    <complex-path-handler
      v-if="editDialog"
      v-model="editDialog"
      :complexPath="chosen"
      @save="editComplexPath"
    />
  </div>
</template>

<script>
import ComplexPathHandler from "@/components/handlers/ComplexPathHandler";
import ColorsPieChart from '../generics/ColorsPieChart.vue';

export default {
  name: "complex-paths-table",
  components: { ComplexPathHandler, ColorsPieChart },
  data() {
    return {
      name: "",
      paths: [],
      loading: false,
      chosen: null,
      search: "",
      addDialog: false,
      editDialog: false,
    };
  },
  computed: {
    computedHeaders() {
      const headers = [
        { text: `${this.$t("generics.colors")}`, value: "colors" },
        { text: `${this.$t("generics.name")}`, value: "name" },
        { text: `${this.$t("complexPaths.complexPath")}`, value: "paths" },
        { text: `${this.$t("complexPaths.time")}`, value: "time" },
      ];
      if (this.isAllowedToMutate) headers.push(
        {
          text: `${this.$t("generics.actions")}`,
          value: "actions",
          width: "10%",
        },
      )
      return headers
    },
    isAllowedToMutate() {
      return this.$store.getters.role <= 1
    },
    complexPaths() {
      return this.$store.getters.complexPaths;
    },
    path() {
      return this.$store.getters.path;
    },
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("path/index");
      await this.$store.dispatch("complexPath/index");
      this.loading = false;
    },
    editItem(item) {
      this.chosen = item;
      this.editDialog = true;
    },
    async deleteItem(item) {
      let { isConfirmed } = await this.$createSwal({
        icon: "warning",
        title: `${this.$t("generics.delete")}`,
      });
      if (isConfirmed) {
        await this.$store.dispatch("complexPath/destroy", item._id);
      }
    },
    async editComplexPath(complexPaths) {
      await this.$store.dispatch("complexPath/update", complexPaths);
    },
    async addComplexPath(complexPaths) {
      await this.$store.dispatch("complexPath/store", complexPaths);
    },
  },
  async mounted() {
    if (!this.complexPaths.length || !this.path) {
      await this.loadData();
    }
  },
};
</script>
