import router from "../../router/index";
import myAxios from "../../services/myAxios";
import axios from "axios";

export default {
  state: {
    name: null,
    role: null,
    state: null,
    uid: null,
    userDetails: {},
  },
  getters: {
    userDetails: (state) => state.userDetails,
    name: (state) => state.name,
    isAuth: (state) => state.isLoggedIn,
    uid: (state) => state.uid,
    role: (state) => state.role,
    r0: (state) => state.role === 0,
    r1: (state) => state.role === 1,
    r2: (state) => state.role === 2,
    r3: (state) => state.role === 3,
    r4: (state) => state.role === 4,
    areAdmins: (state) => state.role <= 2,
    userPhone: (state) => state.userDetails.phone,
  },
  mutations: {
    "auth/login": (state, user) => {
      state.isLoggedIn = true;
      state.userDetails = user;
      state.name = user.name;
      state.role = user.role;
      state.uid = user._id;
    },
    clearData: (state) => {
      state.isLoggedIn = false;
      state.name = null;
      state.role = null;
      state.uid = null;
    },
  },
  actions: {
    "auth/login": async (context, payload) => {
      try {
        let { data } = await myAxios.post("/auth/login", payload);
        context.commit("auth/login", data);
        const isAdmin = context.getters.areAdmins;
        router.push({ name: !isAdmin ? "UserDashboard" : "AdminDashboard" });
        return;
      } catch (e) {
        throw new Error(e);
      }
    },
    "auth/checkLogin": async (context) => {
      try {
        let { data } = await axios.get("/auth/checkLogin");
        context.commit("auth/login", data);
      } catch (e) {
        throw new Error();
      }
    },
    "auth/logout": async ({ commit, getters }) => {
      commit("clearData");
      await myAxios.post("/auth/logout");
      return router.push({ name: "Login" });
    },
  },
};
