import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
// import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/he";

dayjs.extend(utc);
// dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale("he", {
  relativeTime: {
    future: "בעוד %s",
    past: "לפני %s",
    s: "כמה רגעים",
    m: "דקה",
    mm: "%d דקות",
    h: "שעה",
    hh: "%d שעות",
    d: "יום",
    dd: "%d ימים",
    M: "חודש",
    MM: "%d חודשים",
    y: "שנה",
    yy: "%d שנים",
  },
});

const dayStringMap = {
  0: { he: "ראשון" },
  1: { he: "שני" },
  2: { he: "שלישי" },
  3: { he: "רביעי" },
  4: { he: "חמישי" },
  5: { he: "שישי" },
  6: { he: "שבת" },
};

export default class Times {
  static formatTime(time) {
    return dayjs(time).format("DD.MM.YY HH:mmA");
  }
  static extractDateString(date = new Date(), isRtl = false) {
    const format = isRtl ? "YYYY-MM-DD" : "DD-MM-YYYY";
    return date ? dayjs(date).format(format) : "";
  }
  static extractTimeString(date) {
    return date ? dayjs(date).format("HH:mm") : "";
  }
  static extractDayString(date) {
    let dayCode = dayjs(date).day();
    return dayStringMap[dayCode].he;
  }
  static setDateTime(date, time) {
    date = dayjs(date);
    let dateStr = this.extractDateString(date);
    dateStr += `T${time}`;
    return dayjs(dateStr);
  }
  static isSameDay(d1, d2) {
    return this.extractDateString(d1) === this.extractDateString(d2);
  }
  static clacRelativeTimeString(date) {
    return dayjs(date).locale(this.$i18n.locale).fromNow();
  }
  static sortByISODate(dateISO1, dateISO2) {
    const date1 = new Date(dateISO1);
    const date2 = new Date(dateISO2);
    return date1 > date2 ? -1 : date1 < date2 ? 1 : 0;
  }

  //checking if x's day is between y1, y2 days, or same as one
  static isSameOrBetweenDays(x, y1, y2) {
    let isSameOrBetweenDays = false;
    x = dayjs(x);
    y1 = dayjs(y1);
    y2 = dayjs(y2);
    //if y1 and y2 are the same day return true if x is on same day
    if (this.isSameDay(y1, y2)) {
      isSameOrBetweenDays = this.isSameDay(x, y1);
      return isSameOrBetweenDays;
    }
    isSameOrBetweenDays =
      x.isBetween(y1, y2) || this.isSameDay(x, y1) || this.isSameDay(x, y2);
    return isSameOrBetweenDays;
  }

  //checking x's range overlaps with y's range
  static overLaps(xStart, xEnd, yStart, yEnd) {
    let eventStart, rangeStart, eventEnd, rangeEnd;
    if (yStart) rangeStart = dayjs(yStart); //
    if (yEnd) rangeEnd = dayjs(yEnd);
    if (xStart) eventStart = dayjs(xStart);
    if (xEnd) eventEnd = dayjs(xEnd);
    // console.log("calendar", `${rangeStart.format("DD-MM")}:::${rangeEnd.format("DD-MM")}`)
    // console.log("event", `${eventStart.format("DD-MM")}:::${eventEnd?.format("DD-MM")}`)

    //if event.start || event.end is between calendar range
    if (
      eventStart?.isBetween(rangeStart, rangeEnd) ||
      eventEnd?.isBetween(rangeStart, rangeEnd)
    )
      return true;
    //if event start before range && event end is after range
    if (
      eventStart?.isSameOrBefore(rangeStart) &&
      eventEnd?.isSameOrAfter(rangeEnd)
    )
      return true;
    //if event don't have end date
    if (eventStart?.isSameOrBefore(rangeStart) && !eventEnd) return true;
    //if none event doesn't satisfy any of the conditions above - it's not in calendar range
    return false;
  }

  static createCalendarTimeString(date, time) {
    return `${dayjs(date).format("YYYY-MM-DD")} ${time}`;
  }

  static dateIsInArray(date, dates) {
    return dates?.some((date2) => {
      return dayjs(date).isSame(date2);
    });
  }
}
