<template>
  <div>
    <v-container>
      <v-row class="center-all ma-5">
        <h1>{{ $t("notifications.systemMessages") }}</h1>
      </v-row>
      <template v-if="userNotifications && userNotifications.length">
        <v-row v-for="(userNotification, i) in userNotifications" :key="i">
          <v-col>
            <user-notification-card :userNotification="userNotification" />
          </v-col>
        </v-row>
      </template>
      <h2 v-else class="text-center">{{ $t("notifications.noMessages") }}</h2>
    </v-container>
  </div>
</template>

<script>
import UserNotificationCard from "@/components/UserNotificationCard";
export default {
  components: { UserNotificationCard },
  name: "user-notifications",
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    userNotifications() {
      return this.$store.getters.userNotifications;
    },
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("userNotification/index");
      this.loading = false;
    },
  },
  async mounted() {
    if (!this.userNotifications?.length) {
      await this.loadData();
    }
  },
};
</script>
