<template>
  <v-container>
    <v-card>
      <v-tabs v-model="tab" centered>
        <v-tab href="#paths" v-text="$t('paths.paths')" />
        <v-tab href="#complexPaths" v-text="$t('complexPaths.complexPaths')" />
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="paths">
          <paths-tab />
        </v-tab-item>
        <v-tab-item value="complexPaths">
          <complex-paths-tab />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import ComplexPathsTab from "@/components/adminPathTabs/ComplexPathsTab";
import PathsTab from "@/components/adminPathTabs/PathsTab";

export default {
  name: "PathsView",
  components: { PathsTab, ComplexPathsTab },
  data() {
    return { tab: "paths" };
  },
};
</script>
