import announcementSound from "@/assets/audio/announcement-sound.mp3";
import ms from "ms";

const announcementAudio = new Audio(announcementSound);
let soundIntervalId = null;

export function playAnnouncementSoundOnce() {
  announcementAudio.currentTime = 0;
  announcementAudio.play();
}

export function startAnnouncementSoundInterval(intervalDelay = ms("5m")) {
  if (soundIntervalId) stopAnnouncementSoundInterval();

  playAnnouncementSoundOnce();
  soundIntervalId = setInterval(playAnnouncementSoundOnce, intervalDelay);
}

export function stopAnnouncementSoundInterval() {
  clearInterval(soundIntervalId);
  soundIntervalId = null;
}
