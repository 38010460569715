import Vue from "vue";
import Vuex from "vuex";
import moduleList from "@/store/vuexModules";

import Notifications from "../services/notifications";
import genericMixins from "../mixins/generic";
import NotificationService from "../services/notifications";
import I18n from "../i18n";

Vue.use(Vuex);
const modulesCreator = () => {
  let modules = {};
  moduleList.forEach((m) => {
    modules[m] = require("./modules/" + m).default;
  });
  return modules;
};
let vuexData = {
  state: { title: "viewPoint", snackbar: null },
  getters: {
    title: (state) => state.title,
    snackbar: (state) => state.snackbar,
  },
  mutations: {
    popSnack: (state, msg) => {
      state.snackbar = msg;
      setTimeout(() => (state.snackbar = null), 1000);
    },
  },
  actions: {
    async handleError(context, e) {
      if (e.response.status === 401)
        return await Notifications.timeoutSwal({
          icon: "error",
          title: "Forbiden, Try to login again",
        });

      return Notifications.timeoutSwal({
        icon: "error",
        title: genericMixins.methods.extractClapErrorEn(e),
      });
    },
  },
  modules: modulesCreator(),
};

const store = new Vuex.Store(vuexData);

store.subscribeAction({
  after: (action, state) => {
    const { disabled, title, message } = action.payload?.toastOptions || {};
    const actionSuffix = action.type.match(/\/[^/]+?$/)?.[0].replace("/", "");
    if (disabled || actionSuffix === "index") return;
    const toastMessageType =
      actionSuffix === "update"
        ? "successUpdate"
        : actionSuffix === "store"
        ? "successStore"
        : actionSuffix === "destroy"
        ? "successDestroy"
        : "success";

    NotificationService.successToast(
      title || "",
      message || I18n.t(`toasts.${toastMessageType}`)
    );
  },
});

export default store;
