var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',{staticClass:"d-flex align-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","icon":"","label":_vm.$t('generics.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"1"}},[(_vm.isAllowedToMutate)?_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('mdi-plus')},on:{"click":function($event){_vm.addDialog = true}}}):_vm._e(),_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('mdi-refresh')},on:{"click":_vm.loadData}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.computedHeaders,"items":_vm.complexPaths,"search":_vm.search,"loading":_vm.loading,"items-per-page":50},scopedSlots:_vm._u([{key:"item.paths",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',{attrs:{"column":""}},_vm._l((item.paths),function(tag,i){return _c('v-chip',{key:i,attrs:{"color":tag.color}},[_vm._v(_vm._s(tag.name))])}),1)]}},{key:"item.colors",fn:function(ref){
var item = ref.item;
return [_c('colors-pie-chart',{attrs:{"colors":item.paths.map( function (p) { return p.color; } )}})]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.time))]),_vm._v(" "+_vm._s(item.time && _vm.$t("date.minutes"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}])}),(_vm.addDialog)?_c('complex-path-handler',{attrs:{"isNew":true},on:{"save":_vm.addComplexPath},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}}):_vm._e(),(_vm.editDialog)?_c('complex-path-handler',{attrs:{"complexPath":_vm.chosen},on:{"save":_vm.editComplexPath},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }