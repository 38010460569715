import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import VueNumericInput from "vue-numeric-input";
import vuetify from "./plugins/vuetify";
import "./styles/global.scss";
import JsonExcel from "vue-json-excel";
import i18n from "./i18n";
import "izitoast/dist/css/iziToast.min.css";
import Notifications from "./plugins/notifications";
import GenericServices from "./plugins/generics";
import VueSocketIOExt from "vue-socket.io-extended";
import { io } from "socket.io-client";

//sockets
const socket = io();
Vue.config.productionTip = false;
Vue.use(VueSocketIOExt, socket, { store });

Vue.component("downloadExcel", JsonExcel);
Vue.use(Notifications);
Vue.use(GenericServices);
Vue.use(VueNumericInput);

(async () => {
  //checks user auth status
  try {
    await store.dispatch("auth/checkLogin", {
      toastOptions: { disabled: true },
    });
  } catch {
    console.log("No Auth");
  }

  const app = new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
  store.$vue = app;
})();
