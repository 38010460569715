const listsMixins = {
  computed: {
    userRoles() {
      return [
        { text: this.$t("userManagement.roleTypes.regular"), value: 4 },
        { text: this.$t("userManagement.roleTypes.escorter"), value: 3 },
        {
          text: this.$t("userManagement.roleTypes.coordinationCenter"),
          value: 2,
        },
        { text: this.$t("userManagement.roleTypes.correlation"), value: 1 },
        { text: this.$t("userManagement.roleTypes.admin"), value: 0 },
      ];
    },

    escortPathTypes() {
      return [
        { text: this.$t("escorts.types.foot"), value: "foot" },
        { text: this.$t("escorts.types.vehicle"), value: "vehicle" },
        { text: this.$t("escorts.types.vehicle-foot"), value: "vehicle-foot" },
      ];
    },

    escortTypes() {
      return [
        { text: this.$t("generics.oneTime"), value: "oneTime" },
        { text: this.$t("generics.permanent"), value: "permanent" },
      ];
    },

    days() {
      return [
        { text: this.$t("days.sunday"), value: 0 },
        { text: this.$t("days.monday"), value: 1 },
        { text: this.$t("days.tuesday"), value: 2 },
        { text: this.$t("days.wednesday"), value: 3 },
        { text: this.$t("days.thursday"), value: 4 },
        { text: this.$t("days.friday"), value: 5 },
        { text: this.$t("days.saturday"), value: 6 },
      ];
    },

    urgentLevels() {
      return [
        { text: this.$t("generics.urgent"), value: true },
        { text: this.$t("generics.regular"), value: false },
      ];
    },

    notificationAddresseeTypes() {
      return [
        { text: this.$t("generics.global"), value: "global" },
        {
          text: this.$t("escorts.escort"),
          value: "escort",
        },
        {
          text: this.$t("generics.user"),
          value: "single-user",
        },
      ];
    },

    calendarTypeItems() {
      return [
        { value: "day", text: this.$t("date.day") },
        { value: "week", text: this.$t("date.week") },
        { value: "month", text: this.$t("date.month") },
        { value: "category", text: this.$t("generics.color") },
      ];
    },
    colorsItems() {
      return [
        { name: "blue", color: "#039BE5", text: this.$t("colors.blue") },
        { name: "red", color: "#EB5A46", text: this.$t("colors.red") },
        { name: "orange", color: "#FF9F1A", text: this.$t("colors.orange") },
        { name: "green", color: "#61BD4F", text: this.$t("colors.green") },
        { name: "grey", color: "#808080", text: this.$t("colors.grey") },
      ];
    },
  },
};

export default listsMixins;
