var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-card',[_c('v-card-title',{staticClass:"d-flex align-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","icon":"","label":_vm.$t('generics.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('mdi-plus')},on:{"click":function($event){_vm.addDialog = true}}}),_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('mdi-refresh')},on:{"click":_vm.loadData}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.escorts,"loading":_vm.loading,"items-per-page":50,"options":_vm.options,"server-items-length":_vm.itemsCount},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('colors-pie-chart',{attrs:{"color":item.color}})]}},{key:"item.numberOfPassengers",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.numberOfPassengers(item))+" ")]}},{key:"item.days",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.dayKeys(item)))]}},{key:"item.date.start",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.extractDateString(item.date.start))+" ")]}},{key:"item.date.end",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.extractDateString(item.date.end))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.checkPermissionToMutate(item))?[_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]:_vm._e()]}}])})],1)],1),(_vm.addDialog)?_c('escort-handler',{attrs:{"isNew":true},on:{"save":_vm.save},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}}):_vm._e(),(_vm.editDialog)?_c('escort-handler',{attrs:{"escort":_vm.chosen},on:{"save":_vm.update},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }