import myAxios from "../../services/myAxios";

export default {
  state: {
    userNotification: null, //chosen userNotification
    userNotifications: [],
    filtered: [],
  },
  getters: {
    userNotifications: (state) => state.userNotifications,
    userNotification: (state) => state.userNotification,
    userNotificationsFiltered: (state) => state.filtered,
  },
  mutations: {
    //sets all userNotifications
    "userNotifications/set": (state, payload) => {
      state.userNotifications = payload;
      state.filtered = [...state.userNotifications];
    },
    //sets one userNotification
    "userNotification/set": (state, payload) =>
      (state.userNotification = payload),
    //filters the userNotification's array by userNotification's key and userNotification's val
    "userNotifications/filter": (state, { key, val }) => {
      state.filtered = !val
        ? [...state.userNotifications]
        : state.userNotifications.filter((f) => f[key] === val);
    },
    //store one userNotification
    "userNotification/store": (state, payload) =>
      state.userNotifications.push(payload),
    //destroys one userNotification
    "userNotification/destroy": (state, id) =>
      (state.userNotifications = state.userNotifications.filter((item) => {
        return item._id !== id;
      })),
    //updates one userNotification
    "userNotification/update": (state, payload) => {
      state.userNotifications = state.userNotifications.map((item) => {
        if (item._id === payload._id) {
          return payload;
        }
        return item;
      });
    },
  },
  actions: {
    //fetch all userNotifications
    "userNotification/index": async (context) => {
      const { data } = await myAxios.get("/userNotification");
      context.commit("userNotifications/set", data);
    },
    //fetch one userNotification by id
    "userNotification/show": async (context, id) => {
      let { data } = await myAxios.get("/userNotification/" + id);
      context.commit("userNotification/set", data);
    },
    //stores one userNotification
    "userNotification/store": async (context, payload) => {
      let { data } = await myAxios.post("/userNotification", { ...payload });
      context.commit("userNotification/store", data);
    },
    //destroys one userNotification
    "userNotification/destroy": async (context, id) => {
      await myAxios.delete("/userNotification/" + id);
      context.commit("userNotification/destroy", id);
    },
    //updates one userNotification by its id
    "userNotification/update": async (context, payload) => {
      await myAxios.put("/userNotification/" + payload._id, payload);
      context.commit("userNotification/update", payload);
    },
    /**
     * invokes the action of the notification
     * @param {*} payload.notification the notification id
     * @param {*} payload.userNotification the userNotification id
     * @param {*} payload.actionLink the action link attached to notification
     * @param {*} payload.status the approval status ["approve", "decline"]
     */
    "userNotification/handleAction": async (context, payload) => {
      await myAxios.post(payload.actionLink, { status: payload.status });
      await context.dispatch(
        "userNotification/destroy",
        payload.userNotification
      );
    },
  },
};
