<template>
  <v-simple-table v-if="passengers.length">
    <thead>
      <tr>
        <th v-text="$t('generics.passenger')" />
        <th v-text="$t('generics.numberOfPeople')" />
        <th v-text="$t('generics.notes')" />
        <th v-if="isEditMode" v-text="$t('generics.actions')" />
      </tr>
    </thead>
    <tbody>
      <tr v-for="p in passengers" :key="p._id">
        <td>{{ p.passenger.name }}</td>
        <td>
          <number-picker v-if="isEditMode" v-model="p.numberOfPassengers" :min="1" :max="maxPassengers" :step="1" />
          <span v-else>
            {{ p.numberOfPassengers }}
          </span>
        </td>
        <td>{{ p.notes }}</td>
        <td v-if="isEditMode">
          <v-icon @click="rmPassenger(p)">mdi-delete</v-icon>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>
<script>
import NumberPicker from "@/components/generics/NumberPicker";
export default {
  name: "passengers-table-handler",
  components: { NumberPicker },
  props: {
    value: {
      type: Array,
      required: true,
    },
    maxPassengers: { type: Number, default: 5 },
    isEditMode: { type: Boolean, default: true },
    areEmptySpots: { type: Boolean, default: true },
  },
  computed: {
    passengers: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    rmPassenger(passenger) {
      this.passengers = this.passengers.filter(
        (p) => p.passenger !== passenger.passenger
      );
    },
  },
};
</script>
