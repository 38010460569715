<template>
  <div>
    <my-dialog :title="point ? $t('points.edit') : $t('points.new')" v-model="openModal">
      <template v-slot:content>
        <v-row>
          <v-col>
            <v-text-field
              :label="$t('generics.name')"
              v-model="name"
              autofocus
              @keypress.enter="submit"
            />
          </v-col>
        </v-row>
        <v-row v-if="err">
          <v-col class="error--text text-center">{{ err }}</v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn
          text
          color="primary"
          v-text="isNew ? $t('generics.add') : $t('generics.save')"
          @click="submit"
        />
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from "@/components/generics/MyDialog";
export default {
  name: "point-handler",
  components: { MyDialog },
  props: { value: Boolean, point: Object, isNew: Boolean },
  data: () => ({
    name: "",
    err: null,
  }),
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async submit() {
      if (!this.validateForm()) {
        return this.createErr(
          `${this.$t("generics.name")} ${this.$t("errors.notEmpty")}`
        );
      }
      this.$emit("done", this.name);
      this.name = "";
    },
    validateForm() {
      return this.name.length != 0;
    },
    createErr(msg) {
      this.err = msg;
      setTimeout(() => {
        this.err = null;
      }, 2000);
      return;
    },
  },
  mounted() {
    if (!this.isNew) {
      this.name = this.point.name;
    }
  },
};
</script>
