var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mobile-view',[_c('h4',{staticClass:"text-center",domProps:{"textContent":_vm._s(_vm.$t('dashboard.allEescorts'))}}),_c('v-toolbar',{staticClass:"d-flex justify-space-around",attrs:{"flat":""}},[_c('v-btn',{attrs:{"text":"","small":_vm.$vuetify.breakpoint.xsOnly,"color":"primary"},on:{"click":_vm.setToday}},[_vm._v(_vm._s(_vm.$t("date.today")))]),_c('v-btn',{attrs:{"fab":"","x-small":_vm.$vuetify.breakpoint.xsOnly,"text":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1),(_vm.$refs.calendar)?_c('span',{staticClass:"mx-2 text-sm-h6 subtitle-1",domProps:{"textContent":_vm._s(_vm.monthString())}}):_vm._e(),_c('v-btn',{attrs:{"fab":"","x-small":_vm.$vuetify.breakpoint.xsOnly,"text":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{attrs:{"fab":"","small":_vm.$vuetify.breakpoint.xsOnly,"text":"","color":"primary"},on:{"click":_vm.loadData}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-btn',{attrs:{"fab":"","small":_vm.$vuetify.breakpoint.xsOnly,"text":"","color":"green"},on:{"click":_vm.toggleCalendarType}},[_c('v-icon',[_vm._v("mdi-calendar-edit")])],1),_c('color-filter',{model:{value:(_vm.colorsFilter),callback:function ($$v) {_vm.colorsFilter=$$v},expression:"colorsFilter"}})],1),_c('v-sheet',{attrs:{"height":"82vh","dir":_vm.$i18n.locale === 'he' ? 'rtl' : 'ltr'}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","events":_vm.filteredEvents,"categories":_vm.colorsItems,"category-text":"text","category-show-all":"","event-category":function (ref) {
	var color = ref.color;

	return _vm.$t(("colors." + color));
},"type":_vm.calendarType,"event-overlap-mode":"column","interval-height":70,"event-overlap-threshold":"10","event-color":function (ref) {
	var isDone = ref.isDone;
	var color = ref.color;

	return isDone ? '#ffffff' : color;
},"event-text-color":function (ref) {
	var isDone = ref.isDone;

	return isDone ? 'success' : '#ffffff';
}},on:{"click:more":_vm.showEvent,"click:date":_vm.showEvent,"click:day":_vm.showEvent,"change":_vm.updateRange,"click:event":_vm.showEvent},model:{value:(_vm.today),callback:function ($$v) {_vm.today=$$v},expression:"today"}})],1),(_vm.showDialog)?_c('user-escort-display',{attrs:{"escort":_vm.chosenEscort,"event":_vm.chosenEvent,"today":_vm.chosenEvent.start},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}}):_vm._e(),(_vm.createEscortDialog)?_c('user-escort-handler',{model:{value:(_vm.createEscortDialog),callback:function ($$v) {_vm.createEscortDialog=$$v},expression:"createEscortDialog"}}):_vm._e(),_c('float-btn',{on:{"cb":function($event){_vm.createEscortDialog = true}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }