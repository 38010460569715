<template>
	<div>
		<v-simple-table v-if="escort">
			<tbody>
				<tr
					v-for="item in items"
					:key="item.key"
				>
					<td v-text="item.key" />
					<td v-text="item.val" />
				</tr>
			</tbody>
		</v-simple-table>
		<passengers-table-handler
			v-model="escort.passengers"
			:isEditMode="false"
		/>
	</div>
</template>

<script>
import times from "@/mixins/times";
import PassengersTableHandler from "@/components/handlers/PassengersTableHandler.vue";

export default {
	name: "EscortDisplayTable",
	// props: { escort: Object },
	mixins: [times],
	components: {
		PassengersTableHandler,
	},
	computed: {
		escort() {
			return this.$store.getters.escort || {};
		},
		items() {
			return [
				{
					key: this.$t("escorts.type"),
					val: this.$t("escorts.types." + this.escort?.path?.type),
				},
				{
					key: this.$t("paths.path"),
					val: this.escort?.path?.name + (this.escort?.isCircular ? ` (${this.$t("paths.isCircular")})`: ''),
				},
				{
					key: this.$t("generics.days"),
					val: this.allDays,
				},
				{
					key: this.$t("generics.date"),
					val: this.datesRange,
				},
				{
					key: this.$t("generics.time"),
					val: this.timeRange,
				},
				{
					key: this.$t("generics.escorters"),
					val: this.escortersString,
				},
				{
					key: this.$t("generics.passengers"),
					val: this.passengersString,
				},
				{
					key: this.$t("escorts.numberOfPassengers"),
					val: this.numberOfPassengers,
				},
			];
		},
		allDays() {
			let str = "";
			this.escort?.days?.forEach(
				(d) => (str += this.$t(`days[${d}].short`) + " ")
			);
			return str;
		},
		datesRange() {
      let rangeStr = this.extractDateString(
      this.escort?.date?.start);
      if(this.escort?.date?.end) 
        rangeStr += ` - ${this.extractDateString(this.escort?.date?.end)}`
      return rangeStr;
		},
		timeRange() {
			return `${this.escort?.time?.start} - ${this.escort?.time?.end}`;
		},
		escortersString() {
			let str = "";
			this.escort?.escorters?.forEach((e) => (str += `${e.name} `));
			return str;
		},
		passengersString() {
			let str = "";
			this.escort?.passengers?.forEach(
				(p) => (str += `${p?.passenger?.name} `)
			);
			return str;
		},
		numberOfPassengers() {
			let str = 0;
			this.escort?.passengers?.forEach((p) => (str += p?.numberOfPassengers));
			return str;
		},
	},
};
</script>

<style scoped>
</style>
