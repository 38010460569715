import myAxios from "../../services/myAxios";

export default {
  state: {
    user: null, //chosen user
    users: [],
    filtered: [],
  },
  getters: {
    users: (state) => state.users,
    user: (state) => state.user,
    usersFiltered: (state) => state.filtered,
    usersByRole: (state) => (role) => {
      if (role) {
        return state.users.filter((user) => user.role === role);
      }
      return state.users;
    },
    escorters: (state) => state.users.filter((user) => user.role === 3),
    passengers: (state) => state.users.filter((user) => user.role === 4),
  },
  mutations: {
    //sets all users
    "users/set": (state, payload) => {
      state.users = payload;
      state.filtered = [...state.users];
    },
    //sets one user
    "user/set": (state, payload) => (state.user = payload),
    //filters the user's array by user's key and user's val
    "users/filter": (state, { key, val }) => {
      state.filtered = !val
        ? [...state.users]
        : state.users.filter((f) => f[key] === val);
    },
    //store one user
    "user/store": (state, payload) => state.users.push(payload),
    //destroys one user
    "user/destroy": (state, id) =>
      (state.users = state.users.filter((item) => {
        return item._id !== id;
      })),
    //updates one user
    "user/update": (state, payload) => {
      state.users = state.users.map((item) => {
        if (item._id === payload._id) {
          return payload;
        }
        return item;
      });
    },
  },
  actions: {
    //fetch all users
    "users/index": async (context) => {
      const { data } = await myAxios.get("/user");
      context.commit("users/set", data);
    },
    //fetch one user by id
    "user/show": async (context, id) => {
      let { data } = await myAxios.get("/user/" + id);
      context.commit("user/set", data);
    },
    //stores one user
    "user/store": async (context, payload) => {
      let { data } = await myAxios.post("/user", { ...payload });
      context.commit("user/store", data);
    },
    //destroys one user
    "user/destroy": async (context, id) => {
      await myAxios.delete("/user/" + id);
      context.commit("user/destroy", id);
    },
    //updates one user by its id
    "user/update": async (context, payload) => {
      const baseUrl = context.getters.role >= 3 ? "/user/user/" : "/user/";
      await myAxios.put(baseUrl + payload._id, payload);
      context.commit("user/update", payload);
    },
  },
};
