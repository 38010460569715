<template>
  <div>
    <my-dialog :title="escort.name" v-model="openModal">
      <template v-slot:content>
        <v-alert v-if="notes" type="info">{{ notes }}</v-alert>
        <escort-display-table :escort="escort" />
      </template>
      <template v-slot:actions>
        <v-btn v-if="isEscorter" @click="toggleDoneStatus" :loading="loading" width="120" text
          :color="computedIsEscortDone ? 'success' : 'grey'">
          <v-icon class="ml-1">{{ !computedIsEscortDone ? 'mdi-times' : 'mdi-check' }}</v-icon>
          {{ computedIsEscortDone ? $t('generics.done') : $t('generics.undone') }}
        </v-btn>
        <template v-else-if="$store.getters.role === 4">
          <div v-if="!showAction" class="d-flex flex-column align-center justify-center" style="width: 100%">
            <v-btn v-if="userInEscort" v-text="$t('generics.leave')" :color="userInEscort ? 'red' : 'primary'"
              @click="leave" text />
            <v-btn v-if="!userInEscort && maxPassengers" v-text="$t('generics.join')"
              :color="userInEscort ? 'red' : 'primary'" @click="showAction = true" text />
            <v-alert v-else-if="!maxPassengers" dense text type="error" v-text="$t('escorts.theEscortFull')" />
          </div>
          <div v-if="showAction" class="d-flex flex-column align-center justify-center mx-5" style="width: 100%">
            <v-row class="align-self-stretch mb-2" align="end">
              <v-col cols="12" sm="6">
                {{
                    $t("errors.validate.minAndMaxPassenger", {
                      max: userInEscort ? escort.numberOfPassengers : maxPassengers,
                    })
                }}:
                <v-text-field v-model.number="form.numberOfPassengers" class="center-all" style="text-align:center"
                  :rules="[(v) => v > 0 || $t('errors.notEmpty')]" required type="number"
                  :max="userInEscort ? escort.numberOfPassengers : maxPassengers" min="1" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-textarea v-model="form.notes" :label="$t('generics.notes')" rows="1" />
              </v-col>
            </v-row>
            <v-btn v-if="userInEscort" v-text="$t('generics.leave')" :color="userInEscort ? 'red' : 'primary'"
              @click="leave" text />
            <v-btn v-if="!userInEscort && maxPassengers" v-text="$t('generics.join')"
              :color="userInEscort ? 'red' : 'primary'" @click="join" text />
          </div>
        </template>
      </template>
    </my-dialog>
  </div>
</template>
<script>
/**
 * This component display escort for user
 */
import MyDialog from "@/components/generics/MyDialog";
import EscortDisplayTable from "@/components/EscortDisplayTable";
import times from "@/mixins/times";
import sockets from '@/mixins/sockets';
import Times from '@/helpers/times.js';

export default {
  name: "user-escort-display",
  components: {
    MyDialog,
    EscortDisplayTable,
  },
  props: {
    value: Boolean,
    escort: Object,
    today: String,
    event: {
      type: Object,
      default: () => { },
    },
  },
  mixins: [times, sockets],
  data: () => ({
    showAction: false,
    openNumberModal: true,
    loading: false,

    form: {
      numberOfPassengers: 1,
      notes: "",
    },
  }),
  computed: {
    maxPassengers() {
      return this.escort.maxPassengers - this.escort.numberOfPassengers;
    },
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    // if user is in this escort
    userInEscort() {
      let uid = this.$store.getters.uid;
      let find = this.escort?.passengers.find(
        (p) => p?.passenger?._id === uid || p?.passenger === uid
      );
      return find !== undefined;
    },
    notes() {
      return this.escort?.notes;
    },
    isEscorter() {
      return this.$store.getters.role === 3
        && this.escort.escorters.some(({ _id }) => _id === this.$store.getters.uid);
    },
    computedIsEscortDone() {
      return Times.dateIsInArray(this.event.start, this.escort.doneEscorts);
    }
  },
  methods: {
    //User want to join this escort
    async join() {
      if (!this.form.numberOfPassengers) return;
      let choice = await this.$selectSwal(this.$t("escorts.joinType"), {
        all: this.$t("escorts.totalJoin"),
        once: this.$t("escorts.joinOnce", { date: this.today }),
      });
      if (!choice) return;
      const isPermanent = choice === "all";

      let data = {
        isPermanent,
        escortId: this.escort._id,
        ...this.form,
        toastOptions: {
          message: this.$t("notifications.joinToEscortSuccess", { escortName: this.escort.name }),
        },
      };
      if (!isPermanent) data["date"] = this.today;
      await this.$store.dispatch("escort/join", data);
      this.socket_join_escort(this.escort);
      this.close();
    },
    //User want to leave this escort
    async leave() {
      let choice = await this.$selectSwal(this.$t("swals.leaveType"), {
        all: this.$t("escorts.totalLeave"),
        once: this.$t("escorts.leaveOnce", { date: this.today }),
      });
      if (!choice) return;
      const isPermanent = choice === "all";

      let data = {
        isPermanent,
        date: this.today,
        id: this.escort._id,
        toastOptions: {
          message: this.$t("toasts.leftEscort", { escort: this.escort.name }),
        },
      };
      await this.$store.dispatch("escort/leave", data);
      this.socket_leave_escort({ isPermanent, escort: this.escort });
      this.close();
    },
    close() {
      this.openModal = false;
    },
    async toggleDoneStatus() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "escort/change-done-status",
          { id: this.escort._id, isDone: !this.computedIsEscortDone, date: this.event.start });
      } finally {
        this.loading = false;
        this.close();
      }
    }
  },
};
</script>
