import myAxios from "../../services/myAxios";

export default {
  state: {
    point: null, //chosen point
    points: [],
    filtered: [],
  },
  getters: {
    points: (state) => state.points,
    point: (state) => state.point,
    pointsFiltered: (state) => state.filtered,
  },
  mutations: {
    //sets all points
    "points/set": (state, payload) => {
      state.points = payload;
      state.filtered = [...state.points];
    },
    //sets one point
    "point/set": (state, payload) => (state.point = payload),
    //filters the point's array by point's key and point's val
    "points/filter": (state, { key, val }) => {
      state.filtered = !val
        ? [...state.points]
        : state.points.filter((f) => f[key] === val);
    },
    //store one point
    "point/store": (state, payload) => state.points.push(payload),
    //destroys one point
    "point/destroy": (state, id) =>
      (state.points = state.points.filter((item) => {
        return item._id !== id;
      })),
    //updates one point
    "point/update": (state, payload) => {
      state.points = state.points.map((item) => {
        if (item._id === payload._id) {
          return payload;
        }
        return item;
      });
    },
  },
  actions: {
    //fetch all points
    "point/index": async (context) => {
      const { data } = await myAxios.get("/point");
      context.commit("points/set", data);
    },
    //fetch one point by id
    "point/show": async (context, id) => {
      let { data } = await myAxios.get("/point/" + id);
      context.commit("point/set", data);
    },
    //stores one point
    "point/store": async (context, payload) => {
      let { data } = await myAxios.post("/point", { ...payload });
      context.commit("point/store", data);
    },
    //destroys one point
    "point/destroy": async (context, id) => {
      await myAxios.delete("/point/" + id);
      context.commit("point/destroy", id);
    },
    //updates one point by its id
    "point/update": async (context, payload) => {
      await myAxios.put("/point/" + payload._id, payload);
      context.commit("point/update", payload);
    },
  },
};
