<template>
  <div>
    <my-dialog
      :title="isNew ? $t('escorts.new') : $t('escorts.edit')"
      v-model="openModal"
      :persistent="true"
    >
      <template v-slot:content>
        <v-form ref="form" v-model="valid" lazy-validation>
					<v-text-field
						:value="computedDefaultEscortName"
						:label="$t('generics.name')"
						:rules="[(v) => v.length >= 1 || $t('errors.notEmpty')]"
						required
            disabled
					/>
          <v-row align="center">
            <v-col cols="12" sm="7">
              <v-autocomplete
                @input="onInputPath"
                :value="form.path"
                :items="paths"
                :label="$t('paths.path')"
                item-text="name"
                return-object
                clearable
                :rules="[(v) => !!v || $t('errors.notEmpty')]"
              />
            </v-col>
            <v-col cols="6" sm="2">
              <div class="d-flex flex-column align-center">
                <v-switch
                  v-model="isComplexPath"
                  :label="
                    $t(isComplexPath ? 'generics.complex' : 'generics.regular')
                  "
                  @click="() => (form.path = null)"
                  class="mt-0"
                  hide-details
                />
                <span
                v-if="form.path"
                v-text="typeString"
                />
              </div>
            </v-col>
            <v-col cols="6" sm="3">
              <v-switch
                v-model="form.isCircular"
                :label="$t('paths.isCircular')"
                class="mt-0"
                hide-details
              />
            </v-col>
          </v-row>
          <my-date-picker
            v-model="form.date.start"
            :end="form.date.end"
            :label="$t('generics.date')"
          />
          <v-row justify="space-between">
            <v-col cols="6" class="center-all" v-if="form.path">
              <my-time-picker v-model="form.time.start" :label="$t('date.timeStart')" />
            </v-col>
            <v-col cols="6" v-if="computedTimeEnd" class="center-all-col">
              <span>{{ `${$t('date.timeEnd')}` }}</span>
              <br />
              <span>{{ computedTimeEnd }}</span>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="form.escorters"
                :items="escorters"
                :label="$t('generics.escorters')"
                item-text="name"
                item-value="_id"
                clearable
                multiple
                :rules="[(v) => !!v.length || $t('errors.notEmpty')]"
              />
            </v-col>
            <v-col cols="12">
              <passengers-table
                v-model="form.passengers"
                :maxPassengers="form.maxPassengers"
                @maxPassengersHandler="maxPassengersHandler"
              />
            </v-col>
            <color-platen v-model="form.color" />
            <v-col cols="12">
              <v-textarea v-model="form.notes" :label="$t('generics.notes')" rows="1" />
            </v-col>
          </v-row>
        </v-form>
        <v-row v-if="err">
          <v-col class="error--text text-center">{{ err }}</v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn
          text
          color="primary"
          v-text="isNew ? $t('generics.add') : $t('generics.change')"
          @click="isNew ? create() : update()"
          :disabled="!valid"
        />
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from "@/components/generics/MyDialog";
import MyDatePicker from "@/components/generics/MyDatePicker";
import MyTimePicker from "@/components/generics/MyTimePicker";
import ColorPlaten from "@/components/generics/ColorPlaten";
import PassengersTable from "@/components/PassengersTable";
import _ from "lodash";
import times from "@/mixins/times";

export default {
  name: "single-escort-handler",
  components: {
    MyDialog,
    MyDatePicker,
    MyTimePicker,
    PassengersTable,
    ColorPlaten
  },
  props: {
    value: Boolean,
    isNew: {
      type: Boolean,
      default: false,
    },
    escort: { type: Object, default: () => ({}) },
    initialData: {
      type: Object,
    },
  },
  mixins: [times],
  data() {
    return {
      form: {
        isSingle: true,
        name: "",
        color: undefined,
        date: {
          start: this.extractDateString(),
          end: null
        },
        numberOfPassengers: 1,
        escorters: [],
        maxPassengers: 19,
        passengers: [],
        time: { start: "" },
        pathModel: "Path",
        path: "",
        isCircular: false,
        notes: "",
      },
      count: 7,
      err: null,
      valid: true,
      isComplexPath: false,
      loading: false,
    };
  },
  watch: {
    isComplexPath(v) {
      this.form.pathModel = v ? "ComplexPath" : "Path";
    },
    computedTimeEnd(v) {
      this.form.time.end = v;
    }
  },
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
		escorters() {
			return this.$store.getters.escorters;
		},
    passengers() {
      return this.$store.getters.passengers;
    },
    paths() {
      return this.isComplexPath
        ? this.$store.getters.complexPaths
        : this.$store.getters.paths;
    },
    typeString() {
      let str = "";
      str += this.$t('escorts.type') + ": ";
      str += this.$t(`escorts.types.${this.form.path?.type}`);
      return str;
    },
    computedTimeEnd() {
     if (this.form.time.start && this.form.date.start && this.form.path) {
        let d = this.setDateTime(this.form.date.start, this.form.time.start)
        const durationTime = (this.form.isCircular ? 2 : 1) * this.form.path?.time;
        let result = d.add(durationTime, "m")
        return this.extractTimeString(result);
      } else return ""
    },
    computedDefaultEscortName() {
      return `${this.form.time?.start || '00:00'} - ${this.form.path?.name || 'שם מסלול'}`
    }
  },
  methods: {
    async create() {
      this.valid = false;
      const data = {...this.form, name: this.computedDefaultEscortName};
      await this.$store.dispatch("escort/store", data);
      this.close();
      this.valid = true
    },
    async update() {
      const data = {...this.form, name: this.computedDefaultEscortName};
      await this.$store.dispatch("escort/update", data);
      this.close();
    },
    maxPassengersHandler(v) {
      this.form.maxPassengers = v;
    },
    validateForm() {
      this.$refs.form.validate();
      return (
        this.form.name &&
        this.form.date.start &&
				this.form.escorters.length &&
        this.form.passengers.length &&
        this.form.path
      );
    },
    close() {
      this.openModal = false;
    },
    onInputPath(val) {
      this.form.path = val;
      if(!val) return;
      this.form.color = this.isComplexPath ? val.paths[0].color : val.color;
    }
  },
  async created() {
    this.loading = true;
    if (!this.isNew || this.initialData) {
      this.form = _.cloneDeep(!this.isNew ? this.escort : this.initialData);
      this.isComplexPath = this.form.pathModel === "ComplexPath";
      this.form.date = {
        start: this.extractDateString(this.form?.date?.start),
        end: this.extractDateString(this.form?.date?.end),
      };
      this.useDefaultEscortName = false;
    } else {
      this.form.date.start = this.date;
    }
    if (!this.paths.length) {
      await this.$store.dispatch("path/index");
      await this.$store.dispatch("complexPath/index");
    }
    if (!this.passengers.length || !this.escorters.length)
      this.$store.dispatch("users/index");
    this.loading = false;
    this.form.color = this.escort.color;
  },
};
</script>
