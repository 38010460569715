var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',{staticClass:"d-flex align-center"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","icon":"","label":_vm.$t('generics.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"center-all",attrs:{"cols":"3"}},[_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('mdi-refresh')},on:{"click":_vm.loadData}}),_c('v-btn',{attrs:{"text":"","color":"red"},domProps:{"textContent":_vm._s(_vm.$t('generics.deleteAll'))},on:{"click":_vm.deleteMany}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"items-per-page":20,"sort-by":"createdAt"},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.isUrgentClassName},[_vm._v(_vm._s(_vm.clacCreatedAtString(item.createdAt)))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.isUrgentClassName},[_vm._v(_vm._s(item.passenger.name))])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.isUrgentClassName},[_vm._v(_vm._s(item.passenger.phone))])]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [(_vm.getGoogleMapsUrl(item.location.coordinates))?_c('v-btn',{class:_vm.isUrgentClassName,attrs:{"text":"","href":_vm.getGoogleMapsUrl(item.location.coordinates),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-map-marker")])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }