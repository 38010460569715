<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title class="d-flex align-center">
          <v-row>
            <v-col cols="11">
              <v-row>
                <v-col cols="6">
                  <!-- <v-row> -->
                  <v-select
                    class="ml-1"
                    :items="userRoleItems"
                    :label="$t('userManagement.role')"
                    v-model="roleQuery"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    icon
                    :label="$t('generics.search')"
                    single-line
                    hide-details
                  ></v-text-field>
                  <!-- </v-row> -->
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1">
              <v-icon
                color="primary"
                v-if="isAllowedToMutate"
                @click="addDialog = true"
                v-text="'mdi-plus'"
              />
              <v-icon color="primary" @click="loadData" v-text="'mdi-refresh'" />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="computedHeaders"
          :items="items"
          :search="search"
          :loading="loading"
          :items-per-page="20"
        >
          <template v-slot:item.role="{ item }">{{ getRoleLabel(item) }}</template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex">
              <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
              <v-icon @click="editItem(item)">mdi-pencil</v-icon>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <user-handler v-if="addDialog" v-model="addDialog" />
    <user-handler
      v-if="editDialog"
      v-model="editDialog"
      :isEditMode="true"
      :selectedUser="selectedUser"
    />
  </div>
</template>

<script>
import UserHandler from "@/components/handlers/UserHandler";
import { userRoles } from "@/data/lists";

export default {
  components: { UserHandler },
  data() {
    return {
      search: "",
      roleQuery: "all",
      loading: false,
      addDialog: false,
      editDialog: false,
      selectedUser: null,
    };
  },
  computed: {
    computedHeaders() {
      const headers = [
        { text: this.$t("userManagement.name"), value: "name" },
        { text: this.$t("userManagement.email"), value: "email" },
        { text: this.$t("userDetails.phone"), value: "phone" },
        {
          text: this.$t("userManagement.role"),
          value: "role",
          filter: (value) => {
            if (this.roleQuery === "" || this.roleQuery === "all") return true;
            return value === this.roleQuery;
          },
        },
      ];
      if (this.isAllowedToMutate) headers.push(
        {
          text: `${this.$t("generics.actions")}`,
          value: "actions",
          width: "10%",
        },
      )
      return headers
    },
    isAllowedToMutate() {
      return this.$store.getters.role <= 1
    },
    items() {
      return this.$store.getters.users;
    },
    userRoleItems() {
      return [
        {
          text: this.$t("userManagement.allUsers"),
          value: "all",
        },
        ...userRoles(this),
      ];
    },
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("users/index");
      this.loading = false;
    },
    editItem(item) {
      this.selectedUser = item;
      this.editDialog = true;
    },
    async deleteItem(item) {
      let { isConfirmed } = await this.$createSwal({
        icon: "warning",
        title: `${this.$t("generics.delete")} ${item.name}?`,
      });
      if (isConfirmed) {
        await this.$store.dispatch("user/destroy", item._id);
      }
    },
    getRoleLabel(item) {
      return userRoles(this).find(({ value }) => value === item.role).text;
    },
  },
  async mounted() {
    if (!this.items.length) {
      await this.loadData();
    }
  },
};
</script>

<style></style>
