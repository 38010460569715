<template>
  <my-dialog :title="`${pendingEscort.name} - ${$t('escorts.pendingMsg')}`" v-model="openModal">
    <template v-slot:content>
      <h3 class="text-center my-2">{{ $t("generics.changes") }}</h3>
      <escort-display-table v-if="escort" :escort="escort" />
      <h3 class="text-center my-2">{{ $t("generics.status") }}</h3>
      <div v-if="escort.approvals && escort.approvals.length">
        <v-simple-table>
          <thead>
            <tr>
              <th v-text="$t('generics.passenger')" />
              <th v-text="$t('generics.status')" />
            </tr>
          </thead>
          <tbody>
            <tr v-for="ap in escort.approvals" :key="ap._id">
              <td v-text="ap.passenger.name" />
              <td v-text="$t('generics.' + ap.status)" />
            </tr>
          </tbody>
        </v-simple-table>
      </div>
      <div v-else class="center-all-col" v-text="$t('escorts.usersDidntApprove')" />
    </template>
    <template v-slot:actions>
      <v-btn v-text="$t('generics.remove')" class="red--text" text @click="deleteChild" />
      <v-btn v-text="$t('generics.forceChanges')" class="orange--text" text @click="create" />
    </template>
  </my-dialog>
</template>

<script>
import MyDialog from "@/components/generics/MyDialog";
import EscortDisplayTable from "@/components/EscortDisplayTable";
import _ from "lodash";
export default {
  name: "PendingEscortHandler",
  props: { value: Boolean, pendingEscort: Object },
  components: { EscortDisplayTable, MyDialog },
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    escort() {
      return this.$store.getters.escort;
    },
  },
  methods: {
    async deleteChild() {
      let { isConfirmed } = await this.$createSwal({
        title: this.$t("errors.escorts.deletePending"),
      });
      if (isConfirmed) {
        // console.log(this.escort._id);
        await this.$store.dispatch("escort/destroy", this.escort);
        this.close();
      }
    },
    //Ignore pending and creates the child
    async create() {
      let { isConfirmed } = await this.$createSwal({
        title: this.$t("escorts.forceChangesMsg"),
      });
      if (isConfirmed) {
        let data = _.cloneDeep(this.escort);
        delete data.approvals;
        data.pending = false;
        await this.$store.dispatch("escort/update", data);
        this.close();
      }
    },
    async loadEscort() {
      await this.$store.dispatch("escort/show", this.pendingEscort?._id);
    },
    close() {
      this.$emit("close");
    },
  },
  async mounted() {
    await this.loadEscort();
  },
};
</script>
