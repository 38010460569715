<template>
  <div>
    <my-dialog :title="$t('escorts.new')" v-model="openModal" :persistent="true">
      <template v-slot:content>
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="d-flex justify-center align-center">
            <v-switch v-model="form.isSingle" />
            <h4 class="text-center mx-3">סוג מסלול</h4>
            <span>{{ form.isSingle ? $t('generics.oneTime') : $t('generics.permanent') }}</span>
          </div>
          <v-row>
            <v-text-field :value="computedDefaultEscortName" disabled :label="$t('generics.name')" />
          </v-row>
          <v-row>
            <v-col cols="12" sm="7">
              <v-autocomplete
                @input="onInputPath"
                :value="form.path"
                :items="paths"
                :label="$t('paths.path')"
                item-text="name"
                return-object
                clearable
                :rules="[(v) => !!v || $t('errors.notEmpty')]"
              />
            </v-col>
            <v-col cols="6" sm="2">
              <div class="d-flex flex-column align-center">
                <v-switch v-model="isComplexPath" :label="
                  $t(isComplexPath ? 'generics.complex' : 'generics.regular')
                " @click="() => (form.path = null)" class="mt-0" hide-details />
                <span v-if="form.path" v-text="typeString" />
              </div>
            </v-col>
            <v-col cols="6" sm="3">
              <v-switch v-model="form.isCircular" :label="$t('paths.isCircular')" class="mt-0" hide-details />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <my-date-picker v-model="form.date.start" :end="form.date.end" :label="$t('date.dateStart')" validate />
            </v-col>
            <v-col v-if="!form.isSingle">
              <my-date-picker v-model="form.date.end" :start="form.date.start" :label="$t('date.dateEnd')" />
            </v-col>
          </v-row>
          <v-row v-if="!form.isSingle" justify="center">
            <dayjs-picker v-model="form.days" />
          </v-row>
          <v-row justify="space-between">
            <v-col cols="6" class="center-all">
              <my-time-picker v-if="form.path" v-model="form.time.start" :label="$t('date.timeStart')" />
            </v-col>
            <v-col cols="6" v-if="computedTimeEnd" class="center-all-col">
              <span>{{ `${$t('date.timeEnd')}` }}</span>
              <br />
              <span>{{ computedTimeEnd }}</span>
            </v-col>
            <v-col cols="12">
              <color-platen v-model="form.color" />
            </v-col>
            <v-col cols="12">
              <number-picker label="כמות משתתפים" v-model="form.numberOfPassengers" :step="1" :min="1" />
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="form.notes" :label="$t('generics.notes')" rows="1" />
            </v-col>
          </v-row>
        </v-form>
        <v-row v-if="err">
          <v-col class="error--text text-center">{{ err }}</v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn text color="primary" v-text="$t('generics.add')" @click="create()" :disabled="!valid" />
      </template>
    </my-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import MyDialog from "@/components/generics/MyDialog";
import MyDatePicker from "@/components/generics/MyDatePicker";
import MyTimePicker from "@/components/generics/MyTimePicker";
import ColorPlaten from "@/components/generics/ColorPlaten";
import times from "@/mixins/times";
import sockets from '@/mixins/sockets';
import DayjsPicker from "../generics/DayjsPicker.vue";
import NumberPicker from "../generics/NumberPicker.vue";

export default {
  name: "user-escort-handler",
  components: {
    MyDialog,
    MyDatePicker,
    MyTimePicker,
    NumberPicker,
    ColorPlaten,
    DayjsPicker
  },
  props: {
    value: Boolean,
  },
  mixins: [times, sockets],
  data() {
    return {
      form: {
        isSingle: true,
        numberOfPassengers: 1,
        color: undefined,
        days: [],
        date: {
          start: this.extractDateString(),
          end: null
        },
        time: { start: "" },
        pathModel: "Path",
        path: "",
        isCircular: false,
        notes: ""
      },
      count: 7,
      err: null,
      valid: true,
      isComplexPath: false,
    };
  },
  watch: {
    isComplexPath(v) {
      this.form.pathModel = v ? "ComplexPath" : "Path";
    },
    computedTimeEnd(v) {
      this.form.time.end = v;
    },
  },
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    paths() {
      return this.isComplexPath
        ? this.$store.getters.complexPaths
        : this.$store.getters.paths;
    },
    typeString() {
      let str = "";
      str += this.$t('escorts.type') + ": ";
      str += this.$t(`escorts.types.${this.form.path?.type}`);
      return str;
    },
    computedTimeEnd() {
     if (this.form.time.start && this.form.date.start && this.form.path) {
        let d = this.setDateTime(this.form.date.start, this.form.time.start)
        const durationTime = (this.form.isCircular ? 2 : 1) * this.form.path?.time;
        let result = d.add(durationTime, "m")
        return this.extractTimeString(result);
      } else return ""
    },
    computedDefaultEscortName() {
      return `${this.form.time?.start || '00:00'} - ${this.form.path?.name || 'שם מסלול'}`;
    }
  },
  methods: {
    async create() {
      this.valid = false;
      let data = this.form.isSingle ? this.createSingleEvent() : _.cloneDeep(this.form);
      data.name = this.computedDefaultEscortName;
      // console.log("this is data", data)
      const { escort, request } = await this.$store.dispatch("escort/store/user", data);
      this.socket_ask_escort({ escort, request });
      this.close();
      this.valid = true;
    },
    createSingleEvent() {
      const data = _.pick(this.form, ["color", "date.start", "isSingle", "name", "numberOfPassengers", "path", "pathModel", "isCircular", "time", "notes"]);
      return data;
    },
    async deleteItem() {
      this.$emit("deleteItem", this.escort);
      this.openModal = false;
    },
    validateForm() {
      this.$refs.form.validate();
      return (
        this.form.name &&
        this.form.date.start &&
        this.form.passengers.length &&
        this.form.path
      );
    },
    close() {
      this.openModal = false;
    },
    onInputPath(val) {
      this.form.path = val;
      if(!val) return;
      this.form.color = this.isComplexPath ? val.paths[0].color : val.color;
    }
  },
  async created() {
    if (!this.paths.length) {
      await this.$store.dispatch("path/index");
      await this.$store.dispatch("complexPath/index");
    }
  },
};
</script>
