<template>
  <span>{{ text }}</span>
</template>

<script>
import axios from "axios";

export default {
  props: { notificationId: String, notificationType: String },
  data() {
    return {
      text: "",
    };
  },
  async created() {
    if (this.notificationType === "global") return;
    const { data } = await axios.get(
      `/notification/${this.notificationId}/get-addressee?type=${this.notificationType}`
    );
    if (!data) return;

    this.text =
      this.notificationType === "single-user"
        ? `${data.name} | ${data.phone} | ${data.email}`
        : data.name;
  },
};
</script>
