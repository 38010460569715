<template>
  <div :style="{
    width: size,
    height: size,
    borderRadius: size,
    background,
  }" />
</template>

<script>
export default {
  props: {
    colors: Array,
    color: String,
    size: {
      type: String,
      default: '30px',
    }
  },
  computed: {
    background() {
      if (this.color) return this.color;
      const colorsSet = [...new Set(this.colors)];
      if (colorsSet.length === 1) return colorsSet[0];

      return `conic-gradient(${colorsSet.map((c, i) => `${c} ${1 / colorsSet.length * (i)}turn ${1 / colorsSet.length * (i + 1)}turn`).join(',')})`;
    }
  }
};
</script>
