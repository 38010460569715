import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const monthByNumber = (num) => {
  return [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][num - 1];
};

export const days = (i18n) => {
  return [
    i18n.$t("days.sunday"),
    i18n.$t("days.monday"),
    i18n.$t("days.tuesday"),
    i18n.$t("days.wednesday"),
    i18n.$t("days.thursday"),
    i18n.$t("days.friday"),
    i18n.$t("days.saturday"),
  ];
};

export const createRecursiveEvent = (escort, exception = [], max, min) => {
  let myEvents = [];
  let start = escort.date.start;
  while (dayjs(start).isSameOrBefore(escort.date.end ?? dayjs(max))) {
    if (
      !dayjs(start).isSameOrAfter(min) ||
      checkSkips(escort.datesSkips, start)
    ) {
      start = dayjs(start).add(1, "d");
    } else {
      const e = exception.find((e) => {
        return dayjs(start).isSame(e.date);
      });
      if (e) {
        const s = addTimeToDate(start, e.time.start);
        const end = addTimeToDate(start, e.time.end);
        myEvents.push({
          _id: e._id,
          name: e.name,
          start: s,
          end,
          color: e.color,
          timed: true,
        });
      } else if (checkIfIsInDay(escort.days, start, escort)) {
        myEvents.push({
          _id: escort._id,
          name: escort.name,
          start: addTimeToDate(start, escort.time.start),
          end: addTimeToDate(start, escort.time.end),
          color: escort.color ? escort.color : "#808080",
          timed: true,
        });
      }
      start = dayjs(start).add(1, "d");
    }
  }
  return myEvents;
};
export const addTimeToDate = (date, time) => {
  return new Date(
    `${dayjs(date).add(1, "d").toISOString().slice(0, 10)} ${time}:00`
  );
};
const checkIfIsInDay = (days, day) => {
  for (let index = 0; index < days.length; index++) {
    if (dayjs(day).get("d") === days[index]) {
      return true;
    }
  }
  return false;
};
const checkSkips = (skips, day) => {
  for (let index = 0; index < skips.length; index++) {
    if (dateFormat(day) === dateFormat(skips[index])) {
      return true;
    }
  }
  return false;
};

//add date to escort
export const addEscortDateArray = (data) => {
  return data.map((e) => {
    return addEscortDateObject(e);
  });
};

export const addEscortDateObject = (data) => {
  return {
    ...data,
    date: {
      start: dateFormat(data.date.start),
      end: data.date.end ? dateFormat(data.date.end) : null,
    },
  };
};

export const addExceptionEscortsDateArray = (data) => {
  return data.map((e) => {
    return addExceptionEscortsDateObject(e);
  });
};
export const addExceptionEscortsDateObject = (data) => {
  return {
    ...data,
    date: dateFormat(data.date),
  };
};

export const dateFormat = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

export const mapId = (array, key) => {
  return array.map((a) => (key ? { [key]: a._id } : a._id));
};
