import myAxios from "../../services/myAxios";
export default {
  state: {
    requests: [], //chosen requests
    request: null,
    filtered: [],
  },
  getters: {
    requests: (state) => state.requests,
    request: (state) => state.request,
    requestsFiltered: (state) => state.filtered,
    requestTypeCreateEscort: (state) =>
      state.requests.length
        ? state.requests.filter((r) => r.type === "create-escort")
        : [],
    requestTypeJoinEscort: (state) =>
      state.requests.length
        ? state.requests.filter((r) => r.type === "join-escort")
        : [],
  },
  mutations: {
    //sets all request
    "requests/set": (state, payload) => {
      state.requests = payload;
      state.filtered = [...state.requests];
    },
    //sets one requests
    "request/set": (state, payload) => (state.request = payload),
    //filters the requests's array by requests's key and requests's val
    "requests/filter": (state, { key, val }) => {
      state.filtered = !val
        ? [...state.request]
        : state.requests.filter((f) => f[key] === val);
    },
    //store one requests
    "request/store": (state, payload) => state.requests.push(payload),
    //destroys one requests
    "request/destroy": (state, id) =>
      (state.requests = state.requests.filter((item) => {
        return item._id !== id;
      })),
    //updates one requests
    "requests/update": (state, payload) => {
      state.request = state.request.map((item) => {
        if (item._id === payload._id) {
          return payload;
        }
        return item;
      });
    },
  },
  actions: {
    //fetch all request
    "requests/index": async (context) => {
      const { data } = await myAxios.get("/request");
      context.commit("requests/set", data);
    },
    //fetch one requests by id
    "requests/show": async (context, id) => {
      let { data } = await myAxios.get("/request/" + id);
      context.commit("request/set", data);
    },
    //stores one requests
    "requests/store": async (context, payload) => {
      let { data } = await myAxios.post("/request", { ...payload });
      context.commit("request/store", data);
    },
    //destroys one requests
    "requests/destroy": async (context, { id, toastOptions }) => {
      await myAxios.delete("/request/" + id, toastOptions);
      context.commit("request/destroy", id);
    },
    //updates one requests by its id
    "requests/update": async (context, payload) => {
      await myAxios.put("/request/" + payload._id, payload);
      context.commit("request/update", payload);
    },
    //handling the authorization of the request
    "requests/auth": async (context, { id, authorize, numberOfPassengers }) => {
      await myAxios.post(`/request/auth/${id}`, {
        authorize,
        numberOfPassengers,
      });
      context.commit("request/destroy", id);
    },
  },
};
