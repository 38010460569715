<template>
  <mobile-view>
    <h4 class="text-center" v-text="$t('dashboard.myEscort')" />
    <v-toolbar flat class="d-flex justify-space-around">
      <v-btn text :small="$vuetify.breakpoint.xsOnly" color="primary" @click="setToday">{{ $t("date.today") }}</v-btn>
      <v-btn fab :x-small="$vuetify.breakpoint.xsOnly" text color="grey darken-2" @click="prev">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <span v-if="$refs.calendar" class="mx-2 text-sm-h6 subtitle-1" v-text="monthString()" />
      <v-btn fab :x-small="$vuetify.breakpoint.xsOnly" text color="grey darken-2" @click="next">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn fab :small="$vuetify.breakpoint.xsOnly" text color="primary" @click="loadData">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn fab :small="$vuetify.breakpoint.xsOnly" text color="green" @click="toggleCalendarType">
        <v-icon>mdi-calendar-edit</v-icon>
      </v-btn>
      <color-filter v-model="colorsFilter" />
    </v-toolbar>
    <v-sheet height="82vh" :dir="$i18n.locale === 'he' ? 'rtl' : 'ltr'">
      <v-calendar ref="calendar" v-model="today" color="primary" :events="filteredEvents" :type="calendarType"
        event-overlap-mode="column" :interval-height="70" event-overlap-threshold="10"
        :event-color="({ isDone, color }) => isDone ? '#ffffff' : color"
        :event-text-color="({ isDone }) => isDone ? 'success' : '#ffffff'" @click:more="showEvent" @click:date="showEvent"
        @click:day="showEvent" @change="updateRange" @click:event="showEvent" />
    </v-sheet>
    <user-escort-display v-if="showDialog" v-model="showDialog" :escort="chosen" :event="selectedEvent"
      :today="today" />
    <user-escort-handler v-if="createEscortDialog" v-model="createEscortDialog" />
    <float-btn @cb="createEscortDialog = true" />
  </mobile-view>
</template>
<script>
import EventMixins from "@/mixins/events";
import Generics from "@/mixins/generic";
import times from "@/mixins/times";
import FloatBtn from "@/components/generics/FloatBtn";
import MobileView from "@/layouts/MobileView";
import UserEscortHandler from "@/components/handlers/UserEscortHandler";
import ColorFilter from '@/components/generics/ColorFilter.vue';
import UserEscortDisplay from '../../components/UserEscortDisplay.vue';
export default {
  name: "AllEscorts",
  components: { FloatBtn, MobileView, UserEscortHandler, ColorFilter, UserEscortDisplay },
  name: "calendar",
  mixins: [Generics, EventMixins, times],
  data: () => ({
    loading: false,
    addDialog: false,
    showDialog: false,
    selectedEvent: {},
    chosen: {},
    createEscortDialog: false,
    calendarType: "day",
    chosenEscort: {},
    chosenEvent: {},
  }),
  computed: {
    escorts() {
      return this.$store.getters.userEscorts;
    },
  },
  watch: {
    escorts() {
      this.updateRange();
    },
  },
  methods: {
    createEvent() {
      this.chosen = null;
      this.addDialog = true;
    },
    showEvent({ event }) {
      if (!event) return;
      this.$store.commit("escort/set", event.escort);
      this.chosen = event.escort;
      this.selectedEvent = event;
      this.showDialog = true;
    },
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("escort/userEscorts/index");
      await this.$store.dispatch("requests/index");
      this.loading = false;
    },
    toggleCalendarType() {
      if (this.calendarType === "day") this.calendarType = "4day";
      else this.calendarType = "day";
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>
