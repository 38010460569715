<template>
  <div>
    <my-dialog :title="$t('escorts.edit')" v-model="openModal" :persistent="true">
      <template v-slot:content>
        <v-form ref="form" v-model="valid" lazy-validation v-if="!loading">
          <v-select
            v-model="form.type"
            :label="$t('escorts.type')"
            :items="types"
            :rules="[(v) => !!v || $t('errors.notEmpty')]"
            required
          />
          <v-row>
            <v-col cols="12" sm="6">
              <v-autocomplete
                @input="onInputPath"
                :value="form.path"
                :items="paths"
                :label="$t('paths.path')"
                item-text="name"
                return-object
                clearable
                :rules="[(v) => !!v || $t('errors.notEmpty')]"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <v-switch
                v-model="isComplexPath"
                :label="
                  $t(isComplexPath ? 'generics.complex' : 'generics.regular')
                "
                @click="() => (form.path = null)"
                hide-details
              />
            </v-col>
						<v-col cols="6" sm="3">
              <v-switch
                v-model="form.isCircular"
                :label="$t('paths.isCircular')"
                class="mt-0"
                hide-details
              />
            </v-col>
          </v-row>
          <div class="d-flex">
            <my-time-picker
              v-model="form.time.start"
              :end="form.time.end"
              :label="$t('date.timeStart')"
            />
            <my-time-picker
              v-model="form.time.end"
              :start="form.time.start"
              :label="$t('date.timeEnd')"
            />
          </div>
          <v-autocomplete
            v-model="form.escorters"
            :items="escorters"
            :label="$t('generics.escorters')"
            item-text="name"
            item-value="_id"
            clearable
            multiple
            :rules="[(v) => !!v.length || $t('errors.notEmpty')]"
          />
          <color-platen v-model="form.color" />
          <passengers-table
            v-model="form.passengers"
            :maxPassengers="form.maxPassengers"
            @maxPassengersHandler="maxPassengersHandler"
          />
          <v-textarea v-model="form.notes" :label="$t('generics.notes')" rows="1" />

          <div class="d-flex" v-if="!noChanges">
            <v-checkbox v-model="sendMsg" :label="$t('escorts.sendMsg')" />
            <v-checkbox
              v-if="sendMsg"
              class="mx-4"
              v-model="notification.isApproval"
              hint="nice man"
              color="error"
              :label="$t('escorts.approvalMsg')"
            />
            <v-checkbox
              v-if="sendMsg"
              v-model="notification.isUrgent"
              :label="$t('generics.urgent')"
              color="error"
            />
          </div>
          <span
            v-if="sendMsg && notification.isApproval"
            v-text="$t('errors.notifications.isApproval')"
          />
          <div v-if="sendMsg">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="notification.title"
                  v-if="sendMsg"
                  :label="$t('generics.title')"
                  autofocus
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="notification.message"
                  v-if="sendMsg"
                  :label="$t('generics.message')"
                />
              </v-col>
            </v-row>
          </div>
        </v-form>
        <v-row v-if="err">
          <v-col class="error--text text-center">{{ err }}</v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn
          text
          color="primary"
          v-text="$t('generics.change')"
          @click="submit"
          :disabled="noChanges"
        />
      </template>
    </my-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import MyDialog from "@/components/generics/MyDialog";
import MyTimePicker from "@/components/generics/MyTimePicker";
import ColorPlaten from "@/components/generics/ColorPlaten";
import times from "@/mixins/times";
import PassengersTable from "@/components/PassengersTable";

export default {
  name: "exception-escort-handler",
  components: {
    MyDialog,
    MyTimePicker,
    ColorPlaten,
    PassengersTable
  },
  props: {
    value: Boolean,
    escort: Object,
    date: String,
    isNew: Boolean,
  },
  mixins: [times],
  data() {
    return {
      numberOfPassengers: 1,
      sendMsg: false,
      approvalMsg: false,
      loading: false,
      notification: {},
      form: {},
      err: null,
      types: [
        { text: this.$t("escorts.types.foot"), value: "foot" },
        { text: this.$t("escorts.types.vehicle"), value: "vehicle" },
        { text: this.$t("escorts.types.vehicle-foot"), value: "vehicle-foot" },
      ],
      valid: true,
      isComplexPath: false,
      notifyDialog: false,
    };
  },
  watch: {
    isComplexPath(v) {
      this.pathModel = v ? "ComplexPath" : "Path";
    },
  },
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    escorters() {
      return this.$store.getters.escorters;
    },
    passengers() {
      return this.$store.getters.passengers;
    },
    paths() {
      return this.isComplexPath
        ? this.$store.getters.complexPaths
        : this.$store.getters.paths;
    },
    //checks if any changes made in escort
    noChanges() {
      const samePassengers = this.samePassengersCheck(
        this.form.passengers,
        this.escort.passengers
      );
      const timeChange = _.isEqual(this.form.time, this.escort.time);
      const pathChange = this.form.path._id === this.escort.path._id;
      const maxPassChange = _.isEqual(
        this.form?.maxPassengers?.toString(),
        this.escort?.maxPassengers?.toString()
      );
      const colorChange = _.isEqual(this.form.color, this.escort.color);
      const eTypeChange = _.isEqual(this.form.type, this.escort.type);
      const sameNotes = this.form.notes === this.escort.notes;

      return (
        samePassengers &&
        timeChange &&
        pathChange &&
        maxPassChange &&
        colorChange &&
        eTypeChange &&
        sameNotes
      );
    },
    computedDefaultEscortName() {
      return `${this.form.time?.start || '00:00'} - ${this.form.path?.name || 'שם מסלול'}`
    }
  },
  methods: {
    async submit() {
      if (this.noChanges) return;
      this.isNew ? await this.create() : await this.update();
      this.close();
    },
    async create() {
      await this.$store.dispatch("escort/skips", {id: this.escort?._id, date: this.form?.date?.start})

      const data = this.buildChildFromEscort();
      data.name = this.computedDefaultEscortName;

      let escortId = await this.$store.dispatch(
        "escort/store",
        data
      );
      await this.notify(escortId);
    },
    async update() {
      const data = {...this.form, name: this.computedDefaultEscortName};
      await this.$store.dispatch("escort/update", data);
      this.close();
    },
    async notify(escortId) {
      if (this.sendMsg) {
        let data = { ...this.notification };
        data["type"] = "escort";
        data["addresseeId"] = escortId;
        await this.$store.dispatch("notification/store", data);
      }
      this.close();
    },
    //This method takes the form, and build child escort from it
    buildChildFromEscort() {
      let child = _.cloneDeep(this.form);
      child["parent"] = child._id;
      delete child._id;
      child["isChild"] = true;
      child.isSingle = true;
      if (this.isNew && this.notification.isApproval) {
        child["pending"] = true;
      }
      return child;
    },
    maxPassengersHandler(v) {
      this.form.maxPassengers = v
    },
    close() {
      this.$emit("close");
    },
    samePassengersCheck(passengers1, passengers2) {
      const ids1 = mapIds(passengers1);
      const ids2 = mapIds(passengers2);
      return _.isEqual(ids1.sort(), ids2.sort())

      function mapIds(p) {
        return p.map(item => typeof item === 'string' ? item : item.passenger._id)
      }
    },
    onInputPath(val) {
      this.form.path = val;
      if(!val) return;
      this.form.color = this.isComplexPath ? val.paths[0].color : val.color;
    }
  },
  async created() {
    this.loading = true;
    this.form = _.cloneDeep(this.escort);
    this.isComplexPath = this.form.pathModel === "ComplexPath";
    if (this.isNew) {
      this.form.date.start = this.date;
      delete this.form.date.end;
      delete this.form.days;
    }
    if (!this.paths.length) {
      await this.$store.dispatch("path/index");
      await this.$store.dispatch("complexPath/index");
    }
    if (!this.passengers.length || !this.escorters.length)
      await this.$store.dispatch("users/index");
    this.loading = false;
  },
};
</script>
