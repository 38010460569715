<template>
  <v-card elevation="2" :style="{
    position: 'fixed',
    top: 0,
    left: 0,
    margin: '8px',
    zIndex: 10000,
    border: '3px solid',
    borderColor: computedColor,
    minWidth: '245px'
  }">
    <h4 class="ma-2 mb-0">{{ computedTitle }}</h4>
    <v-card-text>
      <v-list>
        <v-list-item v-for="(item, i) in items" :key="i">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on">
                <v-icon v-text="item.icon" />
              </v-list-item-icon>
            </template>
            {{ item.label }}
          </v-tooltip>
          <v-list-item-content>
            <v-list-item-title v-text="item.text" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-btn text color="primary" @click="auth(true)" :disabled="loading">
        {{ $t('generics.approve') }}
      </v-btn>
      <v-btn text color="error" @click="auth(false)" :disabled="loading">
        {{ $t('generics.decline') }}
      </v-btn>
      <div class="flex-grow-1"></div>
      <v-btn text color="grey" @click="close" :disabled="loading">
        {{ $t('generics.cancel') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import times from "@/mixins/times";
import { stopAnnouncementSoundInterval } from '../helpers/audio';

export default {
  mixins: [times],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    items() {
      const request = this.$store.getters.request;
      const escort = this.$store.getters.escort;
      const list = [
        {
          label: this.$t("generics.date"),
          text: this.dateString,
          icon: 'mdi-calendar-outline'
        },
        {
          label: this.$t("generics.time"),
          text: this.timeRange,
          icon: 'mdi-clock-outline'
        },
        {
          label: this.$t("escorts.numberOfPassengers"),
          text: escort?.numberOfPassengers,
          icon: 'mdi-account-group-outline'
        },
      ];
      if (request?.notes) list.push(
        {
          label: this.$t("generics.notes"),
          text: request?.notes,
          icon: 'mdi-information-outline'
        });
      return list;
    },
    dateString() {
      return this.extractDateString(
        this.$store.getters.escort?.date?.start
      );
    },
    timeRange() {
      return `${this.$store.getters.escort?.time?.start} - ${this.$store.getters.escort?.time?.end}`;
    },
    computedTitle() {
      return this.$t("notifications.newEscort", { escortName: this.$store.getters.escort?.name });
    },
    computedColor() {
      return this.$store.getters.escort?.color || 'black';
    }
  },
  methods: {
    async auth(auth) {
      const escort = this.$store.getters.escort;
      const request = this.$store.getters.request;
      this.loading = true;
      try {
        await this.$store.dispatch("escort/approveUserEscort", {
          isSingle: escort?.isSingle,
          id: escort?._id,
          authorize: auth,
          requestId: request._id,
          days: this.getDays(escort),
          toastOptions: {
            message:
              auth ? this.$t("toasts.approveJoin", {
                user: request?.sender?.name,
                escort: escort?.name,
              }) :
                this.$t("toasts.declineJoin", { user: request.sender.name })
          },
        });
        // this.$store.dispatch("escort/get-pending-escorts",
        //   { toastOptions: { disabled: true } }
        // );
        this.close();
        if (!this.$store.getters.requests?.length)
          stopAnnouncementSoundInterval();
      } finally {
        this.loading = false;
      }
    },
    close() {
      document.querySelector('#new-escort-notification-card').classList.add('d-none');
    },
    getDays(escort) {
      if (escort.days) {
        return escort.days;
      }
      else {
        return [this.goToday(item.request.date)];
      }
    }
  }
};
</script>

<style>
</style>
