<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title class="d-flex align-center">
          <v-row>
            <v-col cols="11">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                icon
                :label="$t('generics.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-icon
                color="primary"
                @click="addDialog = true"
                v-text="'mdi-plus'"
              />
              <v-icon
                color="primary"
                @click="loadData"
                v-text="'mdi-refresh'"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="escorts"
          :loading="loading"
          :items-per-page="50"
          :options.sync="options"
          :server-items-length="itemsCount"
        >
          <template v-slot:item.color="{ item }">
            <colors-pie-chart :color="item.color" />
          </template>
          <template v-slot:item.numberOfPassengers="{ item }">{{
              numberOfPassengers(item)
            }}
          </template>
          <template v-slot:item.days="{ item }">{{ dayKeys(item) }}</template>
          <template v-slot:item.date.start="{ item }">{{
              extractDateString(item.date.start)
            }}
          </template>
          <template v-slot:item.date.end="{ item }">{{
              extractDateString(item.date.end)
            }}
          </template>
          <template v-slot:item.actions="{ item }">
            <template v-if="checkPermissionToMutate(item)">
              <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
              <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <escort-handler
      v-model="addDialog"
      v-if="addDialog"
      @save="save"
      :isNew="true"
    />
    <escort-handler
      v-model="editDialog"
      v-if="editDialog"
      :escort="chosen"
      @save="update"
    />
  </div>
</template>

<script>
import EscortHandler from "@/components/handlers/EscortHandler";
import times from "@/mixins/times";
import ColorsPieChart from '../../components/generics/ColorsPieChart.vue';
import myAxios from "@/services/myAxios";

export default {
  name: "Escorts",
  components: {EscortHandler, ColorsPieChart},
  mixins: [times],
  data() {
    return {
      loading: false,
      headers: [
        {text: this.$t("generics.color"), value: "color"},
        {text: this.$t("generics.name"), value: "name"},
        {
          text: this.$t("escorts.passengersCount"),
          value: "numberOfPassengers",
        },
        {text: this.$t("escorts.cycle"), value: "days"},
        {text: this.$t("date.dateStart"), value: "date.start"},
        {text: this.$t("date.dateEnd"), value: "date.end"},
        {text: this.$t("date.timeStart"), value: "time.start"},
        {text: this.$t("date.timeEnd"), value: "time.end"},
        {text: this.$t("generics.actions"), value: "actions", width: "10%"},
      ],
      escorts: [],
      chosen: null,
      search: "",
      searchQueryLastTyping: null, // in ms
      searchQueryTimeoutId: null,
      addDialog: false,
      editDialog: false,
      options: {},
      itemsCount: null,
    };
  },
  methods: {
    numberOfPassengers(escort) {
      let num = 0;
      escort.passengers.forEach((p) => (num += p.numberOfPassengers));
      return num;
    },
    dayKeys(escort) {
      let str = "";
      escort.days.forEach((d) => (str += this.$t(`days[${d}].short`) + " "));
      return str;
    },
    async loadData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const count = await this.$store.dispatch("escort/index", {page, itemsPerPage, returnTotalCount: 'true'});
      this.escorts = this.$store.getters.escorts;
      this.itemsCount = count;
      this.loading = false;
    },
    editItem(item) {
      this.chosen = item;
      this.editDialog = true;
    },
    async deleteItem(item) {
      let {isConfirmed} = await this.$createSwal({
        icon: "warning",
        title: `${this.$t("generics.delete")} ${item.name}?`,
      });
      if (isConfirmed) {
        await this.$store.dispatch("escort/destroy", item);
      }
    },
    async update(escort) {
      await this.$store.dispatch("escort/update", escort);
    },
    async save(escort) {
      await this.$store.dispatch("escort/store", escort);
    },
    checkPermissionToMutate(escort) {
      return escort.isChild || this.$store.getters.role <= 1;
    },
  },
  async mounted() {
    if (!this.escorts.length) {
      await this.loadData();
    }
  },
  watch: {
    editDialog() {
      if (!this.editDialog) {
        this.chosen = null;
      }
    },
    options: {
      handler () {
        this.loadData()
      },
      deep: true,
    },
    search(str) {
      const DELAY_TIME = 400;

      if (str.trim().length < 2) {
        this.escorts = this.$store.getters.escorts;
        return
      };

      const dateNow = Date.now();
      this.searchQueryLastTyping = dateNow;
      const timeFromTheLastTyping = dateNow - this.searchQueryLastTyping;

      if (this.searchQueryTimeoutId)
        clearTimeout(this.searchQueryTimeoutId);

      this.searchQueryTimeoutId = setTimeout(() => {
        this.loading = true;
        this.searchQueryLastTyping = dateNow + timeFromTheLastTyping;
        myAxios
          .get("/escort", { params: { searchQuery: str } })
          .then(({ data }) => { this.escorts = data.escorts })
          .finally(() =>
            this.loading = false
          );
      }, DELAY_TIME);
    }
  },
};
</script>
