import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import he from "vuetify/lib/locale/he";

Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  lang: {
    locales: { he },
    current: "he",
  },
  theme: {
    // dark: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#1F4C83",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        lightGrey: "#b8b7b7",
      },
    },
  },
});
