<template>
	<v-dialog
		v-model="openModal"
		max-width="290"
	>
		<v-card>
			<v-card-title class="text-h5 d-flex justify-center">
				{{
            $t("escorts.chooseEscortType")
        }}
			</v-card-title>
			<v-card-actions class="d-flex justify-center">
				<v-btn
					color="primary"
					text
					@click="create(true)"
					v-if="$store.getters.role <= 1"
				>{{ $t("generics.permanent") }}</v-btn>
				<v-btn
					color="primary"
					text
					@click="create(false)"
				>
					{{$t("generics.oneTime")}}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
	name: "escort-type-dialog",
	props: { value: Boolean },
	computed: {
		openModal: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
	},
	methods: {
		create(isRecurring) {
			isRecurring ? this.$emit("createOne") : this.$emit("createRecurring")
			this.openModal = false
		}
	},
}
</script>
