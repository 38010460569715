var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{style:({
  position: 'fixed',
  top: 0,
  left: 0,
  margin: '8px',
  zIndex: 10000,
  border: '3px solid',
  borderColor: _vm.computedColor,
  minWidth: '245px'
}),attrs:{"elevation":"2"}},[_c('h4',{staticClass:"ma-2 mb-0"},[_vm._v(_vm._s(_vm.computedTitle))]),_c('v-card-text',[_c('v-list',_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-icon',_vm._g(_vm._b({},'v-list-item-icon',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1)]}}],null,true)},[_vm._v(" "+_vm._s(item.label)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.auth(true)}}},[_vm._v(" "+_vm._s(_vm.$t('generics.approve'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"error","disabled":_vm.loading},on:{"click":function($event){return _vm.auth(false)}}},[_vm._v(" "+_vm._s(_vm.$t('generics.decline'))+" ")]),_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"text":"","color":"grey","disabled":_vm.loading},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('generics.cancel'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }