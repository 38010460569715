<template>
  <div>
    <my-dialog :title="title" v-model="openModal">
      <template v-slot:content>
        <v-row>
          <v-col>
            <v-text-field :label="$t('generics.name')" v-model="name" autofocus />
            <v-autocomplete
              v-model="select"
              @change="onSelect"
              :items="paths"
              :label="$t('paths.paths')"
              item-text="name"
              return-object
              clearable
            />

            <div>
              <ins v-if="selectPaths.length">{{ $t("paths.paths") }}:</ins>
              <my-draggable
                @deleteSelect="deleteSelect"
                :items="selectPaths"
                v-if="selectPaths.length"
              />
            </div>
            <v-row class="d-flex justify-start">
              <v-col cols="3" class="d-flex center-all">
                <div class="ml-2">{{ $t("complexPaths.time") }}:</div>
                <!-- <v-text-field v-model="time" type="number" /> -->
                <number-picker :min="5" v-model="time" />
                <div class="mr-2">{{ $t("date.minutes") }}.</div>
              </v-col>
              <v-col cols="7">
                <v-select
                  v-model="type"
                  :label="$t('escorts.type')"
                  :items="types"
                  :rules="[(v) => !!v || $t('errors.notEmpty')]"
                  required
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="err">
          <v-col class="error--text text-center">{{ err }}</v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn
          text
          color="primary"
          v-text="isNew ? $t('generics.add') : $t('generics.save')"
          @click="submit"
        />
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from "@/components/generics/MyDialog";
import MyDraggable from "@/components/generics/MyDraggable";
import NumberPicker from "@/components/generics/NumberPicker"
export default {
  name: "complex-path-handler",
  components: { MyDialog, MyDraggable, NumberPicker },
  props: {
    value: Boolean,
    isNew: {
      type: Boolean,
      default: false,
    },
    complexPath: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      name: "",
      select: "",
      time: 10,
      err: null,
      selectPaths: [],
      searchInput: "",
      type: "",
      types: [
        { text: this.$t("escorts.types.foot"), value: "foot" },
        { text: this.$t("escorts.types.vehicle"), value: "vehicle" },
        { text: this.$t("escorts.types.vehicle-foot"), value: "vehicle-foot" },
      ],
    }
  },
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    paths() {
      return this.$store.getters.paths;
    },
    title() {
      return this.$t("complexPaths.handlerTitle", {
        prefix: this.$t(this.isNew ? "generics.add" : "generics.edit"),
        suffix: this.$t(this.isNew ? "generics.new" : ""),
      });
    },
  },
  methods: {
    onSelect(item) {
      if (item) {
        this.selectPaths = [...this.selectPaths, item];
      }
    },
    deleteSelect(item, i) {
      this.selectPaths = this.selectPaths.filter(
        (select, index) => index !== i
      );
    },
    async submit() {
      if (!this.validateForm()) {
        return this.createErr(this.$t("errors.validate.complexPaths"));
      }
      this.$emit("save", {
        ...this.complexPath,
        name: this.name,
        paths: this.selectPaths,
        time: +this.time,
      });
      this.openModal = false;
    },
    validateForm() {
      return this.name.length && this.selectPaths.length > 1;
    },
    createErr(msg) {
      this.err = msg;
      setTimeout(() => {
        this.err = null;
      }, 2000);
      return;
    },
  },
  mounted() {
    if (!this.isNew) {
      this.name = this.complexPath.name;
      this.selectPaths = [...this.complexPath.paths];
      this.time = this.complexPath.time;
      this.type = this.complexPath.type;
    }
  },
};
</script>
