import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import Times from "../helpers/times.js";

dayjs.extend(isBetween);

const eventsMixins = {
  data() {
    return {
      type: "category",
      colorsFilter: null,
      escortTypeFilter: null,
      selectedEvent: {},
      loading: false,
      chosen: {},
      today: dayjs().format("YYYY-MM-DD"),
      events: [],

      prevLastStartDate: null,
      prevLastEndDate: null,
    };
  },
  computed: {
    filteredEvents() {
      return this.events.filter((i) => {
        const matchColor =
          !this.colorsFilter?.length || this.colorsFilter.includes(i.color);
        const matchEscortType =
          !this.escortTypeFilter ||
          (i.escort.isSingle && this.escortTypeFilter === "oneTime") ||
          (!i.escort.isSingle && this.escortTypeFilter === "permanent");
        return matchColor && matchEscortType;
      });
    },
  },
  methods: {
    //This big method take all escorts, and child escorts
    //And create multiple calendar event's instances from them
    //Its a complex method, so read the comments carefully to fully understand
    updateRange(data) {
      if (
        data &&
        (this.prevLastStartDate !== data.start.date ||
          this.prevLastEndDate !== data.end.date)
      )
        this.fetchEscortsInRange();

      const calStart = dayjs(`${this.$refs.calendar.lastStart.date}T00:00:00`);
      const calEnd = `${this.$refs.calendar.lastEnd.date}T23:59:59`;
      this.events = [];
      for (let i in this.escorts) {
        let escort = this.escorts[i];
        //checking if escort is pending (created by user), if so dont show
        if (escort.pending) {
          // let isOwner = escort.creator === this.$store.getters.uid;
          continue;
        }
        //checking if event is isSingle
        // if (escort.isChild) continue;
        let startPoint, endPoint;
        let eStart = dayjs(escort.date.start),
          eEnd = escort.date.end ? dayjs(escort.date.end) : null;
        //if its single escort - dont create recursive
        if (escort.isSingle) {
          if (!Times.isSameOrBetweenDays(eStart, calStart, calEnd)) continue;

          eEnd = dayjs(escort.date.start);
          let start = Times.createCalendarTimeString(eStart, escort.time.start);
          let end = Times.createCalendarTimeString(eStart, escort.time.end);
          this.events.push({
            _id: escort._id,
            name: escort.name,
            start,
            end,
            color: escort.color,
            escort: escort,
            isDone: Times.dateIsInArray(start, escort.doneEscorts),
          });
          continue;
        }
        //checking if current escort is in calendar day's range
        //if in range, create all recurring events for escort
        if (Times.overLaps(eStart, eEnd, calStart, calEnd)) {
          //creating recurring instances of escort for calendar time range
          startPoint = eStart.isSameOrBefore(calStart) ? calStart : eStart; //the first instance
          endPoint = eEnd && eEnd.isSameOrBefore(calEnd) ? eEnd : calEnd; //the last instance
          while (startPoint.isSameOrBefore(endPoint)) {
            //Checking for escort skips (will not create this instance if skip exists)
            const skip = escort.skips.find((s) => {
              return (
                dayjs(s).format("YYYY-MM-DD") ===
                startPoint.format("YYYY-MM-DD")
              );
            });
            if (skip) {
              startPoint = startPoint.add(1, "d");
              continue;
            }
            if (escort.days.find((d) => d === startPoint.day()) !== undefined) {
              let event;
              //Searching for event's children
              let child = this.escorts.find(
                (c) =>
                  c.isChild &&
                  c.parent === escort?._id &&
                  Times.isSameDay(c.date?.start, startPoint)
              );
              //Checking for pending children
              //If found - nest the pending child in event instance
              if (child && child.pending) {
                event = { ...escort };
                event["pendingChild"] = child;
              }
              //Checking for no pending children
              //If found, replace event instance with child
              else if (child && !child.pending) event = child;
              //If no children found - continue with event instance
              else event = escort;
              let start = Times.createCalendarTimeString(
                startPoint,
                event.time.start
              ); //instance's start date
              let end = Times.createCalendarTimeString(
                startPoint,
                event.time.end
              ); //instance's end date
              this.events.push({
                _id: event._id,
                name: event.pendingChild
                  ? `${event.name} - ${this.$t("escorts.pendingMsg")}`
                  : event.name,
                start,
                end,
                color: event.color,
                isParent: true,
                escort: event,
                isDone: Times.dateIsInArray(start, escort.doneEscorts),
              });
            }
            startPoint = startPoint.add(1, "d");
          }
        }
      }
      // this.events;
    },
    setToday() {
      this.today = dayjs().format("YYYY-MM-DD");
    },
    monthString() {
      let m = this.$refs.calendar.parsedValue.month;
      return this.$t(`date.monthsNames[${m - 1}]`);
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    async fetchEscortsInRange() {
      this.loading = true;
      await this.$store.dispatch("escort/get-by-date-range", {
        startDate: this.$refs.calendar.lastStart.date,
        endDate: this.$refs.calendar.lastEnd.date,
        toastOptions: { disabled: true },
      });
      this.loading = false;
    },
  },
  mounted() {
    this.updateRange();
  },
};

export default eventsMixins;
