import dayjs from "dayjs";
import { startAnnouncementSoundInterval } from "../helpers/audio";
const sockets = {
  sockets: {
    //this lisener listens for alert notifications
    async alert({ date, name, phone }) {
      await this.$store.dispatch("alert/index");
      this.$adminAlert(
        this.$t("notifications.alertPopup"),
        `${date} ${name} ${phone}`,
        this.$t("notifications.showAlert"),
        () => {
          this.navigateToAdminNotificationsTab("alerts");
        }
      );
    },
    //this lisener listens for joinToEscort notifications
    async joinToEscort({ userName, escort }) {
      await this.$store.dispatch("requests/index");
      this.$infoToast({
        title: "",
        message: this.$t("notifications.joinToEscort", {
          userName,
          escortName: escort.name,
        }),
      });
    },
    //this lisener listens for leaveEscort notifications
    async leaveEscort({ userName, escort, isPermanent }) {
      const messageType = isPermanent
        ? "leaveEscortForever"
        : "leaveEscortOneTime";
      this.$infoToast({
        title: "",
        message: this.$t(`notifications.${messageType}`, {
          userName,
          escortName: escort.name,
        }),
      });
    },
    //this lisener listens for askEscort notifications
    async askEscort({
      userName,
      escortRelativeTime,
      data: { escort, request },
    }) {
      startAnnouncementSoundInterval();
      const _this = this;
      const cb = function () {
        _this.$store.commit("escort/set", escort);
        _this.$store.commit("request/set", request);
        document
          .querySelector("#new-escort-notification-card")
          .classList.remove("d-none");
      };
      this.$infoToast({
        title: "",
        message: this.$t("notifications.askEscort", {
          userName,
          escortRelativeTime,
        }),
        btnMsg: this.$t("generics.show"),
        cb,
      });
    },
  },
  methods: {
    //this event handles the connection to socket
    socket_join() {
      const c = this.$services.getCookie("user");
      if (c) this.$socket.client.emit("auth/join", { cookie: c });
    },
    //this event popup alert message to admin
    socket_admin_alert() {
      this.$socket.client.emit("admin/alert", {
        date: dayjs().format("YY-MM-DD hh:mm"),
        name: this.$store.getters.name,
        phone: this.$store.getters.userPhone,
      });
    },
    // this event pop up info message for admins
    socket_join_escort(escort) {
      this.$socket.client.emit("admin/joinToEscort", {
        userName: this.$store.getters.name,
        escort,
      });
    },
    // this event pop up info message for admins
    socket_leave_escort(data) {
      this.$socket.client.emit("admin/leaveEscort", {
        userName: this.$store.getters.name,
        escort: data.escort,
        isPermanent: data.isPermanent,
      });
    },
    // this event pop up info message for admins
    socket_ask_escort({ escort, request }) {
      const date = this.setDateTime(escort.date.start, escort.time.start);
      this.$socket.client.emit("admin/askEscort", {
        userName: this.$store.getters.name,
        escortRelativeTime: this.clacRelativeTimeString(date),
        data: { escort, request },
      });
    },
    navigateToAdminNotificationsTab(tab) {
      if (this.$route.name === "AdminNotifications") {
        const element = document.querySelector(
          `#admin-notifications-tab--${tab}`
        );
        element.click();
      } else {
        this.$router.push({
          name: "AdminNotifications",
          params: { tab },
        });
      }
    },
  },
  mounted() {
    this.socket_join();
  },
};

export default sockets;
