var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',{staticClass:"d-flex align-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","icon":"","label":_vm.$t('generics.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('mdi-plus')},on:{"click":function($event){_vm.addDialog = true}}}),_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('mdi-refresh')},on:{"click":_vm.loadData}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"items-per-page":20,"sort-by":"createdAt"},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getCellTextClassName(item)},[_vm._v(" "+_vm._s(new Date(item.createdAt).toLocaleString(_vm.$i18n.locale))+" ")])]}},{key:"item.addressee",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getCellTextClassName(item)},[_c('addressee-text',{key:'addressee-for-' + item._id,attrs:{"notificationId":item._id,"notificationType":item.type}})],1)]}},{key:"item.expiresAt",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getCellTextClassName(item)},[_vm._v(" "+_vm._s(new Date(item.expiresAt).toLocaleDateString(_vm.$i18n.locale))+" ")])]}},{key:"item.isUrgent",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getCellTextClassName(item)},[_vm._v(" "+_vm._s(item.isUrgent ? _vm.$t("generics.urgent") : _vm.$t("generics.regular"))+" ")])]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getCellTextClassName(item)},[_vm._v(_vm._s(item.message))])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getCellTextClassName(item)},[_vm._v(_vm._s(item.title))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}])}),(_vm.addDialog)?_c('notification-handler',{attrs:{"isNew":true},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}}):_vm._e(),(_vm.editDialog)?_c('notification-handler',{attrs:{"item":_vm.chosen},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }