<template>
  <div>
    <v-container>
      <v-card>
        <v-tabs v-model="tab" centered>
          <v-tab
            href="#alerts"
            v-text="$t('generics.alerts')"
            id="admin-notifications-tab--alerts"
          />
          <v-tab
            href="#global"
            v-text="$t('generics.globalNotifications')"
            id="admin-notifications-tab--global"
          />
          <v-tab
            href="#new-escort-requests"
            v-text="$t('generics.newEscortRequests')"
            id="admin-notifications-tab--requests"
          />
          <v-tab
            href="#outbox"
            v-text="$t('generics.outbox')"
            id="admin-notifications-tab--new-escort-requests"
          />
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="alerts">
            <alerts-tab :isActive="tab === 'alerts'" />
          </v-tab-item>
          <v-tab-item value="global">
            <messages-tab globalMessages :isActive="tab === 'global'" />
          </v-tab-item>
          <v-tab-item value="outbox">
            <messages-tab :isActive="tab === 'outbox'" />
          </v-tab-item>
          <v-tab-item value="new-escort-requests">
            <new-escort-requests-tab :isActive="tab === 'new-escort-requests'" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AlertsTab from "@/components/adminMessagesTabs/AlertsTab";
import MessagesTab from "@/components/adminMessagesTabs/MessagesTab";
import NewEscortRequestsTab from "@/components/adminMessagesTabs/NewEscortRequestsTab.vue";

export default {
  name: "NotificationsTable",
  components: { AlertsTab, MessagesTab, NewEscortRequestsTab },
  data() {
    return {
      tab: "alerts",
    };
  },
  created() {
    const routeTab = this.$route.params.tab
    if (routeTab) this.tab = routeTab;
  },
};
</script>
