import myAxios from "../../services/myAxios";

export default {
  state: {
    complexPath: null, //chosen complexPath
    complexPaths: [],
    filtered: [],
  },
  getters: {
    complexPaths: (state) => state.complexPaths,
    complexPath: (state) => state.complexPath,
    complexPathFiltered: (state) => state.filtered,
  },
  mutations: {
    //sets all complexPaths
    "complexPaths/set": (state, payload) => {
      state.complexPaths = payload;
      state.filtered = [...state.complexPaths];
    },
    //sets one complexPath
    "complexPath/set": (state, payload) => (state.complexPath = payload),
    //filters the complexPath's array by complexPath's key and complexPath's val
    "complexPaths/filter": (state, { key, val }) => {
      state.filtered = !val
        ? [...state.complexPaths]
        : state.complexPaths.filter((f) => f[key] === val);
    },
    //store one complexPath
    "complexPath/store": (state, payload) => state.complexPaths.push(payload),
    //destroys one complexPath
    "complexPath/destroy": (state, id) =>
      (state.complexPaths = state.complexPaths.filter((item) => {
        return item._id !== id;
      })),
    //updates one complexPath
    "complexPath/update": (state, payload) => {
      state.complexPaths = state.complexPaths.map((item) => {
        if (item._id === payload._id) {
          return payload;
        }
        return item;
      });
    },
  },
  actions: {
    //fetch all complexPaths
    "complexPath/index": async (context) => {
      const { data } = await myAxios.get("/complexPath");
      context.commit("complexPaths/set", data);
    },
    //fetch one complexPath by id
    "complexPath/show": async (context, id) => {
      let { data } = await myAxios.get("/complexPath/" + id);
      context.commit("complexPath/set", data);
    },
    //stores one complexPath
    "complexPath/store": async (context, payload) => {
      let { data } = await myAxios.post("/complexPath", { ...payload });
      context.commit("complexPath/store", { ...data, ...payload });
    },
    //destroys one complexPath
    "complexPath/destroy": async (context, id) => {
      await myAxios.delete("/complexPath/" + id);
      context.commit("complexPath/destroy", id);
    },
    //updates one complexPath by its id
    "complexPath/update": async (context, payload) => {
      await myAxios.put("/complexPath/" + payload._id, payload);
      context.commit("complexPath/update", payload);
    },
  },
};
