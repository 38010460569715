<template>
  <v-navigation-drawer app right v-model="openModal">
    <div class="d-flex justify-center">
      <h3
        class="primary--text font-weight-bold mt-3"
        v-text="$t('generics.mainMenu')"
      />
    </div>
    <v-divider class="my-2" />
    <v-list nav dense v-for="(item, i) in items" :key="i">
      <v-list-item
        @click="goRoute(item.route)"
        :disabled="$route.name === item.route"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title v-text="item.title" />
      </v-list-item>
    </v-list>
    <v-divider class="mt-10" />
    <v-list-item @click="logout">
      <v-list-item-icon>
        <v-icon color="red">mdi-logout</v-icon>
      </v-list-item-icon>
      <v-list-item-title class="red--text" v-text="$t('auth.logout')" />
    </v-list-item>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "Drawer",
  props: { value: Boolean },
  data() {
    return {
      excelDialog: false,
    };
  },
  computed: {
    items() {
      return [
        {
          title: this.$t("drawer.calendar"),
          icon: "mdi-calendar-month-outline",
          route: "AdminDashboard",
        },
        {
          title: this.$t("drawer.points"),
          icon: "mdi-map-marker-multiple",
          route: "AdminPoints",
        },
        {
          title: this.$t("drawer.paths"),
          icon: "mdi-map-outline",
          route: "AdminPaths",
        },
        {
          title: this.$t("escorts.escorts"),
          icon: "mdi-car-side",
          route: "AdminEscorts",
        },
        {
          title: this.$t("drawer.userManagement"),
          icon: "mdi-account-multiple",
          route: "AdminUsers",
        },
        {
          title: this.$t("drawer.notifications"),
          icon: "mdi-message-badge",
          route: "AdminNotifications",
        },
      ];
    },
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    goRoute(r) {
      this.$router.push({ name: r });
      this.openModal = false;
    },
    async logout() {
      this.$store.dispatch("auth/logout", {
        toastOptions: { disabled: true },
      });
    },
  },
};
</script>
