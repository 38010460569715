<template>
  <div>
    <v-card-title class="d-flex align-center">
      <v-row justify="space-between">
        <v-col cols="9">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            icon
            :label="$t('generics.search')"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="center-all">
          <v-icon color="primary" @click="loadData" v-text="'mdi-refresh'" />
          <v-btn v-text="$t('generics.deleteAll')" text color="red" @click="deleteMany" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :loading="loading"
      :items-per-page="20"
      sort-by="createdAt"
    >
      <template v-slot:item.createdAt="{ item }">
        <span :class="isUrgentClassName">{{ clacCreatedAtString(item.createdAt) }}</span>
      </template>
      <template v-slot:item.name="{ item }">
        <span :class="isUrgentClassName">{{ item.passenger.name }}</span>
      </template>
      <template v-slot:item.phone="{ item }">
        <span :class="isUrgentClassName">{{ item.passenger.phone }}</span>
      </template>
      <template v-slot:item.location="{ item }">
        <v-btn
          v-if="getGoogleMapsUrl(item.location.coordinates)"
          text
          :class="isUrgentClassName"
          :href="getGoogleMapsUrl(item.location.coordinates)"
          target="_blank"
        >
          <v-icon>mdi-map-marker</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import times from "@/mixins/times";

export default {
  name: "AlertsTab",
  mixins: [times],
  props: { isActive: { type: Boolean } },
  data() {
    return {
      loading: false,
      search: "",
      isUrgentClassName: "red--text text--darken-4 font-weight-bold",
    };
  },
  methods: {
    async deleteItem(item) {
      let { isConfirmed } = await this.$createSwal({
        icon: "question",
        title: `${this.$t("generics.delete")} ${this.$t("generics.alert")}`,
      });
      if (isConfirmed) {
        await this.$store.dispatch("alert/destroy", item._id);
      }
    },
    clacCreatedAtString(createAtDate) {
      const dateString = new Date(createAtDate).toLocaleDateString(
        this.$i18n.locale
      );
      return `${dateString} (${this.clacRelativeTimeString(createAtDate)})`;
    },
    async deleteMany() {
      let { isConfirmed } = await this.$createSwal({
        icon: "question",
        title: this.$t("alert.deleteAll"),
      });
      if (isConfirmed) await this.$store.dispatch("alert/destroyMany")
    },
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("alert/index");
      this.loading = false;
    },
    getGoogleMapsUrl(coordinates) {
      if (!coordinates?.length) return;
      const [long, lat] = coordinates;
      return lat && long && `https://www.google.com/maps?q=${lat},${long}`
    }
  },
  computed: {
    items() {
      return this.$store.getters.alerts;
    },
    headers() {
      const headers = [
        {
          text: this.$t("date.creationDate"),
          value: "createdAt",
          sort: this.sortByISODate,
        },
        { text: this.$t("userDetails.name"), value: "name" },
        { text: this.$t("userDetails.phone"), value: "phone" },
        { text: this.$t("generics.location"), value: "location" },
        {
          text: this.$t("generics.actions"),
          value: "actions",
          width: "100px",
        },
      ];
      return headers;
    },
  },
  async mounted() {
    await this.loadData();
  },
  watch: {
    async isActive(isActive) {
      if (isActive) await this.loadData();
    },
  },
};
</script>
