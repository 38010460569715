<template>
	<div>
		<v-card-title class="d-flex align-center">
			<v-row>
				<v-col cols="11">
					<v-text-field
						v-model="search"
						append-icon="mdi-magnify"
						icon
						:label="$t('generics.search')"
						single-line
						hide-details
					></v-text-field>
				</v-col>
				<v-col cols="1">
					<v-icon
						color="primary"
						@click="loadData"
						v-text="'mdi-refresh'"
					/>
				</v-col>
			</v-row>
		</v-card-title>
		<v-data-table
			:headers="headers"
			:items="items"
			:search="search"
			:loading="loading"
			:items-per-page="20"
			sort-by="createdAt"
		>
      <template v-slot:item.escort.color="{ item }">
        <colors-pie-chart :color="item.escort.color" />
      </template>
			<template v-slot:item.escort.date.start="{ item }">{{ extractDateString(item.escort.date.start) }}</template>
			<!-- <template v-slot:item.escort.isSingle="{ item }">
				<span
					class="primary--text"
					v-if="item.escort.isSingle"
					v-text="$t('escorts.singleEscort')"
				/>
				<span
					class="red--text"
					v-else
					v-text="$t('escorts.permanentEscort')"
				/>
			</template> -->
      <template v-slot:item.escort.points="{ item }">
        {{getPointsString(item.escort)}}
      </template>
			<template v-slot:item.actions="{ item }">
				<div class="d-flex justify-space-around">
					<v-icon
						@click="beforeAuth(item, true)"
						class="green--text"
					>mdi-check</v-icon>
					<v-icon
						@click="beforeAuth(item, false)"
						class="red--text"
					>mdi-cancel</v-icon>
					<v-icon
						@click="editRequest(item.escort)"
						class="primary--text"
					>mdi-pencil</v-icon>
				</div>
			</template>
		</v-data-table>
		<single-escort-handler
			v-if="singleEscortDialog"
			v-model="singleEscortDialog"
			:escort="chosen"
		/>
		<escort-handler
			v-if="escortDialog"
			v-model="escortDialog"
			:escort="chosen"
		/>
	</div>
</template>

<script>
import times from "@/mixins/times";
import listsMixins from "@/mixins/lists";
import SingleEscortHandler from "../handlers/SingleEscortHandler.vue";
import EscortHandler from "../handlers/EscortHandler.vue"
import _ from "lodash";
import { stopAnnouncementSoundInterval } from '../../helpers/audio';
import ColorsPieChart from '../generics/ColorsPieChart.vue';

export default {
	name: "NewEscortRequestsTab",
	props: {
		isActive: { type: Boolean, default: false }
	},
	components: { SingleEscortHandler, EscortHandler, ColorsPieChart },
	mixins: [listsMixins, times],
	data() {
		return {
			loading: false,
			chosen: null,
			escortDialog: false,
			singleEscortDialog: false,
			search: "",
			headers: [
				{ text: this.$t("generics.color"), value: "escort.color", width: 75 },
				{ text: this.$t("escorts.name"), value: "escort.name" },
				{ text: this.$t("date.dateStart"), value: "escort.date.start" },
				{ text: this.$t("date.timeStart"), value: "escort.time.start" },
				// { text: this.$t("escorts.type"), value: "escort.isSingle" },
				{ text: this.$t("generics.applicant"), value: "request.sender.name" },
				{ text: this.$t("escorts.pointsInPath"), value: "escort.points" },
				{ text: this.$t("generics.notes"), value: "request.notes" },
				{ text: this.$t("generics.actions"), value: "actions", align: "center" },
			],
		};
	},
	methods: {
		async loadData() {
			await this.$store.dispatch("requests/index");
      this.$store.dispatch("escort/get-pending-escorts",
        { toastOptions: { disabled: true } }
      );
		},
		async beforeAuth(item, auth) {
			let { isConfirmed } = await this.$createSwal({
				title: this.$t(auth ? "swals.approveJoin" : "swals.declineJoin"),
			});
			if (isConfirmed) {
				this.auth(item, auth);
			}
		},
		async auth(item, auth) {
			await this.$store.dispatch("escort/approveUserEscort", {
        isSingle: item.escort?.isSingle,
				id: item.escort?._id,
				authorize: auth,
				requestId: item.request._id,
				days: this.getDays(item.escort),
				toastOptions: {
					message:
						auth ? this.$t("toasts.approveJoin", {
							user: item.request?.sender?.name,
							escort: item.escort?.name,
						}) :
							this.$t("toasts.declineJoin", { user: item.request.sender?.name })
				},
			});
		},
		editRequest(escort) {
			this.chosen = _.clone(escort);
			if (escort.isSingle)
				this.singleEscortDialog = true
			else
				this.escortDialog = true
		},
    getPointsString(escort) {
      const getSinglePathPointsStr = (path) => path?.points?.map(({name})=>name).join(', ');

      return escort.pathModel !== "ComplexPath" ?
        getSinglePathPointsStr(escort.path)
        : escort.path?.paths?.map(getSinglePathPointsStr).join(' ✡ ');
    },
    getDays(escort){
      if(escort.days){
        return escort.days
      }
      else{
        return [this.goToday(item.request.date)]
      }
    }
	},
	computed: {
		items() {
      const items = [];
		  if (this.loading) return items;

			this.$store.getters.requestTypeCreateEscort.forEach((request) => {
				const escort = this.$store.getters.escortsIsUserRequest?.find((escort) => escort?._id === request.escort?._id)
        if(!escort) return;
				items.push({ request, escort });
			})
      return items;
		},
	},
	async mounted() {
    stopAnnouncementSoundInterval();
		this.loading = true
    await this.loadData();
		this.loading = false
	},
  watch:{
    escortDialog(){
      this.loadData()
    },
    singleEscortDialog(){
      this.loadData()
    },
    async isActive(isActive) {
      if (isActive) {
        stopAnnouncementSoundInterval();
        await this.loadData();
      }
    },
  }
};
</script>
