var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{staticClass:"mx-1",staticStyle:{"max-width":"11em"},attrs:{"items":_vm.colorsItems,"item-value":"name","label":_vm.$t('escorts.color'),"outlined":"","hide-details":"","dense":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index <= 1)?_c('v-chip',{staticClass:"color-chip px-1",attrs:{"text-color":"white","color":item.color,"small":"","close":""},on:{"click:close":function($event){return _vm.selected.splice(index, 1)}}}):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v("(+"+_vm._s(_vm.selected.length - 2)+")")]):_vm._e()]}},{key:"item",fn:function(ref){
var active = ref.active;
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({style:({ backgroundColor: item.color }),scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"dark":""}})],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }