<template>
  <div>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="2">
        <span>{{ $t("escorts.maxPassengers") }}</span>
        <number-picker
          v-model="maxPassengersEnd"
          :min="minPassengers"
          :step="1"
        />
      </v-col>
      <v-col cols="10">
        <v-autocomplete
          v-model="passengers"
          :items="allPassengers"
          :label="$t('generics.passengers')"
          item-text="passenger.name"
          return-object
          clearable
          multiple
          required
          :rules="[(v) => !!v.length || $t('errors.notEmpty')]"
          :disabled="!areEmptySpots"
        />
      </v-col>
    </v-row>
    <passengers-table-handler
      v-model="passengers"
      :isEditMode="true"
      :maxPassengers="maxPassengers"
      @maxPassengersHandler="(v) => $emit('maxPassengersHandler', v)"
      :areEmptySpots="areEmptySpots"
    />
  </div>
</template>
<script>
import NumberPicker from "@/components/generics/NumberPicker";
import PassengersTableHandler from "@/components/handlers/PassengersTableHandler.vue";
export default {
  name: "passengers-table",
  props: {
    value: {
      type: Array,
      required: true,
    },
    maxPassengers: { type: Number, default: 19 },
  },
  data() {
    return {
      maxPassengersEnd: 5,
    };
  },
  computed: {
    passengers: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    allPassengers() {
      return this.$store.getters.passengers.map((p) => {
        return { passenger: p, numberOfPassengers: 1 };
      });
    },
    areEmptySpots() {
      return this.sumPassengers() < this.maxPassengersEnd;
    },
    minPassengers() {
      return this.sumPassengers();
    },
  },
  components: { NumberPicker, PassengersTableHandler },
  methods: {
    sumPassengers() {
      let count = 0;
      this.passengers.forEach((p) => (count += p.numberOfPassengers));
      return count;
    },
  },
  watch: {
    maxPassengersEnd(v) {
      this.$emit("maxPassengersHandler", v);
    },
  },
  mounted() {
    this.maxPassengersEnd = this.maxPassengers;
  },
};
</script>
