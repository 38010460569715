<template>
  <v-card :color="userNotification.isUrgent && 'error'">
    <v-card-title class="primary--text" v-text="userNotification.notification.title || ''" />
    <v-card-subtitle class="primary--text" v-text="formatTime(userNotification.createdAt)" />
    <v-card-subtitle v-text="userNotification.notification.message" />
    <v-card-actions class="d-flex justify-end">
      <template v-if="userNotification.notification.isApproval">
        <v-btn
          class="mx-2 mb-2"
          v-text="$t('notifications.NotApproved')"
          :outlined="userNotification.declined"
          :text="!userNotification.declined"
          @click="handleClick(false)"
          :disabled="loading"
        />
        <v-btn
          class="mx-2 mb-2"
          v-text="$t('notifications.approved')"
          :outlined="userNotification.approved"
          :text="!userNotification.approved"
          @click="handleClick(true)"
          :disabled="loading"
        />
      </template>
      <v-btn
        class="mx-2 mb-2"
        text
        color="red"
        @click="deleteItem(userNotification)"
        :disabled="loading"
      >
        <v-icon style="opacity: 0.6">mdi-delete-outline</v-icon>
        {{ $t("notifications.read") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import times from "@/mixins/times";

export default {
  props: { userNotification: Object },
  mixins: [times],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async handleClick(isApprove) {
      let data = {
        notification: this.userNotification?.notification?._id,
        userNotification: this.userNotification?._id,
        actionLink: this.userNotification?.notification?.actionLink,
        status: isApprove ? "approve" : "decline",
      };
      await this.$store.dispatch("userNotification/handleAction", data);
    },
    async deleteItem(item) {
      this.loading = true;
      await this.$store.dispatch("userNotification/destroy", item._id);
      this.loading = false;
    },
  },
};
</script>

<style></style>
