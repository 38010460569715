<template>
  <v-container class="mt-5">
    <v-row class="center-all">
      <v-btn
        @click="goPage('MyEscorts')"
        class="ma-4 pa-5"
        x-large
        width="300px"
      >{{ $t("dashboard.myEscort") }}</v-btn>
    </v-row>
    <v-row class="center-all">
      <v-btn
        @click="goPage('AllEscorts')"
        width="300px"
        class="ma-4 pa-5"
        x-large
      >{{ $t("dashboard.allEescorts") }}</v-btn>
    </v-row>
    <v-row class="center-all">
      <v-btn
        @click="goPage('UserDetails')"
        class="ma-4 pa-5"
        x-large
        width="300px"
      >{{ $t("dashboard.personalInformation") }}</v-btn>
    </v-row>
    <v-row class="center-all">
      <v-btn
        @click="goPage('Notifications')"
        class="ma-4 pa-5"
        x-large
        width="300px"
      >{{ $t("dashboard.messages") }}</v-btn>
    </v-row>
    <v-row class="center-all">
      <v-btn
        @click="sendDistressAlert"
        class="ma-4 pa-5"
        color="error"
        x-large
        width="300px"
        height="150px"
      >{{ $t("dashboard.emergencyButton") }}</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import sockets from "@/mixins/sockets";
export default {
  name: "user-dashboard",
  data() {
    return {
      latitude: null,
      longitude: null,
    };
  },
  mixins: [sockets],
  methods: {
    goPage(v) {
      this.$router.push({ name: v });
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.watchPosition((position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
        });
      }
    },
    async sendDistressAlert() {
      this.getLocation();
      let { isConfirmed } = await this.$createSwal({
        icon: "question",
        title: `${this.$t("alert.doSendDistressAlert")}`,
      });
      if (isConfirmed) {
        await this.$store.dispatch("alert/store", {
          location: {
            coordinates: [this.longitude, this.latitude],
          },
          passenger: this.$store.getters.uid,
        });
        this.socket_admin_alert()
      }
    },
  },
};
</script>
