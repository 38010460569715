<template>
  <div>
    <my-dialog
      :title="
        this.isEditMode ? $t('userManagement.edit') : $t('userManagement.new')
      "
      v-model="openModal"
    >
      <template v-slot:content>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col>
              <v-text-field
                :label="$t('userManagement.name')"
                v-model="name"
                autofocus
                :rules="nameValidationRule"
                @keypress.enter="submit"
              />
              <v-text-field
                :label="$t('userManagement.email')"
                v-model="email"
                :rules="emailValidationRule"
                @keypress.enter="submit"
              />
              <v-text-field
                :label="$t('userManagement.phone')"
                v-model="phone"
                :rules="phoneValidationRule"
                @keypress.enter="submit"
              />
              <v-select
                v-if="role >= 4"
                :items="userRoleItems"
                :label="$t('userManagement.role')"
                :rules="requiredValidationRule"
                v-model="role"
              ></v-select>
              <v-text-field
                v-if="changePasswordMode"
                :label="$t('userManagement.password')"
                v-model="password"
                :rules="passwordValidationRule"
                @keypress.enter="submit"
              />
              <span v-else @click="changePasswordMode = !changePasswordMode">
                {{
                  $t("userManagement.changePassword")
                }}
              </span>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions>
        <div class="d-flex flex-column align-center">
          <v-alert icon="mdi-minus-circle" type="error" dense v-if="errorMessage">{{ errorMessage }}</v-alert>
          <v-btn
            text
            color="primary"
            v-text="
              loading
                ? ''
                : !isEditMode
                  ? $t('generics.add')
                  : $t('generics.save')
            "
            @click="submit"
            :loading="loading"
          />
        </div>
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from "@/components/generics/MyDialog";
import { userRoles } from "@/data/lists";
import validationMixins from "@/mixins/validation";

export default {
  name: "user-handler",
  components: { MyDialog },
  props: {
    value: Boolean,
    user: Object,
    isEditMode: Boolean,
    selectedUser: Object,
  },
  mixins: [validationMixins],
  data: () => ({
    name: "",
    email: "",
    password: "",
    phone: "",
    role: 4,
    changePasswordMode: undefined,
    errorMessage: "",
    successMessage: "",
    loading: false,
  }),
  computed: {
    userRoleItems() {
      return userRoles(this);
    },
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    requiredRule() {
      return validationRules(this).required;
    },
  },
  methods: {
    async submit() {
      this.errorMessage = "";
      this.successMessage = "";
      if (!this.validateForm()) return;
      const user = {
        ...this.selectedUser,
        name: this.name,
        phone: this.phone,
        email: this.email,
        role: this.role,
      };
      if (this.changePasswordMode) {
        user.password = this.password;
      } else {
        delete user.password;
      }

      this.loading = true;
      try {
        if (this.isEditMode) {
          if (!this.changePasswordMode) {
            await this.$store.dispatch("user/update", user);
          } else {
            let { isConfirmed } = await this.$createSwal({
              icon: "warning",
              title: this.$t("userManagement.changeUserPasswordWarning", {
                name: user.name,
              }),
            });

            if (isConfirmed) {
              await this.$store.dispatch("user/update", user);
            } else {
              throw new Error("User Cancel");
            }
          }
        } else {
          await this.$store.dispatch("user/store", user);
        }
        this.openModal = false;
      } catch (err) {
        console.error(err.message);
      } finally {
        this.loading = false;
      }
    },
    validateForm() {
      return this.$refs.form.validate();
    },
    async editUser(user) {
      await this.$store.dispatch("user/update", user);
    },
    async addUser(user) {
      await this.$store.dispatch("user/store", user);
    },
  },
  created() {
    if (this.isEditMode) {
      this.name = this.selectedUser.name;
      this.email = this.selectedUser.email;
      this.phone = this.selectedUser.phone;
      this.role = this.selectedUser.role;
    }
    this.changePasswordMode = !this.isEditMode;
  },
};
</script>
