import myAxios from "../../services/myAxios";

export default {
  state: {
    escort: null, //chosen escort
    escorts: [],
    filtered: [],
    userEscorts: [],
  },
  getters: {
    escorts: (state) => state.escorts,
    escortsIsUserRequest: (state) =>
      state.escorts.filter((escort) => escort.isUserRequest),
    escortsAdminCreate: (state) =>
      state.escortsAdminCreate.filter((escort) => !escort.isUserRequest),
    escort: (state) => state.escort,
    escortsFiltered: (state) => state.filtered,
    userEscorts: (state) => state.userEscorts,
  },
  mutations: {
    //sets all escorts
    "escorts/set": (state, payload) => {
      state.escorts = payload;
      state.filtered = [...state.escorts];
    },
    //sets one escort
    "escort/set": (state, payload) => (state.escort = payload),
    //filters the escort's array by escort's key and escort's val
    "escorts/filter": (state, { key, val }) => {
      state.filtered = !val
        ? [...state.escorts]
        : state.escorts.filter((f) => f[key] === val);
    },
    //store one escort
    "escort/store": (state, payload) => state.escorts.push(payload),
    //destroys one escort
    "escort/destroy": (state, id) =>
      (state.escorts = state.escorts.filter((item) => {
        return item._id !== id;
      })),
    //updates one escort
    "escort/update": (state, payload) => {
      state.escorts = state.escorts.map((item) =>
        item._id === payload._id ? payload : item
      );
    },
    leave: (state, { id, user }) => {
      const escort = state.escorts.find((es) => es._id === id);
      escort.passengers = escort?.passengers.filter(
        (p) => p !== user && p._id !== user
      );
    },
    setUserEscorts: (state, payload) => (state.userEscorts = payload),
  },
  actions: {
    //fetch escorts by paging
    "escort/index": async (context, payload) => {
      let { data } = await myAxios.get("/escort", { params: payload });
      //translate data and parse time
      context.commit("escorts/set", data.escorts);
      return data.count;
    },
    //fetch escorts by date range
    "escort/get-by-date-range": async (context, payload) => {
      let { data } = await myAxios.get("/escort/get-by-date-range", {
        params: payload,
      });
      //translate data and parse time
      context.commit("escorts/set", data);
    },
    //fetch pending escorts
    "escort/get-pending-escorts": async (context, payload) => {
      let { data } = await myAxios.get("/escort/get-pending-escorts");
      //translate data and parse time
      context.commit("escorts/set", data);
    },
    //fetch one escort by id
    "escort/show": async (context, id) => {
      let { data } = await myAxios.get("/escort/" + id);
      context.commit("escort/set", data);
    },
    //stores one escort
    "escort/store": async (context, payload) => {
      const baseUrl = payload.isSingle ? "/escort/single" : "/escort";
      let { data } = await myAxios.post(baseUrl, payload);
      context.commit("escort/store", data);
      return data._id;
    },
    //stores one escort by the user
    "escort/store/user": async (context, payload) => {
      let {
        data: { escort, request },
      } = await myAxios.post("/escort/user", payload);
      context.commit("escort/store", escort);
      return { escort, request };
    },
    //destroys one escort
    "escort/destroy": async (context, payload) => {
      const baseUrl = payload.isSingle ? "/escort/single" : "/escort";
      await myAxios.delete(baseUrl + "/" + payload._id);
      context.commit("escort/destroy", payload._id);
    },
    //skips date one escort
    "escort/skips": async (context, { id, date }) => {
      let { data } = await myAxios.put("/escort/skips/" + id, { date });
      context.commit("escort/update", data);
    },
    //updates one escort by its id
    "escort/update": async (context, payload) => {
      const baseUrl = payload.isSingle ? "/escort/single" : "/escort";
      let { data } = await myAxios.put(baseUrl + "/" + payload._id, payload);
      context.commit("escort/update", data);
      context.commit("escort/set", data);
    },
    //user joining an escort
    "escort/join": async (context, payload) => {
      const {
        data: { escort, newExceptionEscort },
      } = await myAxios.post(`/escort/${payload.escortId}/join`, payload);
      context.commit("escort/update", escort);
      if (newExceptionEscort) {
        context.commit("escort/store", newExceptionEscort);
      }
      context.dispatch("escort/userEscorts/index");
    },
    //user leaving an escort
    "escort/leave": async (context, payload) => {
      const { data } = await myAxios.post(
        `escort/${payload.id}/leave`,
        payload
      );
      if (data.operationType === "DELETE") {
        context.commit("escort/destroy", payload.id);
      } else if (data.operationType === "UPDATE") {
        context.commit("escort/update", data.escort);
      }
      if (data.newEscort) {
        context.commit("escort/store", data.newEscort);
      }
    },
    "escort/notifyPassengers": async (context, payload) => {
      await myAxios.post(`/notification/escort/${payload.escortId}`, payload);
    },
    "escort/userEscorts/index": async (context, payload) => {
      let { data } = await myAxios.get("/escort/user-all");
      context.commit("setUserEscorts", data);
    },
    "escort/approveUserEscort": async (context, payload) => {
      const baseUrl = payload.isSingle ? "/escort/single" : "/escort";
      let { data } = await myAxios.post(
        `${baseUrl}/${payload.id}/approveUserEscort`,
        payload
      );
      if (payload.authorize) {
        context.commit("escort/update", data);
      } else {
        context.commit("escort/destroy", payload.id);
      }
      context.commit("request/destroy", payload.requestId);
    },
    "escort/clear-escorts-in-range": async (context, payload) => {
      return await myAxios.post(`/escort/clear-escorts-in-range`, payload);
    },
    "escort/change-done-status": async (context, payload) => {
      const { data } = await myAxios.put(
        `/escort/${payload.id}/change-done-status`,
        payload
      );
      context.commit("escort/update", data);
    },
  },
};
