<template>
  <v-dialog v-model="openModal" max-width="400">
    <v-card class="d-flex flex-column align-center">
      <v-card-title class="text-h5 mb-2">
        {{
          $t("escorts.deleteEscorts")
        }}
      </v-card-title>
      <v-form v-model="valid" ref="form" class="d-flex flex-column align-center">
        <div>
          <my-date-picker validate v-model="startDate" :end="endDate" :label="$t('date.from')" />
          <my-date-picker validate v-model="endDate" :start="startDate" :label="$t('date.to')" />
        </div>
        <v-card-actions>
          <v-btn
            color="error"
            type="sumbit"
            :loading="loading"
            text
            @click.prevent="deleteEscorts"
          >{{ $t("generics.delete") }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import MyDatePicker from '../generics/MyDatePicker.vue';
export default {
  components: { MyDatePicker },
  name: "delete-escorts-handler",
  props: { value: Boolean },
  data() {
    return {
      startDate: null,
      endDate: null,

      valid: false,
      loading: false
    }
  },
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.reset();
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async deleteEscorts() {
      try {
        if (!this.validate()) return;
        let { isConfirmed } = await this.$createSwal({
          title: this.$t("escorts.deleteEscorts"),
          text: this.$t("swals.deleteEscortsInRange", {
            startDate: this.startDate,
            endDate: this.endDate
          }),
        });
        if (isConfirmed) {
          this.loading = true;
          await this.$store.dispatch("escort/clear-escorts-in-range", {
            startDate: new Date(this.startDate),
            endDate: new Date(this.endDate),
          })
          await this.$emit('onSuccess')

          this.reset();
        }
      } finally {
        this.loading = false
      }
    },
    validate() {
      return this.$refs.form.validate()
    },
    reset() {
      this.$refs.form.reset()
    },
  },
}
</script>
