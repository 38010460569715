<template>
  <div>
    <my-dialog
      :title="item ? $t('notifications.edit') : $t('notifications.new')"
      v-model="openModal"
    >
      <template v-slot:content>
        <v-form ref="form" lazy-validation>
          <template v-if="isNew">
            <v-select
              autofocus
              :items="notificationAddresseeTypesItems"
              :label="$t('generics.addressee')"
              :rules="requiredValidationRule"
              v-model="type"
              @change="onChangeAddresseeType"
            />
            <v-autocomplete
              v-if="type && type !== 'global'"
              v-model="addresseeId"
              ref="itemAutocomplete"
              @keydown="onTypeAddresseeItem"
              :items="addresseeItems"
              :label="addresseeLabel"
              :item-text="addresseeTextKey"
              item-value="_id"
              clearable
              required
              :rules="requiredValidationRule"
              :loading="addresseeItemsLoading"
            />
          </template>
          <v-text-field
            :label="$t('generics.title')"
            v-model="title"
            :rules="requiredValidationRule"
            @keypress.enter="submit"
          />
          <v-textarea
            rows="3"
            outlined
            :label="$t('generics.message')"
            v-model="message"
            :rules="requiredValidationRule"
            @keypress.enter="submit"
          />
          <v-select
            :items="urgentLevelItems"
            :label="$t('notifications.urgentLevel')"
            :rules="requiredValidationRule"
            v-model="isUrgent"
          />
          <v-switch v-model="sendSms" inset label="שליחת sms"></v-switch>
        </v-form>
        <v-row v-if="err">
          <v-col class="error--text text-center">{{ err }}</v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn
          text
          color="primary"
          v-text="isNew ? $t('generics.add') : $t('generics.save')"
          @click="submit"
        />
      </template>
    </my-dialog>
  </div>
</template>
<script>
import { notificationAddresseeTypes, urgentLevels } from "@/data/lists";
import MyDialog from "@/components/generics/MyDialog";
import times from "@/mixins/times";
import validationMixins from "@/mixins/validation";
import myAxios from "@/services/myAxios";

export default {
  name: "notification-handler",
  components: { MyDialog },
  props: { value: Boolean, item: Object, isNew: Boolean },
  mixins: [times, validationMixins],
  data: () => ({
    isUrgent: null,
    title: "",
    message: "",
    type: "",
    matchEscorts: [],
    addresseeId: "",
    err: null,
    addresseeItemsLoading: false,
    sendSms: false,

    // for autocomplete escorts from server
    searchQueryLastTyping: null, // time in ms
    searchQueryTimeoutId: null,
  }),
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    urgentLevelItems() {
      return urgentLevels(this);
    },
    notificationAddresseeTypesItems() {
      return notificationAddresseeTypes(this);
    },
    addresseeItems() {
      if (this.type === "escort") {
        return this.matchEscorts;
      }
      return this.$store.getters.passengers;
    },
    addresseeLabel() {
      if (this.type === "escort") {
        return this.$t("escorts.escort");
      }
      return this.$t("generics.user");
    },
    addresseeTextKey() {
      if (this.type === "single-user") {
        return "email";
      } else {
        return "name";
      }
    },
  },
  methods: {
    async submit() {
      this.errorMessage = "";
      this.successMessage = "";
      if (!this.validateForm()) return;
      const newItem = {
        ...this.item,
        isUrgent: this.isUrgent,
        sendSms: this.sendSms,
        title: this.title,
        message: this.message,
      };
      if (this.isNew) {
        newItem.type = this.type;
        newItem.addresseeId = this.addresseeId;
      }
      if (this.type === "escort") {
        newItem.escort = this.addresseeId;
      }

      this.loading = true;
      try {
        if (!this.isNew) {
          await this.$store.dispatch("notification/update", newItem);
        } else {
          await this.$store.dispatch("notification/store", newItem);
        }
        this.openModal = false;
      } catch (err) {
        console.error(err.message);
      } finally {
        this.loading = false;
      }
    },
    async onChangeAddresseeType(type) {
      this.addresseeItemsLoading = true;
      if (type === "single-user" && !this.$store.getters.users?.length)
        await this.$store.dispatch("users/index");
      this.addresseeItemsLoading = false;
    },
    validateForm() {
      return this.$refs.form.validate();
    },
    createErr(msg) {
      this.err = msg;
      setTimeout(() => {
        this.err = null;
      }, 2000);
      return;
    },
    async onTypeAddresseeItem(ev) {
      const DELAY_TIME = 400;
      let searchQuery = ev.target.value;
      if (['Delete', 'Backspace'].includes(ev.key)) searchQuery = searchQuery.slice(0, -1);
      else searchQuery += ev.key;

      if (searchQuery.trim().length < 2) {
        this.matchEscorts = [];
        return
      };

      const dateNow = Date.now();
      this.searchQueryLastTyping = dateNow;
      const timeFromTheLastTyping = dateNow - this.searchQueryLastTyping;

      if (this.searchQueryTimeoutId)
        clearTimeout(this.searchQueryTimeoutId);

      this.searchQueryTimeoutId = setTimeout(() => {
        this.addresseeItemsLoading = true;
        this.searchQueryLastTyping = dateNow + timeFromTheLastTyping;
        myAxios
          .get("/escort", { params: { searchQuery } })
          .then(({ data }) => { this.matchEscorts = data.escorts })
          .finally(() =>
            this.addresseeItemsLoading = false
          );
      }, DELAY_TIME);
    }
  },
  created() {
    if (!this.isNew) {
      this.isUrgent = this.item.isUrgent;
      this.title = this.item.title;
      this.message = this.item.message;
      this.type = this.item.type;
    }
  },
};
</script>
