<template>
  <div>
    <v-card-title class="d-flex align-center">
      <v-row>
        <v-col cols="11">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            icon
            :label="$t('generics.search')"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-icon color="primary" @click="addDialog = true" v-text="'mdi-plus'" />
          <v-icon color="primary" @click="loadData" v-text="'mdi-refresh'" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :loading="loading"
      :items-per-page="20"
      sort-by="createdAt"
    >
      <template v-slot:item.createdAt="{ item }">
        <span :class="getCellTextClassName(item)">
          {{
            new Date(item.createdAt).toLocaleString($i18n.locale)
          }}
        </span>
      </template>
      <template v-slot:item.addressee="{ item }">
        <span :class="getCellTextClassName(item)">
          <addressee-text
            :key="'addressee-for-' + item._id"
            :notificationId="item._id"
            :notificationType="item.type"
          />
        </span>
      </template>
      <template v-slot:item.expiresAt="{ item }">
        <span :class="getCellTextClassName(item)">
          {{
            new Date(item.expiresAt).toLocaleDateString($i18n.locale)
          }}
        </span>
      </template>
      <template v-slot:item.isUrgent="{ item }">
        <span :class="getCellTextClassName(item)">
          {{
            item.isUrgent ? $t("generics.urgent") : $t("generics.regular")
          }}
        </span>
      </template>
      <template v-slot:item.message="{ item }">
        <span :class="getCellTextClassName(item)">{{ item.message }}</span>
      </template>
      <template v-slot:item.title="{ item }">
        <span :class="getCellTextClassName(item)">{{ item.title }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <notification-handler v-if="addDialog" v-model="addDialog" :isNew="true" />
    <notification-handler v-if="editDialog" v-model="editDialog" :item="chosen" />
  </div>
</template>

<script>
import times from "@/mixins/times";
import AddresseeText from "@/components/AddresseeText";
import NotificationHandler from "@/components/handlers/NotificationHandler";

export default {
  name: "MessagesTab",
  components: { AddresseeText, NotificationHandler },
  mixin: [times],
  props: {
    globalMessages: { type: Boolean, default: false },
    isActive: { type: Boolean },
  },
  data() {
    return {
      loading: false,
      chosen: null,
      search: "",
      addDialog: false,
      editDialog: false,
    };
  },
  methods: {
    async deleteItem(item) {
      let { isConfirmed } = await this.$createSwal({
        icon: "question",
        title: `${this.$t("generics.delete")} ${item.title}`,
      });
      if (isConfirmed) {
        await this.$store.dispatch("notification/destroy", item._id);
      }
    },
    editItem(item) {
      this.chosen = item;
      this.editDialog = true;
    },
    getCellTextClassName(item) {
      return item.isUrgent ? "red--text text--darken-4 font-weight-bold" : "";
    },
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("notification/index");
      this.loading = false;
    },
  },
  computed: {
    items() {
      if (this.globalMessages) {
        return this.$store.getters.notifications.filter(
          ({ type }) => type === "global"
        );
      }
      return this.$store.getters.notifications.filter(
        ({ type }) => type !== "global"
      );
    },
    headers() {
      const headers = [
        {
          text: this.$t("date.creationDate"),
          value: "createdAt",
          sort: this.sortByISODate,
        },
        ...(!this.globalMessages
          ? [{ text: this.$t("generics.addressee"), value: "addressee" }]
          : []),
        { text: this.$t("date.deletionDate"), value: "expiresAt" },
        { text: this.$t("notifications.urgentLevel"), value: "isUrgent" },
        { text: this.$t("generics.title"), value: "title" },
        { text: this.$t("generics.message"), value: "message" },
        {
          text: this.$t("generics.actions"),
          value: "actions",
          width: "100px",
        },
      ];
      return headers;
    },
  },
  async mounted() {
    await this.loadData();
  },
  watch: {
    async isActive(isActive) {
      if (isActive) await this.loadData();
    },
  },
};
</script>
