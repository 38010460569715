export const userRoles = (_this) => [
  { text: _this.$t("userManagement.roleTypes.regular"), value: 4 },
  { text: _this.$t("userManagement.roleTypes.escorter"), value: 3 },
  { text: _this.$t("userManagement.roleTypes.coordinationCenter"), value: 2 },
  { text: _this.$t("userManagement.roleTypes.correlation"), value: 1 },
  { text: _this.$t("userManagement.roleTypes.admin"), value: 0 },
];

export const days = (_this) => [
  { text: _this.$t("days.sunday"), value: 0 },
  { text: _this.$t("days.monday"), value: 1 },
  { text: _this.$t("days.tuesday"), value: 2 },
  { text: _this.$t("days.wednesday"), value: 3 },
  { text: _this.$t("days.thursday"), value: 4 },
  { text: _this.$t("days.friday"), value: 5 },
  { text: _this.$t("days.saturday"), value: 6 },
];

export const urgentLevels = (_this) => [
  { text: _this.$t("generics.urgent"), value: true },
  { text: _this.$t("generics.regular"), value: false },
];

export const notificationAddresseeTypes = (_this) => [
  { text: _this.$t("generics.global"), value: "global" },
  {
    text: _this.$t("escorts.escort"),
    value: "escort",
  },
  {
    text: _this.$t("generics.user"),
    value: "single-user",
  },
];
