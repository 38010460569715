var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',{staticClass:"d-flex align-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","icon":"","label":_vm.$t('generics.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('mdi-refresh')},on:{"click":_vm.loadData}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"items-per-page":20,"sort-by":"createdAt"},scopedSlots:_vm._u([{key:"item.escort.color",fn:function(ref){
var item = ref.item;
return [_c('colors-pie-chart',{attrs:{"color":item.escort.color}})]}},{key:"item.escort.date.start",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.extractDateString(item.escort.date.start)))]}},{key:"item.escort.points",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPointsString(item.escort))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-around"},[_c('v-icon',{staticClass:"green--text",on:{"click":function($event){return _vm.beforeAuth(item, true)}}},[_vm._v("mdi-check")]),_c('v-icon',{staticClass:"red--text",on:{"click":function($event){return _vm.beforeAuth(item, false)}}},[_vm._v("mdi-cancel")]),_c('v-icon',{staticClass:"primary--text",on:{"click":function($event){return _vm.editRequest(item.escort)}}},[_vm._v("mdi-pencil")])],1)]}}])}),(_vm.singleEscortDialog)?_c('single-escort-handler',{attrs:{"escort":_vm.chosen},model:{value:(_vm.singleEscortDialog),callback:function ($$v) {_vm.singleEscortDialog=$$v},expression:"singleEscortDialog"}}):_vm._e(),(_vm.escortDialog)?_c('escort-handler',{attrs:{"escort":_vm.chosen},model:{value:(_vm.escortDialog),callback:function ($$v) {_vm.escortDialog=$$v},expression:"escortDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }