<template>
  <div>
    <v-toolbar
      flat
      class="d-flex justify-center"
    >
      <v-btn
        outlined
        color="grey darken-2"
        @click="setToday"
      >
        {{
          $t("date.today")
        }}
      </v-btn>
      <v-btn
        fab
        text
        color="grey darken-2"
        @click="prev"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-toolbar-title
        v-if="$refs.calendar"
        class="mx-5"
        v-text="monthString()"
      />
      <v-btn
        fab
        text
        color="grey darken-2"
        @click="next"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-select
        :items="calendarTypeItems"
        v-model="type"
        :label="$t('generics.display')"
        outlined
        hide-details
        dense
        style="max-width:7em"
      />
      <color-filter v-model="colorsFilter"/>
      <v-select
        :items="escortTypes"
        v-model="escortTypeFilter"
        :label="$t('escorts.escortType')"
        outlined
        hide-details
        dense
        style="max-width:10em"
        clearable
      />
      <v-btn
        fab
        text
        color="primary"
        @click="loadData"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>
    <v-sheet
      :height="type==='month' ? '82vh' : 'auto'"
      :dir="$i18n.locale === 'he' ? 'rtl' : 'ltr'"
    >
      <v-calendar
        ref="calendar"
        v-model="today"
        color="primary"
        :events="filteredEvents"
        :categories="colorsItems"
        category-text="text"
        category-show-all
        :event-category="({ color }) => $t(`colors.${color}`)"
        :type="type"
        mode="stack"
        :event-color="({isDone, color})=> isDone ? '#ffffff' : color"
        :event-text-color="({isDone})=> isDone ? 'success' : '#ffffff'"
        event-overlap-mode="column"
        :interval-height="70"
        event-overlap-threshold="10"
        @click:more="showEvent"
        @click:date="showEvent"
        @click:day="showEvent"
        @change="updateRange"
        @click:event="showEvent"
      />
    </v-sheet>
    <escort-handler
      v-if="addDialog"
      v-model="addDialog"
      :handleExeptions="true"
      :today="today"
      :isNew="true"
    ></escort-handler>
    <single-escort-handler
      v-if="addSingleDialog"
      v-model="addSingleDialog"
      :isNew="true"
    />
    <escort-display
      v-if="showDialog"
      v-model="showDialog"
      :escort="chosen"
      :event="selectedEvent"
    />
    <escort-type-dialog
      v-model="escortTypeDialog"
      v-if="escortTypeDialog"
      @createOne="createEvent('permanent')"
      @createRecurring="createEvent('onTime')"
    />
    <delete-escorts-handler v-model="deleteEscortsHandler" @onSuccess="fetchEscortsInRange()" />

    <float-btn v-if="$store.getters.role >= 2" @cb="escortTypeDialog = true"/>
    <v-speed-dial
      v-else
      v-model="isFabOpen"
      fixed
      bottom
      left
    >
      <template v-slot:activator>
        <v-btn
          v-model="isFabOpen"
          color="blue darken-2"
          dark
          fab
        >
          <v-icon v-if="isFabOpen">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="error"
        class="relative"
        @click="deleteEscortsHandler = true"
      >
        <v-icon>mdi-calendar-remove</v-icon>
        <div :style="fabActionLabelStyle">{{ $t('escorts.deleteEscorts') }}
        </div>
      </v-btn>
      <v-btn
        fab
        dark
        color="primary"
        class="relative"
        @click="escortTypeDialog = true"
      >
        <v-icon>mdi-calendar-plus</v-icon>
        <div :style="fabActionLabelStyle">{{ $t('escorts.createEscort') }}</div>
      </v-btn>
    </v-speed-dial>
  </div>
</template>
<script>
import EventMixins from "@/mixins/events";
import listsMixins from '@/mixins/lists';
import times from "@/mixins/times";
import EscortHandler from "@/components/handlers/EscortHandler";
import SingleEscortHandler from "@/components/handlers/SingleEscortHandler";
import EscortDisplay from "@/components/EscortDisplay";
import EscortTypeDialog from "@/components/EscortTypeDialog";
import FloatBtn from "@/components/generics/FloatBtn";
import DeleteEscortsHandler
  from '../../components/handlers/DeleteEscortsHandler.vue';
import ColorFilter from '../../components/generics/ColorFilter.vue';

export default {
  name: "AdminCalendar",
  components: {
    EscortHandler,
    EscortDisplay,
    SingleEscortHandler,
    FloatBtn,
    EscortTypeDialog,
    ColorFilter,
    DeleteEscortsHandler
  },
  mixins: [listsMixins, EventMixins, times],
  data: () => ({
    loading: false,
    escortTypeDialog: false,
    deleteEscortsHandler: false,
    addDialog: false,
    addSingleDialog: false,
    editDialog: false,
    showDialog: false,
    isFabOpen: false
  }),
  computed: {
    escorts() {
      return this.$store.getters.escorts;
    },
    fabActionLabelStyle() {
      return {
        position: 'absolute',
        top: '50%',
        left: '100%',
        transform: 'translateY(-50%)',
        marginLeft: '.8em',
        textTransform: 'none',
        padding: '.4em .7em',
        backgroundColor: '#666',
        borderRadius: '.3em'
      };
    }
  },
  watch: {
    escorts() {
      this.updateRange();
    },
  },
  methods: {
    createEvent(type) {
      this.escortTypeDialog = false;
      this.chosen = null;
      if (type === "permanent") {
        this.addDialog = true;
      } else {
        this.addSingleDialog = true;
      }
    },
    showEvent({event}) {
      if (!event) return;
      this.$store.commit("escort/set", event.escort);
      this.chosen = event.escort;
      this.selectedEvent = event;
      this.showDialog = true;
    },
    editEvent(e) {
      const {event} = e;
      this.selectedEvent = e;
      this.chosen = this.allEscorts.find((escort) => escort._id === event._id);
      this.$store.commit(
        "escort/set",
        this.allEscorts.find((escort) => escort._id === event._id)
      );
    },
    editItem(item) {
      this.current = item;
      this.editDialog = true;
    },
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("users/index");
      await this.fetchEscortsInRange();
      this.loading = false;
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>
