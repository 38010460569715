import Vue from "vue";
import VueRouter from "vue-router";
import auth from "../store/modules/authModule";

//public views
import Login from "../views/Login";

//user's views
import MyEscorts from "../views/user/MyEscorts";
import Dashboard from "../views/user/Dashboard";
import AllEscorts from "../views/user/AllEscorts";
import Notifications from "../views/user/Notifications";
import UserDetails from "../views/user/UserDetails";

//admin's views
import AdminEscorts from "../views/admin/Escorts";
import AdminPoints from "../views/admin/Points";
import AdminPaths from "../views/admin/Paths";
import AdminUsers from "../views/admin/Users";
import AdminCalender from "../views/admin/Calendar";
import AdminNotifications from "../views/admin/Notifications";

Vue.use(VueRouter);

function createRouteGuardByRole(role) {
  return (to, from, next) => {
    const isAuthorized =
      auth.state.role !== null &&
      (role === "admin" ? auth.state.role <= 2 : auth.state.role >= 3);
    if (isAuthorized) {
      next();
    } else next({ name: "Login" });
  };
}

function redirectToDashboardHandler(to, from, next) {
  if (auth.state.isLoggedIn) {
    if (auth.state.role <= 2) {
      next("/admin/dashboard");
    } else {
      next("/dashboard");
    }
  } else next();
}

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    beforeEnter: redirectToDashboardHandler,
  },
  {
    path: "/dashboard",
    name: "UserDashboard",
    component: Dashboard,
    beforeEnter: createRouteGuardByRole("user"),
  },
  {
    path: "/all-escorts",
    name: "AllEscorts",
    component: AllEscorts,
    beforeEnter: createRouteGuardByRole("user"),
  },
  {
    path: "/user-details",
    name: "UserDetails",
    component: UserDetails,
    beforeEnter: createRouteGuardByRole("user"),
  },

  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    beforeEnter: createRouteGuardByRole("user"),
  },
  {
    path: "/my-escorts",
    name: "MyEscorts",
    component: MyEscorts,
    beforeEnter: createRouteGuardByRole("user"),
  },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: AdminCalender,
    beforeEnter: createRouteGuardByRole("admin"),
  },
  {
    path: "/admin/paths",
    name: "AdminPaths",
    component: AdminPaths,
    beforeEnter: createRouteGuardByRole("admin"),
  },
  {
    path: "/admin/points",
    name: "AdminPoints",
    component: AdminPoints,
    beforeEnter: createRouteGuardByRole("admin"),
  },
  {
    path: "/admin/escorts",
    name: "AdminEscorts",
    component: AdminEscorts,
    beforeEnter: createRouteGuardByRole("admin"),
  },
  {
    path: "/admin/users",
    name: "AdminUsers",
    component: AdminUsers,
    beforeEnter: createRouteGuardByRole("admin"),
  },
  {
    path: "/admin/notifications",
    name: "AdminNotifications",
    component: AdminNotifications,
    props: true,
    beforeEnter: createRouteGuardByRole("admin"),
  },
  {
    path: "*",
    redirect: { name: "Login" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

//TODO: create route guard
router.beforeEach(async (to, from, next) => {
  try {
    next();
  } catch (e) {
    return next();
  }
});

export default router;
