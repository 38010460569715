const he = {
  appTitle: "עטרת ליווים",
  userAppbarTitle: "מוקד ליווים",
  adminAppbarTitle: "מערכת ניהול ליווים",
  generics: {
    the: "את",
    home: "בית",
    mainMenu: "תפריט ראשי",
    change: "שנה",
    changes: "שינויים",
    actions: "פעולות",
    delete: "למחוק",
    forceChanges: "כפה שנויים",
    remove: "מחיקה",
    name: "שם",
    show: "הצג",
    add: "הוסף",
    save: "שמור",
    edit: "ערוך",
    search: "חיפוש",
    new: "חדש",
    cancel: "ביטול",
    confirm: "אישור",
    user: "משתמש",
    users: "משתמשים",
    selectColors: "בחר צבע",
    color: "צבע",
    colors: "צבעים",
    escorters: "מלווים",
    send: "שלח",
    title: "כותרת",
    message: "הודעה",
    alert: "הודעת מצוקה",
    alerts: "הודעות מצוקה",
    globalNotifications: "הודעות כלליות",
    requestsMessages: "בקשות הצטרפות",
    deleteAll: "מחק הכל",
    outbox: "דואר יוצא",
    urgent: "דחוף",
    regular: "רגיל",
    complex: "מורכב",
    days: "ימים",
    date: "תאריך",
    time: "זמן",
    passenger: "תושב",
    passengers: "תושבים",
    all: "הכל",
    status: "סטטוס",
    approve: "מאשר",
    decline: "דחה",
    reject: "דחה",
    location: "מיקום",
    before: "לפני",
    addressee: "נמען",
    join: "הצטרפות",
    leave: "יציאה",
    requestSent: "בקשתך נשלחה",
    global: "כללי",
    applicant: "הפונה",
    type: "סוג",
    escort: "ליווי",
    sentAt: "נשלח בתאריך",
    newEscortRequests: "בקשת ליווי חדש",
    numberOfPeople: "מספר נפשות",
    oneTime: "חד פעמי",
    permanent: "קבוע",
    notes: "הערות",
    display: "תצוגה",
    done: "בוצע",
    undone: "לא בוצע",
  },
  days: [
    { key: 0, name: "ראשון", short: "א" },
    { key: 1, name: "שני", short: "ב" },
    { key: 2, name: "שלישי", short: "ג" },
    { key: 3, name: "רביעי", short: "ד" },
    { key: 4, name: "חמישי", short: "ה" },
    { key: 5, name: "שישי", short: "ו" },
    { key: 6, name: "שבת", short: "ז" },
  ],
  date: {
    month: "חודש",
    week: "שבוע",
    day: "יום",
    today: "היום",
    from: "מתאריך",
    to: "עד תאריך",
    dateStart: "תאריך התחלה",
    dateEnd: "תאריך סיום",
    creationDate: "תאריך יצירה",
    deletionDate: "תאריך מחיקה",
    timeStart: "זמן התחלה",
    timeEnd: "זמן סיום",
    minutes: "דקות",
    monthsNames: [
      "ינואר",
      "פברואר",
      "מרץ",
      "אפריל",
      "מאי",
      "יוני",
      "יולי",
      "אוגוסט",
      "ספטמבר",
      "אוקטובר",
      "נובמבר",
      "דצמבר",
    ],
  },
  drawer: {
    home: "דף הבית",
    calendar: "לוח שנה",
    points: "נקודות במסלול",
    paths: "מסלולים",
    complexPaths: "מסלולים מורכבים",
    escorts: "ליווים",
    userManagement: "ניהול משתמשים",
    myEscorts: "הליווים שלי",
    notifications: "הודעות",
  },
  auth: {
    email: "אימייל",
    password: "סיסמה",
    login: "התחברות",
    logout: "התנתקות",
  },
  admin: {
    adminLoginTitle: "כניסה לעמוד המנהלים",
  },
  points: {
    points: "נקודות",
    new: "הוסף נקודה חדשה",
    edit: "ערוך נקודה",
  },
  paths: {
    path: "מסלול",
    paths: "מסלולי נקודות",
    new: "הוסף מסלול חדש",
    edit: "ערוך מסלול",
    isCircular: "מעגלי",
    notCircular: "לא מעגלי",
    handlerTitle: "{prefix} מסלול {suffix}",
    time: "אורך הליווי",
  },
  complexPaths: {
    complexPath: "מסלול מורכב",
    complexPaths: "מסלולים מורכבים",
    new: "הוסף מסלול מורכב חדש",
    edit: "ערוך מסלול מורכב",
    isCircular: "מעגלי",
    notCircular: "לא מעגלי",
    handlerTitle: "{prefix} מסלול מורכב {suffix}",
    time: "אורך הליווי",
  },
  escorts: {
    name: "שם ליווי",
    singleEscort: "ליווי חד פעמי",
    permanentEscort: "ליווי קבוע",
    noEscorts: "אין ליווים",
    escort: "ליווי",
    escorts: "ליווים",
    escortsForDate: "ליווים לתאריך",
    new: "הוספת ליווי חדש",
    edit: "ערוך ליוי",
    duplicate: "שכפל ליווי",
    cycle: "מחזור",
    escortType: "סוג ליווי",
    color: "צבע ליווי",
    chooseEscortType: "מהו סוג הליווי?",
    maxPassengers: "מקסימום תושבים",
    passengers: "תושבים",
    passengersCount: "כמות אנשים",
    type: "סוג ליווי",
    types: {
      vehicle: "רכוב",
      "vehicle-foot": "רכוב + רגלי",
      foot: "רגלי",
    },
    cycles: {
      weekly: "שבועי",
      daily: "יומי",
      single: "חד פעמי",
    },
    askToJoin: "בקש להצטרף",
    requestPending: "ממתין לאישור",
    askToLeave: "ביטול הצטרפות",
    joininType: "סוג הצטרפות",
    joininTypes: {
      once: "חד פעמי",
      onTheWay: "על הדרך",
      permanent: "קבוע",
    },
    leavingType: "סוג ביטול",
    leavingTypes: {
      once: "חד פעמי",
      permanent: "קבוע",
    },
    joinRequestInProgress: "בקשת ההצטרפות בטיפול",
    joinType: "למתי תרצה להצטרף",
    totalJoin: "הצטרפות לליווי כולו",
    joinOnce: "רק לתאריך {date}",
    totalLeave: "יציאה מהליווי כולו",
    leaveOnce: "רק את תאריך {date}",
    isMember: "מאושר",
    membership: "הצטרפות",
    cancelJoinRequest: "ביטול הצטרפות",
    createEscort: "יצירת ליווי",
    editSingleEscort: "ערוך ליווי הזה",
    editTotalEscort: "עריכת כל הליווי",
    deleteEscorts: "מחיקת ליווים",
    deleteSingleEscort: "מחיקת ליווי זה",
    deleteTotalEscort: "מחיקת הליווי כולו",
    leave: "עזוב ליווי",
    absenceReport: "דווח על היעדרות",
    pointsInPath: "נקודות במסלול",
    pendingMsg: "שינויים בהמתנה",
    usersDidntApprove: "אף תושב לא אישר שינויים",
    changesHeader: "החלת שינויים ושליחת הודעה למשתתפים",
    changeAll: "שנה את כל האירועים",
    changeSingle: "שנה אירוע זה בלבד",
    sendMsg: "שלח הודעה למשתתפים",
    noMsg: "אל תשלח הודעה",
    infoMsg: "שליחת הודעה אינפורמטיבית",
    approvalMsg: "שליחת בקשה לאישור השינוי",
    forceChangesMsg: "לכפות שינויים",
    exitEscortMsg: "יצאת מליווי זה",
    numberPassengers: "כמה נוסעים אתם?",
    numberOfPassengers: "מספר הנוסעים",
    theEscortFull: "הליווי מלא",
  },
  colors: {
    grey: "אפור - זוג ליווי",
    orange: "כתום - יונתן",
    red: "אדום - זוג ליווי",
    green: "ירוק - נריה",
    blue: "כחול - קופח, מעיין",
  },
  userManagement: {
    name: "שם",
    email: "אימייל",
    phone: "טלפון",
    password: "סיסמה",
    role: "סוג משתמש",
    changePassword: "שנה סיסמה",
    new: "הוספת משתמש",
    edit: "עריכת משתמש",
    createUserSuccessMessage: "המשתמש נוסף בהצלחה",
    editUserSuccessMessage: "המשתמש עודכן בהצלחה",
    changeUserPasswordWarning: "הסיסמא של {name} עומדת להשתנות. אתה בטוח?",
    roleTypes: {
      regular: "תושב",
      escorter: "מלווה",
      coordinationCenter: "מוקד תיאומים",
      correlation: "מתאם",
      admin: "מנהל",
    },
    allUsers: "כל המשתמשים",
  },
  notifications: {
    noMessages: "אין הודעות",
    new: "הוסף התראה חדשה",
    edit: "ערוך התראה",
    systemMessages: "הודעות מערכת",
    read: "קראתי",
    NotApproved: "לא מאשר",
    approved: "מאשר",
    urgentLevel: "רמת דחיפות",
    alertPopup: "התראת מצוקה חדשה",
    showAlert: "הצג התראה",
    joinToEscortSuccess: "הצטרפת לליווי {escortName}",
    joinToEscort: "{userName} הצטרף לליווי {escortName}",
    leaveEscortForever: "{userName} עזב את ליווי {escortName} לצמיתות",
    leaveEscortOneTime: "{userName} עזב את ליווי {escortName} חד-פעמית",
    askEscort: "{userName} מבקש ליווי {escortRelativeTime}",
    newEscort: "בקשת ליווי חדש '{escortName}'",
  },
  alert: {
    doSendDistressAlert: "לשלוח הודעת מצוקה",
    GeolocationNotSupported: "המיקום לא מאושר",
    allowGeolocation: "אפשר מיקום",
    deleteAll: "האם למחוק את כל הודעות המצוקה",
  },
  userDetails: {
    userDetails: "פרטים אישיים",
    name: "שם",
    phone: "טלפון",
    email: "אימייל",
    address: "כתובת",
  },
  errors: {
    validate: {
      complexPaths: "אנא הכנס שם ולפחות 2 מסלולים",
      paths: "אנא הכנס שם ולפחות 2 נקודות",
      required: "שדה חובה",
      minlen6: "מינימום 6 תוים",
      minlen2: "מינימום 2 תוים",
      maxlen40: "מקסימום 40 תוים",
      choose: "נא לבחור",
      minAndMaxPassenger: "בחר מינמום נוסע אחד ומקסימום {max} נוסעים",
    },
    auth: {
      email: "יש להזין אמייל",
      emailInvalid: "אימייל שגוי",
      phoneInvalid: "טלפון שגוי",
      pass: "יש להזין סיסמה",
      minlen6: "מינימום 6 תוים",
    },
    escorts: {
      invaildDate: "תאריך ההתחלה חייב להיות לפני תאריך הסוף",
      invaild: "אנא מלא את כל השדות",
      enterMsg: "אנא הכנס הודעה למשתתפים",
      deleteTotal: "האם למחוק את הליווי כולו",
      deleteSingle: "האם למחוק מופע זה",
      deletePending: "האם למחוק שינויים אלו",
    },
    notifications: {
      isApproval: "השינויים לא יחולו עד שהמשתפפים יאשרו את השינוי",
    },
    notEmpty: "לא יכול להיות ריק",
    global: "אירעה שגיאה בלתי צפויה",
    globalShort: "שגיאה",
    missingItems: "פרטים חסרים",
  },
  dashboard: {
    myEscort: "הליווים שלי",
    allEescorts: "כל הליווים במערכת",
    personalInformation: "פרטים אישיים",
    messages: "הודעות",
    emergencyButton: "לחצן מצוקה",
  },
  toasts: {
    approveJoin: "התווסף בהצלחה לליווי {escort} {user}",
    declineJoin: "נדחתה בהצלחה {user} בקשתו של ",
    leftEscort: "יצאת מהליווי {escort}",
    error: "אירעה שגיאה",
    success: "הפעולה הושלמה",
    successStore: "היצירה הושלמה",
    successUpdate: "העריכה הושלמה",
    successDestroy: "המחיקה הושלמה",
  },
  swals: {
    approveJoin: "לאשר את הבקשה",
    declineJoin: "לדחות את הבקשה",
    leaveType: "איזה ליווי לעזוב",
    deleteEscortsInRange:
      "למחוק את כל הליווים בין התאריכים {startDate} עד {endDate}?",
  },
};
export default he;
