<template>
  <v-app>
    <drawer v-if="showAdminNavbarDrawer" v-model="drawer" />
    <v-main>
      <navbar v-if="showAdminNavbarDrawer" @toggleDrawer="toggleDrawer" />
      <user-navbar v-if="showUserNavbar" @toggleDrawer="toggleDrawer" />
      <router-view />
    </v-main>
    <close-app-footer v-if="showFooter" />
    <new-escort-notification id="new-escort-notification-card" class="d-none" v-if="$store.getters.role <= 2" />
  </v-app>
</template>

<script>
import { isRtlLang } from "./i18n";
import Navbar from "@/layouts/Navbar";
import CloseAppFooter from "@/layouts/CloseAppFooter";
import Drawer from "@/layouts/Drawer";
import UserNavbar from "@/layouts/UserNavbar";
import sockets from "./mixins/sockets";
import NewEscortNotification from './components/newEscortNotification.vue';
export default {
  name: "App",
  components: { Navbar, CloseAppFooter, Drawer, UserNavbar, NewEscortNotification },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    snackbar() {
      return this.$store.getters.snackbar;
    },
    showFooter() {
      return true;
    },
    notLoginPage() {
      return this.$route.name !== "Login" && this.$route.name !== "AdminLogin";
    },
    showAdminNavbarDrawer() {
      return this.notLoginPage && this.$store.getters.role < 3;
    },
    showUserNavbar() {
      return this.notLoginPage && this.$store.getters.role >= 3;
    },
  },
  mixins: [sockets],
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
  },
  watch: {
    "$i18n.locale": function (newLocale) {
      const isRtl = isRtlLang(newLocale);
      this.$vuetify.rtl = isRtl;
    },
  },
};
</script>
