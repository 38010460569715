import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/he";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale("he", {
  relativeTime: {
    future: "בעוד %s",
    past: "לפני %s",
    s: "כמה רגעים",
    m: "דקה",
    mm: "%d דקות",
    h: "שעה",
    hh: "%d שעות",
    d: "יום",
    dd: "%d ימים",
    M: "חודש",
    MM: "%d חודשים",
    y: "שנה",
    yy: "%d שנים",
  },
});

const genericMixins = {
  methods: {
    formatTime(time, tz = "Pacific/Pitcairn") {
      return dayjs(time).tz(tz).format("DD.MM.YY HH:mmA");
    },
    extractDateString(date = new Date()) {
      return date ? dayjs(date).format("YYYY-MM-DD") : "";
    },
    extractTimeString(date) {
      return date ? dayjs(date).format("HH:mm") : "";
    },
    setDateTime(date, time) {
      date = dayjs(date);
      let dateStr = this.extractDateString(date);
      dateStr += `T${time}`;
      return dayjs(dateStr);
    },
    createCalendarTimeString(date, time) {
      return `${dayjs(date).format("YYYY-MM-DD")} ${time}`;
    },
    isSameDay(d1, d2) {
      return this.extractDateString(d1) === this.extractDateString(d2);
    },
    isBetweenOrEquals(d1, d2, d3) {},
    clacRelativeTimeString(date) {
      return dayjs(date).locale(this.$i18n.locale).fromNow();
    },
    sortByISODate(dateISO1, dateISO2) {
      const date1 = new Date(dateISO1);
      const date2 = new Date(dateISO2);
      return date1 > date2 ? -1 : date1 < date2 ? 1 : 0;
    },
    //checking if x's day is between y1, y2 days, or same as one
    isSameOrBetweenDays(x, y1, y2) {
      x = dayjs(x);
      y1 = dayjs(y1);
      y2 = dayjs(y2);
      return (
        x.isBetween(y1, y2) || this.isSameDay(x, y1) || this.isSameDay(x, y2)
      );
    },
    //checking x's range overlaps with y's range
    overLaps(xStart, xEnd, yStart, yEnd) {
      xStart = dayjs(xStart);
      xEnd = dayjs(xEnd);
      yStart = dayjs(yStart);
      yEnd = dayjs(yEnd);
      return (
        xStart.isBetween(yStart, yEnd) ||
        (xStart.isSameOrBefore(yStart) && (!xEnd || xEnd.isSameOrAfter(yEnd)))
      );
    },
    goToday(day) {
      return dayjs(day).day();
    },
  },
};

export default genericMixins;
