import myAxios from "../../services/myAxios";
export default {
  state: {
    alert: null, //chosen alert
    alerts: [],
    filtered: [],
  },
  getters: {
    alerts: (state) => state.alerts,
    alert: (state) => state.alert,
    alertFiltered: (state) => state.filtered,
  },
  mutations: {
    //sets all alerts
    "alerts/set": (state, payload) => {
      state.alerts = payload;
      state.filtered = [...state.alerts];
    },
    //sets one alert
    "alert/set": (state, payload) => (state.alert = payload),
    //filters the alert's array by alert's key and alert's val
    "alerts/filter": (state, { key, val }) => {
      state.filtered = !val
        ? [...state.alerts]
        : state.alerts.filter((f) => f[key] === val);
    },
    //store one alert
    "alert/store": (state, payload) => state.alerts.push(payload),
    //destroys one alert
    "alert/destroy": (state, id) =>
      (state.alerts = state.alerts.filter((item) => {
        return item._id !== id;
      })),
    "alert/destroyMany": (state) => (state.alerts = []),
    //updates one alert
    "alert/update": (state, payload) => {
      state.alerts = state.alerts.map((item) => {
        if (item._id === payload._id) {
          return payload;
        }
        return item;
      });
    },
  },
  actions: {
    //fetch all alerts
    "alert/index": async (context) => {
      const { data } = await myAxios.get("/alert");
      context.commit("alerts/set", data);
    },
    //fetch one alert by id
    "alert/show": async (context, id) => {
      let { data } = await myAxios.get("/alert/" + id);
      context.commit("alert/set", data);
    },
    //stores one alert
    "alert/store": async (context, payload) => {
      let { data } = await myAxios.post("/alert", { ...payload });
      context.commit("alert/store", data);
    },
    //destroys one alert
    "alert/destroy": async (context, id) => {
      await myAxios.delete("/alert/" + id);
      context.commit("alert/destroy", id);
    },
    //destroys all alerts
    "alert/destroyMany": async (context, id) => {
      await myAxios.delete("/alert");
      context.commit("alert/destroyMany");
    },
    //updates one alert by its id
    "alert/update": async (context, payload) => {
      await myAxios.put("/alert/" + payload._id, payload);
      context.commit("alert/update", payload);
    },
  },
};
