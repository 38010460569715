<template>
	<v-app-bar flat>
		<v-row align="center">
			<v-col
				cols="auto"
				sm="2"
			>
				<div class="d-flex justify-center align-center px-5">
					<v-app-bar-nav-icon
						color="primary"
						@click="openDrawer"
					/>
					<v-switch
						v-model="$vuetify.theme.dark"
						dense
						inset
						hide-details
					/>
					<v-icon>mdi-theme-light-dark</v-icon>
				</div>
			</v-col>
			<v-col
				cols="8"
				class="d-none d-sm-flex justify-center align-center"
			>
				<v-toolbar-title>
					<div class="primary--text">{{ $t("adminAppbarTitle") }}</div>
				</v-toolbar-title>
			</v-col>
		</v-row>
	</v-app-bar>
</template>

<script>
export default {
	name: "Navbar",
	data() {
		return {
			drawer: false,
		};
	},
	methods: {
		openDrawer() {
			this.$emit("toggleDrawer");
		},
	},
};
</script>
