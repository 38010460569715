<template>
  <v-select
    :items="colorsItems"
    item-value="name"
    v-model="selected"
    :label="$t('escorts.color')"
    outlined
    hide-details
    dense
    multiple
    class="mx-1"
    style="max-width:11em"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip
        v-if="index <= 1"
        text-color="white"
        :color="item.color"
        small
        @click:close="selected.splice(index, 1)"
        close
        class="color-chip px-1"
      />
      <span v-if="index === 2" class="grey--text text-caption">(+{{ selected.length - 2 }})</span>
    </template>
    <template v-slot:item="{ active, item, attrs, on }">
      <v-list-item
        v-on="on"
        v-bind="attrs"
        #default="{ active }"
        :style="{ backgroundColor: item.color }"
      >
        <v-list-item-action>
          <v-checkbox :input-value="active" dark></v-checkbox>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import listsMixins from '@/mixins/lists';

export default {
  name: "color-filter",
  mixins: [listsMixins],
  props: { value: Array },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  }
}
</script>

<style lang="scss" scoped>
.color-chip {
  margin: 0 2px !important;
  &::v-deep {
    .v-chip__close {
      margin: 0 !important;
    }
  }
}
</style>
